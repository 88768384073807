import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
  전단적용기간 : 2025. 3. 27(목) ~ 2025. 4. 9(수) < 신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 2(수) >

  온국민이 땡잡는 날, 땡큐절
  3.27~4.9

  일주일 내내 땡잡았다!
  롯데/BC/신한/NH농협/삼성카드 초특가!
  기간 : 3/27(목) ~ 4/2(수)
  ※L.POINT 회원 해당카드 최종 결제 금액 기준입니다
  ※페이지 하단 카드할인 세부내용 참고

  TV CF 방영상품
  1등급 한우(냉장) 전품목 50%
  한우 등심(1등급/100g) 5,990원
  ※할인 전 가격 11,980원(냉장/국내산 한우고기) ※카드할인/다다익선 등 상품별 프로모션 상이

  TV CF 방영상품
  활대게 반값! 3,495원
  ※할인 전 가격 6,990원(100g/냉장/러시아산)

  파프리카 반값! 3개 이상 구매시 1개당 1,990원(개/국산)
  ※할인 전 가격 1,990원(개/국산)

  대용량 1.5kg 대추방울토마토! 8,990원
  ※할인 전 가격 12,990원(1.5kg/박스/국산)

  TV CF 방영상품
  행복생생란!
  2판 이상 구매시 1판당 4,490원
  ※(할인 전 가격) 1판 구매시 6,990원(대란/30입/판/국산)
  ※기간 : 3/27(목) ~ 3/30(일) ※조기 품절될 수 있습니다

  TV CF 방영상품
  10호 닭 큰 치킨! 7,495원
  ※할인 전 가격 14,990원 (1마리/국내산 계육)

  땡잡았다! 롯또 혜택!
  ▶ 기 간 : 3/27(목)~4/9(수)
  ※이벤트 세부사항은 QR코드 스캔 후 이벤트 페이지에서 확인하세요

  EVENT 1. 
  매일 오전 10시~오후 1시 최대 10,000원 롯또쿠폰 증정!
  7만원 이상 구매시 10%할인(최대 1만원 한도)

  EVENT 2. 
  총 1천명! 3개 채널에서 각 1만원 이상 구매시 최대 10,000점 스노우플랜 추첨 증정!

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  땡 이 가격! 잡았다! 오늘만 이 가격! 하루특가!

  3/27 목

  놀라운 가격 1천원대 한우
  L.POINT 50% -> 롯데/BC/신한/NH농협/삼성카드 34% 추가할인
  1등급 한우 국거리/불고기(각 100g/냉장/국내산 한우고기)
  ※양지 국거리용 제외
  ※페이지 하단 카드할인 세부내용 참고

  오픈런 필수템!
  L.POINT 3,000원
  상생 딸기(500g/팩/국산) 2,990원
  ※조기 품절될 수 있습니다 ※점별 운영상품 상이
  ※전점 1만팩 한정, 1인 2팩 한정

  대용량 새송이버섯2,990원!
  한가득 상생 새송이버섯(800g/팩/국산) 2,990원
  ※조기 품절될 수 있습니다

  3/28 금

  L.POINT 50% 
  남해안 산지직송!
  남해안 가리비(1.2kg/냉장/국산) 6,450원

  L.POINT 50% 
  연중 최대 할인 도전!
  냉동 번들 새우살(250g×2/냉동/원산지 별도표기) 11,950원

  3/29 토 

  파이/비스켓(상품별 상이) 롯데/BC/신한/NH농협/삼성카드 3개 구매시 9,900원
  ※3의 배수로 적용
  ※브랜드 관계없이 교차구매 가능
  ※3,300원 이하 상품 제외
  ※L.POINT 회원 해당카드 결제시 적용
  ※공구핫딜/기획상품/대용량상품 제외
  ※일부품목 제외 ※1인 1회 최대 9개 한정
  ※페이지 하단 카드할인 세부내용 참고 

  3/30 일

  롯데/BC/신한/NH농협/삼성카드 2개 이상 구매시 50%
  삼다수 그린(2L×6입) 2개 이상 구매시 1개당 각3,240원
  ※1개 구매시 각6,480원
  ※제주점 판매가 상이
  ※페이지 하단 카드할인 세부내용 참고

  땡 잡았다! 단, 4일간 주말특가!
  ※기간 : 3/27(목) ~ 3/30(일)

  1+1
  교자만두/얇은피만두 13종(상품별 규격 상이) 각6,480~12,980원
  ※CJ/풀무원/오뚜기/하림산업
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  사조대림 인기 어묵/맛살 12종(상품별 상이) 2개 이상 구매시 1개당 각990~3,490원
  ※1개 구매시 각1,980~6,980원
  ※스노우크랩킹/로얄크랩/선사각어묵 외 ※점별 운영상품 상이
  ※교차구매 가능

  1+1
  간식 어린이 소시지 4종(상품별 상이) 각6,980~8,480원
  ※CJ 맥스봉 오리지널 외 ※동일 가격 간 교차구매 가능

  L.POINT 10,200원
  산토리 가쿠빈(700ml) 31,800원
  ※점별 한정수량 ※조기 품절될 수 있습니다

  1+1
  CJ/대림/청정원/풀무원 떡볶이 6종(각 2인) 각4,980~5,480원
  ※사조대림 국물떡볶이 외 ※동일 브랜드 내 교차구매 가능

  1+1
  오뚜기/풀무원 인기 냉장면 6종(각 2인) 각6,980~9,980원
  ※막국수/쫄면/짜장/짬뽕/우동
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  인기 그릭 요거트 6종(상품별 상이) 2개 이상 구매시 1개당 각1,900~7,000원 
  ※1개 구매시 각3,800~14,000원
  ※점별 운영상품 상이 ※동일 브랜드 내 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 510원 할인
  지평 평생 막걸리(750ml) 990원
  ※점별 한정수량 ※조기 품절될 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  서울 체다 슬라이스 치즈(360g) 8,980원
  ※전점 11,000개 한정 수량

  1+1
  서울우유 유기농 우유(700ml) 4,980원

  3/29 토, 3/30 일
  1+1
  샴푸/컨디셔너/트리트먼트(상품별 상이)
  ※일부 품목 제외 ※동일 품목 내 동일 가격 간 교차구매 가능
  ※일부상품 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)

  3/29 토, 3/30 일
  1+1
  바디워시(상품별 상이)
  ※일부 품목 제외 ※동일 품목 내 동일 가격 간 교차구매 가능
  ※일부상품 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  요리하다 냉동밥 전품목(상품별 규격 상이) 2,000
  ※1입 상품 한정

  1+1
  냉동 안주류 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  냉동 돈까스 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  하코야 닭꼬치 전품목(상품별 규격 상이)
  ※동일가격 간 교차구매 가능

  1+1
  냉동 피자 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~4/2(수)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  상온 국물요리 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  컵밥/프리미엄밥 전품목(상품별 규격 상이)
  ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  카레/짜장 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  포도씨유/카놀라유/해바라기유 전품목(상품별 규격 상이)
  ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  고추장/된장/쌈장 전품목(상품별 규격 상이)
  ※일부품목 제외
  ※기간 : 3/27(목)~3/30(일) ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  케찹/마요네즈 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  파스타소스 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  땡 잡았다! 인기 카테고리 전품목 세일! 
  ※기간 : 3/27(목) ~ 4/9(수) ※품목별 기간 상이

  1+1
  스테비아/알룰로스 전품목(상품별 규격 상이) 
  ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  티젠/담터 콤부차 30입/립톤 아이스티 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※담터/티젠 브랜드별 교차구매 가능
  ※립톤 아이스티 각 동일 입수별 교차구매 가능

  1+1
  참치액/코인조미료 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  16입 두유 전품목(각 190ml×16입)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  대용량 과채주스 40종(각 1L~1.89L)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  2+1
  스타벅스/네스카페 돌체구스토 캡슐커피 전품목(상품별 상이)
  ※점포별 입고 상품 상이 ※기간 : 3/27(목)~4/2(수)
  ※동일 호환 기기별 교차구매 가능

  2+1
  카누 캡슐 커피 전품목(상품별 상이)
  ※시즌 캡슐/캡슐기획 제외 ※동일 호환 기기별 교차구매

  2개이상 50%
  아기치즈 전품목(상품별 규격 상이)
  ※동일 브랜드 간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  냉장 밀키트 전품목(상품별 상이)
  ※점별 운영상품 상이 ※기간 : 3/27(목)~3/30(일) ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  냉동 밀키트/국/탕 전품목 최대 165종(상품별 규격 상이)
  ※점별 운영상품 상이 ※기획 상품 제외
  ※기간 : 3/27(목)~4/2(수) ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  세탁세제 전품목(상품별 상이) ※교차구매 가능

  2개이상 50%
  크리넥스/스카트 물티슈 전품목(상품별 상이)※점별 운영상품 상이
  ※기간 : 3/27(목)~3/30(일) ※교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  땡잡았다 롯데마트 추천! 매일 신선 먹거리!
  ·신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 2(수) ※상품별 기간 상이

  ZERO SUGAR
  제로슈가 육류 2종 L.POINT 최대 50%

  L.POINT 50%
  제로슈가 양념 소불고기(600g/냉장/원산지별도표기) 9,900원

  L.POINT 6,000원
  제로 슈가 훈제오리 슬라이스(400g/냉장/원산지 별도표기) 7,990원

  땡큐 닭볶음탕 연중 최저가 도전!
  ※연중 기간 : 24년 1월 1일 ~ 25년 3월
  ※조기 품절될 수 있습니다
  1+1 땡큐 닭볶음탕용(1kg/냉장/국내산) 8,990원

  청정지역 제주돼지 주말 반값! L.POINT 50%
  제주돼지 삼겹살/목심(각 100g/냉장/국내산)
  ※기간 : 3/27(목) ~ 3/30(일)
  ※조기 품절될 수 있습니다
  ※일부점 프로모션 상이

  해양수산부와 함께하는 수산대전 20%
  ※기간 : 3/27(목) ~ 4/2(수) ※해당상품에 한함 ※산지시황에 따라 조기품절될 수 있습니다
  ※L.POINT 회원 기간 내 주차별 1인 최대 1만원 할인 ※이미지 연출컷입니다
  ※페이지 하단 카드할인 세부내용 참고

  롯데/BC/신한/NH농협/삼성카드 40% -> 수산대전 20%
  반값 광어회(450g내외/냉장/광어 : 국산) 24,000원
  ※조기 품절될 수 있습니다
  ※1인 1팩 한정판매 ※입점점에 한함

  롯데/BC/신한/NH농협/삼성카드 40% -> 수산대전 20%
  내가 만드는 광어회필렛(100g/냉장/광어:국산) 4,560원
  ※1인 1팩 한정판매

  롯데/BC/신한/NH농협/삼성카드 10% -> 수산대전 20%
  국산 손질 꽃게(500g/포장/냉동/국산) 10,728원

  롯데/BC/신한/NH농협/삼성카드 20% -> 수산대전 20%
  참조기(대/마리/해동/국산) 2,432원

  롯데/BC/신한/NH농협/삼성카드 30% -> 수산대전 20%
  고등어자반(특대/1손/냉장/고등어,천일염:국산) 3,915원

  롯데/BC/신한/NH농협/삼성카드 25% -> 수산대전 20%
  완도 활 전복(왕/대)(마리/냉장/국산) 3,540/1,980원

  물가안정 가성비 채소 1,990/3,990원

  대파(700g/봉/국산) 1,990원
  ※점별 취급상품 상이

  한가득 시금치(400g/팩/국산) 1,990원

  상생 다다기오이(5입/봉/국산) 3,990원
  ※점별 취급상품 상이

  뉴질랜드 킹 단호박(통/뉴질랜드산) 3,990원

  2팩 이상 구매시 50%
  하루 한끼 한우 11종(품목별 규격 상이)
  ※냉장/국내산 한우고기/1등급 ※교차구매 가능

  L.POINT 50%
  몸보신용 한우 사골/잡뼈(각 3kg/냉동/국내산 한우고기) 6,990/4,990원

  호주청정우 한끼기획 9종(품목별 규격 상이/냉장/호주산) 2개 이상 구매시 1개당 각8,990원
  ※1개 구매시 각9,990원
  ※교차구매 가능

  L.POINT 최대 30%
  동원이 항공 직수입하여 더욱 신선하고 믿을 수 있는 양고기
  동원청정램 전품목(100g/냉장/호주산 양고기)

  L.POINT 15,000원
  칠레 연어 1위 업체 사전 계약 물량 대방출
  프리미엄 파타고니아 생연어 필렛(500g/냉장/칠레산) 17,900원 ※조기 품절 될 수 있습니다.

  1+1
  1+1 기획팩이 하나 더!
  동원 프리미엄 명란/양념명란 기획(번들팩 1+1)(각 100g×2/냉장/원산지 별도표기) 각16,900원 ※교차구매 가능

  1+1
  한입 꼬마육포/BBQ 통통 오징어(125g/90g) 각9,980원
  ※원산지 별도표기 ※교차구매 가능

  32봉 특별기획
  동원 양반 들기름김 기획팩(4g×16봉×2입/원산지 별도표기) 9,980원

  ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
  ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
  롯데마트 추천 봄맞이 제철과일
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 2,000원
  12Brix 이상 참외만 선별!
  성주 상생참외(3~6입/봉/국산) 7,990원

  12Brix 이상 고당도 오렌지를 한 봉 가득 담아 9,990원
  한 봉 가득 오렌지(봉/미국산)
  ※매장 내 비치된 전용 봉투에 담아 주세요
  ※조기 품절될 수 있습니다

  태국에서 비행기 타고 날아온 망고!
  태국 남독마이 망고(개/태국산) 4개 구매시 9,960원
  ※1개 구매시 3,990원
  ※조기 품절될 수 있습니다

  온가족 나들이 즉석 먹거리 추천

  L.POINT 50%
  반값 알뜰 초밥(20입)(1팩/원산지 별도표기) 9,990원

  L.POINT 25%
  온가족 한통가득 탕수육(1팩/원산지 별도표기) 9,743원

  김밥&유부&샌드 한판샌드(1팩/원산지 별도표기) 11,990원

  L.POINT 30%
  5무 훈제오리(1팩/원산지 별도표기) 11,193원

  국내산 통족발(특대)(1팩/국내산) 19,800원 ※족발매장 입점점 한함

  5,000원 할인
  대용량 가득바삭파이(1팩/원산지 별도표기) 7,900원

  반찬 3+1팩 골라담기(4팩/원산지 별도표기) 9,990원
  ※기간 : 3/27(목) ~ 3/30(일)
  ※비움, 미찬, 도시곳간, 무명찬반,초이스쿡 입점점 한함
  ※정상가 4,000원 이하 상품 한정

  홈술족들 모여라!
  새롭고 이로운 와인장터 주주총회
  주류코너에서 QR코드 찍으면 행사상품을 확인할 수 있어요

  와인 전품목 롯데/BC/신한/NH농협/삼성카드 구매시 
  15만원 이상 구매시 15,000원 할인 
  30만원 이상 구매시 60,000원 할인
  ※기간 : 3/27(목)~4/2(수) ) ※페이지 하단 카드할인 세부내용 참고

  레 볼테 델 오르넬라이아 2022(750ml) 36,900원
  ※전점 800병 한정

  L.POINT 24,100원
  파이퍼하이직 뀌베 브뤼(750ml) 64,900원

  L.POINT 60,100원
  글렌 알라키 10년 CS Batch 11(700ml) 129,800원

  북극곰의 눈물 전용잔 패키지(720ml) 24,900원
  ※한정수량으로 조기 품절될 수 있습니다

  생산일이 한눈에!
  이달의 맥주(500ml) 5캔 구매시 10,000원 ※1캔 구매시 2,500원

  세계맥주 67종(상품별 용량 상이) 5캔 구매시 9,900원
  ※1캔 구매시 각 3,000~3,300원

  견과류 6종(상품별 용량 상이/원산지 별도표기) 각9,990원

  딸기/토마토/수입포도전품목(상품별 규격 상이/원산지 별도표기) L.POINT 최대 3,000원 할인
  ※딸기, 토마토 전품목 L.POINT 3천원 할인,수입포도 전품목 L.POINT 2천원 할인

  임금님표 이천쌀(8kg/국산)/해보드미(20kg/국산) 29,900/49,900원

  풀무원 특등급 국산 콩나물(200g/국산)/풀무원 1+등급 목초란(대란/15입/국산) 990/5,990원
  ※조기 품절될 수 있습니다

  GAP 팽이버섯(3입/봉/국산)/GAP 향기로운 송이버섯(270g×2봉/국산) 1,490/2,990원

  2송이 이상 각 1,000원 할인
  고산지 바나나(송이/필리핀산) 2송이 이상 구매시 1송이당 각2,990원 ※1송이 구매시 3,990원
  ※점별 운영상품 상이(스위티오/감숙왕/허니글로우)
  ※교차구매 가능 

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
 
  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다 인기 먹거리 최대 1+1 최대 50% !

  1+1 요리하다 포기김치(900g) 12,900원

  1+1 봉지라면 6종(상품별 규격상이) 각3,320~8,800원
  ※팔도&해찬들 차돌된장찌개라면 4개입 外 5종
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1 오뚜기밥 찰현미/오곡/발아흑미(각 210g×3입) 각6,480원 ※교차구매 가능

  1+1 CJ 비비고 열무김치/총각김치(각 900g) 각15,900원 
  ※기간 3/27(목)~4/2(수) ※교차구매 가능

  1+1
  치킨너겟/텐더 13종(상품별 규격 상이) 각5,980~12,980원
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  냉장 꼬치어묵 2종(삼진/고래사) 각9,980원
  ※동일상품에 한함

  1+1
  오뚜기 생쫄면/메밀소바(각 2인분) 각6,980원 ※교차구매 가능

  1+1
  CJ/대림/풀무원 유부초밥 3종(상품별 상이) 각5,480~6,980원
  ※기간 3/27(목)~4/2(수) ※동일상품에 한함

  1+1
  크래프트 슈레드 치즈 4종 각8,990원
  (상품별 상이) ※교차구매 가능

  1+1
  블루샥 라떼, 피넛 라떼(상품별 상이)/이디야 블렌드 아메리카노(1g×100입) 각12,900/25,900원
  ※블루샥 라떼 교차구매 가능 ※이디야 블렌드 아메리카노 동일 품목만 적용 가능

  1+1
  글로벌 수입과자 11종(상품별 상이) 각3,500~15,980원
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  동서 포스트 아몬드 후레이크(620g)/켈로그 첵스초코(570g) 9,780/8,880원 ※동일상품에 한함

  1+1
  상온/냉장커피 음료 63종(상품별 상이) 각1,880~4,390원
  ※동일 브랜드 내 동일 가격간 교차 구매 가능

  1+1
  코카콜라/코카콜라 제로 각3,990원
  (각 1.8L) ※교차구매 가능

  1+1
  롯데마트 단독
  링티 레몬 라이트(1L) 3,980원

  2개 이상 10%
  농심 라면 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,952~5,022원
  ※1개 구매시 각 3,280~5,580원
  ※기간 : 3/27(목)~4/4(금), 4/7(월)~4/9(수)
  ※농심 올리브 짜파게티 5개입 外 14종 ※교차구매가능

  농심 라면 전품목 1만원 이상 구매시 라면 전용 계량컵 증정!
  ※전점 15,000개 한정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
  ※구분자 " ㉡ "를 확인하세요 ※영수증 지참 후 '도와드리겠습니다' 센터에서 수령

  5개 이상 각2,980원 할인
  씨제이 햇반 용기 죽 7종(상품별 상이) 5개 이상 구매시 1개당 각2,000원 ※1개 구매시 각 4,980원 ※교차구매 가능

  2개 이상 50%
  진주햄 천하장사 2종(각 448g, 오리지널/치즈) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 각 7,980원
  ※교차구매 가능

  2개 이상 50%
  한성 크런치 롤 유부초밥(240g) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 7,980원

  2개 이상 50%
  제주우유 3종(각 750ml) 2개 이상 구매시 1개당 각2,490원 ※1개 구매시 각 4,980원
  ※A2유기농/무항생제/저지 ※교차구매 가능

  2개 이상 50%
  매일 헬스케어 셀렉스 9종(상품별 상이) 2개 이상 구매시 1개당 각2,990~17,500원
  ※1개 구매시 각5,980~35,000원
  ※교차구매 가능 ※점별 운영상품 상이

  2개 이상 50%
  화제의 레몬/레몬생강즙 6종(상품별 상이) 2개 이상 구매시 1개당 각4,995~7,950원 ※1개 구매시 각9,990~15,900원 ※교차구매 가능

  동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  동원 수산/과일 통조림 8종(상품별 상이) 2,280~12,480원 ※기간 : 3/27(목)~3/30(일)
  ※동일 가격간 교차구매 가능

  1+1
  양반밥 찰진백미밥/현미밥/흑미밥(각 130g×6입) 각10,480원 ※교차구매 가능

  1+1
  동원 양반 야채바삭 김말이(406g) 8,980원

  1+1
  동원 롤 유부초밥2종/리얼크랩스 더킹오리지널(상품별상이) 각6,980원 ※교차구매 가능

  롯데웰푸드 행사상품 3만원 이상 구매시 10,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  롯데 의성마늘 순한 비엔나/프랑크(상품별 상이) 각7,980/8,980원
  ※동일상품에 한함

  롯데 의성마늘 빅그릴 비엔나(300 g×2) 6,980원

  사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령

  사조대림 실속김밥세트(510g) 7,980원

  1+1
  사조 살코기/마일드/고추참치(각 85g×4입) 각8,980원
  ※교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  2개 이상 50%
  동원 딤섬 4종(상품별 규격상이) 2개 이상 구매시 1개당 각5,490~5,990원
  ※새우하가우/부추창펀/샤오롱바오/부채교 ※교차구매 가능

  2개 이상 50%
  고래사 어묵떡볶이(520g) 2개 이상 구매시 1개당 각3,300원 ※1개 구매시 6,600원
  ※기간 : 3/27(목)~4/2(수)

  2개 이상 50%
  나뚜루 파인트 전품목(각 474ml) 2개 이상 구매시 1개당 각7,950원 ※1개 구매시 각15,900원
  ※교차구매 가능

  2개 이상 50%
  논알콜맥주 22종(상품별 상이) 2개 이상 구매시 1개당 각690~1,650원 ※1개 구매시 각1,380~3,300원 ※교차구매 가능

  2개 이상 40%
  종근당건강 락토핏 6종(상품별 상이) 2개 이상 구매시 1개당 각9,540~17,940원 ※1개 구매시 각15,900~29,900원 ※교차구매 가능

  400원 할인 -> 롯데/BC/신한/NH농협/삼성카드 400원 할인
  오늘좋은 1등급 우유(900ml×2입) 2,990원 ※기간 : 3/27(목) ~ 3/30(일)

  롯데마트 단독
  빙그레 바나나맛우유 수세미 굿즈 기획(240ml×6입) 12,980원

  CJ 비비고 육즙가득 고기만두(360g×2입) 10,980원

  동원 통그릴 비엔나(1kg) 10,980원

  롯데마트 단독
  hy 야쿠르트 1971 키링 기획(450ml×4입) 12,800원

  신상품
  종가 오래아삭 포기김치(3.7kg) 35,900원

  서울 흰우유(2.3L) 5,780원
  ※기간 3/27(목)~4/2(수)

  롯데마트 단독
  칠성사이다 유리병 기획(300ml×4입) 3,980원

  신상품
  해태 생생감자칩 랍스터맛(85g)/샌드에이스 그릭요거트(204g)/자유시간 누가마블 딸기(408g) 2,480/3,980/10,980원

  신상품
  오리온 마켓오 브라우니 바이트 제주말차(240g) 5,280원

  신상품
  롯데 명가 찰떡파이 초코바나나(250g) 5,580원

  샘표식품 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " S "를 확인하세요 ※결제시 계산대에서 수령

  1+1
  샘표 국산 100% 조선고추장/토장 (상품별 규격 상이) 29,900/22,900원 ※동일상품에 한함

  동서식품 전품목 5만원 이상 구매시 5,000원 롯데상품권 증정
  ※동서식품 치즈 제외 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " # "를 확인하세요 ※결제시 계산대에서 수령

  2,000원 할인
  동서 맥심 모카골드 마일드/화이트골드 220입+20입 증량업 기획(상품별 상이) 각31,220원

  CJ제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " C "를 확인하세요 ※결제시 계산대에서 수령

  1+1
  CJ 더건강한 베이컨(90g×2입) 육공육 더얇은 슬라이스 씬(250g) 각9,980원
  ※교차구매 가능

  1+1
  해찬들 원조 태양초 고추장(1.5kg) 18,900원

  2개 이상 각 1,000원 할인
  CJ 고메 소바바 치킨 5종(상품별 상이) 2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원 ※교차구매가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)

  </div>
  `,
  `
  <div>
  땡잡았다! 생활 필수품 최대 1+1 최대 50% !

  1+1 
  케라시스 클린노트 샴푸/트리트먼트(각 800ml) 각12,500원 ※베이비파우더향/화이트코튼향 ※교차구매 가능

  1+1 
  리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 각15,900원
  ※손상모발용/지성두피용 ※교차구매 가능

  1+1 
  아이깨끗해 핸드워시 용기/리필 20종 각5,900~12,980원
  (상품별 상이) ※점별 운영상품 상이
  ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  오랄비 초미세모 잇몸케어 칫솔(3개) 11,900원

  롯데/BC/신한/NH농협/삼성카드 1+1 
  섬유유연제 최대 66종
  ※다우니/피죤/아우라/스너글/버넬 ※점별 운영상품 상이
  ※동일 품목 내 동일 가격간 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참

  1+1 
  섬유 탈취제(용기) 5대 브랜드 각7,500~10,900원
  (상품별 상이) ※일부품목 제외 ※점별 취급품목 상이
  ※동일 브랜드 내 동일가격에 한해 교차구매 가능

  1+1 
  변기 세정제(상품별 상이) 각1,000~8,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  주방세제(상품별 상이) 각1,900~10,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  로션 미용티슈(상품별 상이) 각13,400~16,900원 ※행사상품에 한함
  ※점별 취급품목 상이 ※동일 상품에 한함

  LG생활건강
  행사상품 3만원 이상 구매시
  여행용 세트 증정

  코카콜라
  행사상품 5만원 이상 구매시
  아이스 박스 증정

  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개) 
  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령 
  ※구분자 "@"를 확인하세요 ※사은품 한정수량 ※사은품 소진시 자동 행사 종료

  1+1
  엘라스틴 헤드스파&퍼퓸 샴푸/컨디셔너(각 855ml) 각13,900원
  ※로지드림/디어자스민 ※점별 운영상품 상이 ※교차구매 가능

  1+1
  온더바디 퍼퓸 바디워시(각 900g) 각9,900원
  ※피오니로즈/화이트머스크
  ※점별 운영상품 상이 ※교차구매 가능

  1+1
  페리오 알파치약 기획(185g×4입) 10,900원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할
  ※해당 단일카드로 전액 결제시에 한함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  1+1
  도브 바디케어 전품목(비누/핸드워시/바디워시/바디로션) 각5,900~15,900원
  ※점별 운영상품 상이 ※동일 품목 내 동일 가격간 교차구매 가능

  2개 이상 50%
  도브 베이비 리치모이스처 로션/워시(각 400ml) 2개 이상 구매시 1개당 각7,700~8,000원
  ※1개 구매시 각15,400~16,000원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  2개 이상 50% 
  싸이닉 엔조이 선케어 3종(선크림 200g/선에센스 80ml/선스틱 20g) 2개 이상 구매시 1개당 각6,900~8,900원
  ※1개 구매시 각13,800~17,800원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  2개 이상 50%
  좋은느낌 생리대(상품별 상이)
  ※팬티라이너/탐폰/일부품목 제외 ※교차구매 가능

  2개 이상 50%
  슬릭 면도기/날/쉐이빙폼 등 8종(상품별 상이) 2개 이상 구매시 1개당 각3,100~21,950원
  ※1개 구매시 각6,200~43,900원
  ※점별 입점상품 상이 ※동일 품목간 교차구매 가능

  최대 50%
  스프레이 주거세정제(상품별 상이) 각3,950~7,900원
  ※행사상품에 한함 ※점별 취급품목 상이
  ※동일 브랜드 내 동일가격간 교차구매 가능

  2개 이상 50%
  벚꽃에디션 미용티슈/키친타월/물티슈(상품별 상이) 2개 이상 구매시 1개당 각1,000~3,950원
  ※교차구매 가능 ※1개 구매시 각2,000~7,900원

  롯데/BC/신한/NH농협/삼성카드 2,000원 할인
  벚꽃에디션 무형광 3겹 화장지 9,900원
  (30m×30롤) ※페이지 하단 카드할인 세부내용 참고

  1매당 8.9원 초가성비 물티슈!
  코코토토 데일리 물티슈(100매×10입) 8,900원

  AHC 행사상품 3만원 이상 구매시 
  5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " A "를 확인하세요 ※결제시 계산대에서 수령

  AHC 유스래스팅 아이크림(35ml×2입+7ml×2입)/AHC 바이탈 골든 콜라겐 크림(50g×2입+토너 30ml) 각25,900원

  브랜드 화장품 롯데상품권 증정!
  ※기간 : 3/27(목)~3/30(일) ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※단일 브랜드 결제시에 한함 ※비연속식 증정(1인 1일 1매) ※당일 영수증에 한함
  ※점별 입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  해당카드 5만원 이상 구매시 5,000원 롯데상품권 증정
  해당카드 10만원 이상 구매시 10,000원 롯데상품권 증정

  디펜드 2개 이상 구매시 다용도 가방 증정!
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
  ※구분자 " D "를 확인하세요
  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령

  2+1
  디펜드 37종(상품별 상이) 각5,250~23,900원 ※동일 가격간 교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다 홈 인테리어ㆍ잡화류 최대 1+1 최대 50%

  브랜드 프라이팬/밀폐용기(상품별 상이) 8,900~83,330원 최대 50%  ※입점점에 한함

  40%
  테팔 스타트이지/에브리데이쿡 11종(상품별 상이) 각18,480~35,880원

  50%
  해피콜 쿡메이트 프라이팬/궁중팬 2종(28cm) 각18,900원

  40,000원 할인
  쿡셀 더블 블랙 프라이팬 2P세트(20cm/28cm) 49,900원

  50%
  락앤락 익스트롱 프라이팬 6종(상품별 상이) 각15,450~18,450원

  글라스락 인기 밀폐 시리즈 L.POINT 50%
  ※스마트/퓨어오븐/모듈러/퓨어핸디/간편캡 등

  스텐락 인기 밀폐 시리즈 L.POINT 50%
  ※퓨어/이지오픈
  
  락앤락 인기 밀폐 시리즈 L.POINT 50%
  ※비스프리모듈러/더클리어/클리어비스프리/프리저핏/스마트킵/클래식 등

  1+1 기획
  글라스락 센스 핑크 에디션 1+1기획(상품별 용량 상이)각5,900~19,900원

  2개 이상 50%
  크린랩 이중지퍼백/뽑아쓰는 크린백(상품별 상이) 2개 이상 구매시 1개당 각3,145~7,695원 
  ※1개 구매시 각6,290~15,390원 ※교차구매 가능

  2개 이상 50%
  오늘좋은 데일리 울트라쿨 냉감패드(SS : 110×200cm/Q : 150×200cm) 2개 이상 구매시 1개당 각14,950/19,950원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  990원

  오늘좋은 데일리 무세제 클리너(3P/11×3×6cm) 990원 정상가 1,500원

  오늘좋은 베이직 바스켓 7종/클래식 바스켓 4종(상품별 상이) 990원 정상가 각1,000원~1,500원

  베이직 남성/여성덧신/양말(각 1매) 990원 정상가 2,000원
  ※입점점에 한함 ※해당상품에 한함
  ※점별 재고 상이

  동원 뉴트리플랜 소프트뮨 강아지 습식간식 3종(각 100g) 990원 정상가 각 2,000원

  4,990원

  룸바이홈 럭셔리 호텔 세면타월(각 40×60cm, 네이비/그레이/화이트) 4,990원 정상가 각 6,990원
  ※ 입점점에 한함 ※ 해당상품에 한함 ※ 점별 재고 상이

  맥스 폭발방지 CRV 부탄가스(4입) 4,990원 정상가 6,790원

  향기가득 프리미엄 워셔액 4종(각 1.8L×2) 4,990원 정상가 각 5,900원

  1+1 기회팩
  유니랩 위생장갑(200매+200매)

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  50%
  풍년 마레러스터 압력솥(8인) 59,000원

  롯데/BC/신한/NH농협/삼성카드 최대 50%
  포트메리온(상품별 용량 상이) 5,340~41,665원
  ※입점점에 한함 ※ 점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 40%
  3M 주방 고무장갑/수세미 전품목(상품별 상이) 2개 이상 구매시 1개당 각1,308~6,594원
  ※1개 구매시 각2,180~10,990원
  ※기간 : 3/27(목)~4/2(수) ※교차구매 가능 ※입점점에 한함 ※점별 재고 상이

  2개 이상 50%
  테이프클리너 전품목(상품별 상이) 2개 이상 구매시 1개당 각1,950~10,390원
  ※1개 구매시 각3,900~20,780원
  ※일부품목 제외
  ※조기 품절될 수 있습니다 ※교차구매 가능 ※기간 : 3/27(목)~4/2(수)

  30%
  크린랩 막대걸레 더블헤드(표준형/대형)/크린랩 물걸레 청소포(각 30매, 표준형/대형) 각5,030~18,540원

  50%
  아이리스 시스템 수납함(중형 2호)/빌드업 바스켓 3종(상품별 상이) 각1,000~9,950원 ※입점점에 한함

  30%
  오늘좋은 세탁이 편한 빨아쓰는 베개(소 : 40×60cm/대 : 50×70cm) 15,330/20,930원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각6,930~7,630원 
  ※1개 구매시 각9,900~10,900원 ※교차구매 가능 ※입점점에 한함
  ※뉴발란스, 아디다스, 언더아머, 네파 등 ※점별 상품 상이

  2+1
  남성 브랜드 언더웨어(상품별 상이) 6,900원 ※입점점에 한함
  ※브랜드 : 트라이/보디가드/베이직엘르 외 ※교차구매 가능

  10,000원 할인
  트래커 브리튼 여행가방(16/20/24/28인치) 49,000~89,000
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  7,990원

  SOUP/AD HOC 데일리진(스트레이트/테이퍼드) 7,990원 정상가 각 14,800원

  오늘좋은 심리스 런닝/땀패드 런닝 7,990원 정상가 각 8,900~12,900원 ※입점점에 한함 

  코코도르 차량용 미니멀 디퓨저(50mlX2) 7,990원 정상가 각 9,900원

  오늘좋은 패브릭 발매트 11종(메모리폼/워셔블 外) 7,990원 정상가 각 10,900원~12,900원

  9,990원

  블랙 박스 카트(M, 38×29×85.5cm) 9,990원 정상가 15,900원

  뉴발란스/데상트 러닝 양말(각 2매) 9,990원 ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  에너자이저 맥스10+5입(AA/AAA) 9,990원 정상가 각 12,900원

  대림바스 PVC 샤워호스(실버 1.5m/어반 그레이, 화이트 2m) 9,990원 정상가 13,900/14,900원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)

  </div>
  `,
  `
  <div>
  1+1
  유니랩 셰프장갑(소/중/대)/원데이 일회용 수세미(핑크/그린) 각4,990원 ※동일 상품에 한해 교차구매 가능

  1+1 기획팩
  크린랩 크린장갑(200매)/이중지퍼백(20매,소/중/대)/랩(30X50cm) 각3,190~9,090원

  L.POINT 20%
  오늘좋은 아이스박스 3종(미니 5L/17L/38L) 15,920/31,920/71,920원

  2개 이상20%
  오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 3,920원 ※1개 구매시 4,900원

  1+1
  코렐 냉면기 4종(상품별 용량 상이) 각21,900원
  ※입점점에 한함 ※점별 재고 상이 ※교차구매 가능

  40%
  테팔 인텐시움 통 3중 냄비 3종(상품별상이) 26,280~37,680원

  30%
  코렐 목련/더블링그린티/시나모롤 外 5,530~15,330원

  브랜드 와인잔 2P(슈피겔라우/쇼트즈위젤 外) 각9,900원

  30%
  오늘좋은 데일리 피치스킨 이불(S/Q) 16,030/20,930원
  ※S : 150×200cm/Q : 180×220cm

  2+1
  크린랩 물걸레 청소포 30매(표준형/대형, 각 2P) 14,380/16,580원 ※입점점에 한함 ※행사 상품에 한함

  꽃 피는 다육 포트 3종기획 각9,990원

  5,000원 할인
  오늘좋은 점보 리빙박스(100L/75×51×37cm) 19,900원
  ※입점점에 한함

  르젠 써큘레이터형 스탠드 선풍기(45W) 39,900원

  최대 40%
  세차도구 전품목(상품별 규격상이)
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  2+1
  불스원샷 70000(기획상품) 32,000원
  ※입점 점포에 한함 ※행사 상품에 한함

  러닝용품 전품목 8,720~39,920원 ※입점점에 한함
  ※브랜드 : 나이키/아디다스/언더아머 外

  10,000원 할인
  크록스 아동/성인 크록밴드 클로그 각33,900~39,900원
  ※기간 3/27(목)~4/2(수) ※행사상품에 한함 ※입점점에 한함

  브랜드 반팔&반바지 19,900~29,900원
  ※입점점에 한함 ※브랜드 : 휠라/푸마/르까프 外

  여성 브랜드 언더웨어 브라/팬티 4,900/9,900원
  ※입점점에 한함 ※브랜드 : 비비안/비너스/오테나 外

  쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
  ※기간 3/20(목)~4/23(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령

  최대 20%
  쿡셀 프라이팬/냄비 18종(상품별 상이) 31,120~49,900원

  에너자이저 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
  ※기간 3/27(목)~4/9(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉧"를 확인하세요 ※결제시 계산대에서 수령

  10% 에너자이저 맥스플러스 4입(AA/AAA) 8,370원

  레고 마블 인기 완구 30종 최대 L.POINT 30% 할인

  L.POINT 30%
  레고 콜의 타이탄 드래곤 로봇(71821) 104,300원
  (12세 이상/35.6×7.1×37.8cm) ※입점점에 한함

  L.POINT 30%
  마블 14인치 피규어세트(아이언맨/스파이더맨) 55,930원
  (6세 이상/49×11.2×45cm) ※입점점에 한함

  개이득 냥이득!
  ※기간 : 3/27(목)~4/9(수)

  colioli 득템위크
  반려동물식품&용품 500여종 할인!
  반려동물식품/용품 3만원 이상 구매시 5천원 롯데상품권 증정!
  ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※기간 : 3/27(목)~4/9(수)
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※페이지 하단 카드할인 세부내용 참고

  1+1 기획
  건강백서 사료 5종(상품별 용량 상이) 23,500~28,500원
  ※푸들/말티즈/피부/관절/캣 ※입점점에 한함 ※점별 수량 상이

  콜리올리 깔끔하게 사용하는 1회용 패드(M 100매/M 200매/L 40매) 각7,140~14,340원 ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  L.POINT 10% -> 롯데/BC/신한/NH농협/삼성카드 10% 추가할인
  이나바 챠오츄르 버라이어티(20P/40P) 각12,069/21,465원
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 30% 
  콜리올리 반려동물 방석 3종(상품별 상이) 각16,030~17,430원
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  탑텐, 탑텐키즈, 탑텐밸런스
  롯데마트 단독 5만원 구매 시 5천원 즉시할인
  ▶기간 : 3/27(목) ~ 4/9(수)
  ※최종 결제 금액에서 5천원 차감(탑텐 영수증 확인 필수)
  ※탑텐, 탑텐키즈, 탑텐밸런스 타 할인 쿠폰 행사와 중복 적용 불가

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
  ·전단적용기간 : 2025. 3. 27(목) ~ 2025. 4. 9(수) < 신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 2(수) >
  ·제타플렉스 잠실점

  온국민이 땡잡는 날, 땡큐절
  3.27~4.9

  일주일 내내 땡잡았다!
  롯데/BC/신한/NH농협/삼성카드 초특가!
  기간 : 3/27(목) ~ 4/2(수)
  ※L.POINT 회원 해당카드 최종 결제 금액 기준입니다
  ※페이지 하단 카드할인 세부내용 참고

  TV CF 방영상품
  1등급 한우(냉장) 전품목 50%
  한우 등심(1등급/100g) 5,990원
  ※할인 전 가격 11,980원(냉장/국내산 한우고기) ※카드할인/다다익선 등 상품별 프로모션 상이

  TV CF 방영상품
  활대게 반값! 3,495원
  ※할인 전 가격 6,990원(100g/냉장/러시아산)

  파프리카 반값! 3개 이상 구매시 1개당 1,990원(개/국산)
  ※할인 전 가격 1,990원(개/국산)

  대용량 1.5kg 대추방울토마토! 8,990원
  ※할인 전 가격 12,990원(1.5kg/박스/국산)

  TV CF 방영상품
  행복생생란!
  2판 이상 구매시 1판당 4,490원
  ※(할인 전 가격) 1판 구매시 6,990원(대란/30입/판/국산)
  ※기간 : 3/27(목) ~ 3/30(일) ※조기 품절될 수 있습니다

  TV CF 방영상품
  10호 닭 큰 치킨! 7,495원
  ※할인 전 가격 14,990원 (1마리/국내산 계육)

  땡잡았다! 롯또 혜택!
  ▶ 기 간 : 3/27(목)~4/9(수)
  ※이벤트 세부사항은 QR코드 스캔 후 이벤트 페이지에서 확인하세요

  EVENT 1. 
  매일 오전 10시~오후 1시 최대 10,000원 롯또쿠폰 증정!
  7만원 이상 구매시 10%할인(최대 1만원 한도)

  EVENT 2. 
  총 1천명! 3개 채널에서 각 1만원 이상 구매시 최대 10,000점 스노우플랜 추첨 증정!

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  땡 이 가격! 잡았다! 오늘만 이 가격! 하루특가!

  3/27 목

  놀라운 가격 1천원대 한우
  L.POINT 50% -> 롯데/BC/신한/NH농협/삼성카드 34% 추가할인
  1등급 한우 국거리/불고기(각 100g/냉장/국내산 한우고기)
  ※양지 국거리용 제외
  ※페이지 하단 카드할인 세부내용 참고

  오픈런 필수템!
  L.POINT 3,000원
  상생 딸기(500g/팩/국산) 2,990원
  ※조기 품절될 수 있습니다 ※점별 운영상품 상이
  ※전점 1만팩 한정, 1인 2팩 한정

  대용량 새송이버섯2,990원!
  한가득 상생 새송이버섯(800g/팩/국산) 2,990원
  ※조기 품절될 수 있습니다

  3/28 금

  L.POINT 50% 
  남해안 산지직송!
  남해안 가리비(1.2kg/냉장/국산) 6,450원

  L.POINT 50% 
  연중 최대 할인 도전!
  냉동 번들 새우살(250g×2/냉동/원산지 별도표기) 11,950원

  3/29 토 

  파이/비스켓(상품별 상이) 롯데/BC/신한/NH농협/삼성카드 3개 구매시 9,900원
  ※3의 배수로 적용
  ※브랜드 관계없이 교차구매 가능
  ※3,300원 이하 상품 제외
  ※L.POINT 회원 해당카드 결제시 적용
  ※공구핫딜/기획상품/대용량상품 제외
  ※일부품목 제외 ※1인 1회 최대 9개 한정
  ※페이지 하단 카드할인 세부내용 참고 

  3/30 일

  롯데/BC/신한/NH농협/삼성카드 2개 이상 구매시 50%
  삼다수 그린(2L×6입) 2개 이상 구매시 1개당 각3,240원
  ※1개 구매시 각6,480원
  ※제주점 판매가 상이
  ※페이지 하단 카드할인 세부내용 참고

  땡 잡았다! 단, 4일간 주말특가!
  ※기간 : 3/27(목) ~ 3/30(일)

  1+1
  교자만두/얇은피만두 13종(상품별 규격 상이) 각6,480~12,980원
  ※CJ/풀무원/오뚜기/하림산업
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  사조대림 인기 어묵/맛살 12종(상품별 상이) 2개 이상 구매시 1개당 각990~3,490원
  ※1개 구매시 각1,980~6,980원
  ※스노우크랩킹/로얄크랩/선사각어묵 외 ※점별 운영상품 상이
  ※교차구매 가능

  1+1
  간식 어린이 소시지 4종(상품별 상이) 각6,980~8,480원
  ※CJ 맥스봉 오리지널 외 ※동일 가격 간 교차구매 가능

  1+1
  CJ/대림/청정원/풀무원 떡볶이 6종(각 2인) 각4,980~5,480원
  ※사조대림 국물떡볶이 외 ※동일 브랜드 내 교차구매 가능

  1+1
  오뚜기/풀무원 인기 냉장면 6종(각 2인) 각6,980~9,980원
  ※막국수/쫄면/짜장/짬뽕/우동
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  인기 그릭 요거트 6종(상품별 상이) 2개 이상 구매시 1개당 각1,900~7,000원 
  ※1개 구매시 각3,800~14,000원
  ※점별 운영상품 상이 ※동일 브랜드 내 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 510원 할인
  지평 평생 막걸리(750ml) 990원
  ※점별 한정수량 ※조기 품절될 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  서울 체다 슬라이스 치즈(360g) 8,980원
  ※전점 11,000개 한정 수량

  1+1
  서울우유 유기농 우유(700ml) 4,980원

  3/29 토, 3/30 일
  1+1
  샴푸/컨디셔너/트리트먼트(상품별 상이)
  ※일부 품목 제외 ※동일 품목 내 동일 가격 간 교차구매 가능
  ※일부상품 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)

  3/29 토, 3/30 일
  1+1
  바디워시(상품별 상이)
  ※일부 품목 제외 ※동일 품목 내 동일 가격 간 교차구매 가능
  ※일부상품 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  요리하다 냉동밥 전품목(상품별 규격 상이) 2,000
  ※1입 상품 한정

  1+1
  냉동 안주류 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  냉동 돈까스 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  하코야 닭꼬치 전품목(상품별 규격 상이)
  ※동일가격 간 교차구매 가능

  1+1
  냉동 피자 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~4/2(수)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  상온 국물요리 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  컵밥/프리미엄밥 전품목(상품별 규격 상이)
  ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  카레/짜장 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  포도씨유/카놀라유/해바라기유 전품목(상품별 규격 상이)
  ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  고추장/된장/쌈장 전품목(상품별 규격 상이)
  ※일부품목 제외
  ※기간 : 3/27(목)~3/30(일) ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  케찹/마요네즈 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  파스타소스 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  땡 잡았다! 인기 카테고리 전품목 세일! 
  ※기간 : 3/27(목) ~ 4/9(수) ※품목별 기간 상이

  1+1
  스테비아/알룰로스 전품목(상품별 규격 상이) 
  ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  티젠/담터 콤부차 30입/립톤 아이스티 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※담터/티젠 브랜드별 교차구매 가능
  ※립톤 아이스티 각 동일 입수별 교차구매 가능

  1+1
  참치액/코인조미료 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  16입 두유 전품목(각 190ml×16입)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  대용량 과채주스 40종(각 1L~1.89L)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  2+1
  스타벅스/네스카페 돌체구스토 캡슐커피 전품목(상품별 상이)
  ※점포별 입고 상품 상이 ※기간 : 3/27(목)~4/2(수)
  ※동일 호환 기기별 교차구매 가능

  2+1
  카누 캡슐 커피 전품목(상품별 상이)
  ※시즌 캡슐/캡슐기획 제외 ※동일 호환 기기별 교차구매

  2개이상 50%
  아기치즈 전품목(상품별 규격 상이)
  ※동일 브랜드 간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  냉장 밀키트 전품목(상품별 상이)
  ※점별 운영상품 상이 ※기간 : 3/27(목)~3/30(일) ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  냉동 밀키트/국/탕 전품목 최대 165종(상품별 규격 상이)
  ※점별 운영상품 상이 ※기획 상품 제외
  ※기간 : 3/27(목)~4/2(수) ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  세탁세제 전품목(상품별 상이) ※교차구매 가능

  2개이상 50%
  크리넥스/스카트 물티슈 전품목(상품별 상이)※점별 운영상품 상이
  ※기간 : 3/27(목)~3/30(일) ※교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  땡잡았다 롯데마트 추천! 매일 신선 먹거리!
  ·신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 2(수) ※상품별 기간 상이

  ZERO SUGAR
  제로슈가 육류 2종 L.POINT 최대 50%

  L.POINT 50%
  제로슈가 양념 소불고기(600g/냉장/원산지별도표기) 9,900원

  L.POINT 6,000원
  제로 슈가 훈제오리 슬라이스(400g/냉장/원산지 별도표기) 7,990원

  땡큐 닭볶음탕 연중 최저가 도전!
  ※연중 기간 : 24년 1월 1일 ~ 25년 3월
  ※조기 품절될 수 있습니다
  1+1 땡큐 닭볶음탕용(1kg/냉장/국내산) 8,990원

  청정지역 제주돼지 주말 반값! L.POINT 50%
  제주돼지 삼겹살/목심(각 100g/냉장/국내산)
  ※기간 : 3/27(목) ~ 3/30(일)
  ※조기 품절될 수 있습니다
  ※일부점 프로모션 상이

  해양수산부와 함께하는 수산대전 20%
  ※기간 : 3/27(목) ~ 4/2(수) ※해당상품에 한함 ※산지시황에 따라 조기품절될 수 있습니다
  ※L.POINT 회원 기간 내 주차별 1인 최대 1만원 할인 ※이미지 연출컷입니다
  ※페이지 하단 카드할인 세부내용 참고

  롯데/BC/신한/NH농협/삼성카드 40% -> 수산대전 20%
  반값 광어회(450g내외/냉장/광어 : 국산) 24,000원
  ※조기 품절될 수 있습니다
  ※1인 1팩 한정판매 ※입점점에 한함

  롯데/BC/신한/NH농협/삼성카드 40% -> 수산대전 20%
  내가 만드는 광어회필렛(100g/냉장/광어:국산) 4,560원
  ※1인 1팩 한정판매

  롯데/BC/신한/NH농협/삼성카드 10% -> 수산대전 20%
  국산 손질 꽃게(500g/포장/냉동/국산) 10,728원

  롯데/BC/신한/NH농협/삼성카드 20% -> 수산대전 20%
  참조기(대/마리/해동/국산) 2,432원

  롯데/BC/신한/NH농협/삼성카드 30% -> 수산대전 20%
  고등어자반(특대/1손/냉장/고등어,천일염:국산) 3,915원

  롯데/BC/신한/NH농협/삼성카드 25% -> 수산대전 20%
  완도 활 전복(왕/대)(마리/냉장/국산) 3,540/1,980원

  물가안정 가성비 채소 1,990/3,990원

  대파(700g/봉/국산) 1,990원
  ※점별 취급상품 상이

  한가득 시금치(400g/팩/국산) 1,990원

  상생 다다기오이(5입/봉/국산) 3,990원
  ※점별 취급상품 상이

  뉴질랜드 킹 단호박(통/뉴질랜드산) 3,990원

  2팩 이상 구매시 50%
  하루 한끼 한우 11종(품목별 규격 상이)
  ※냉장/국내산 한우고기/1등급 ※교차구매 가능

  L.POINT 50%
  몸보신용 한우 사골/잡뼈(각 3kg/냉동/국내산 한우고기) 6,990/4,990원

  호주청정우 한끼기획 9종(품목별 규격 상이/냉장/호주산) 2개 이상 구매시 1개당 각8,990원
  ※1개 구매시 각9,990원
  ※교차구매 가능

  L.POINT 최대 30%
  동원이 항공 직수입하여 더욱 신선하고 믿을 수 있는 양고기
  동원청정램 전품목(100g/냉장/호주산 양고기)

  L.POINT 15,000원
  칠레 연어 1위 업체 사전 계약 물량 대방출
  프리미엄 파타고니아 생연어 필렛(500g/냉장/칠레산) 17,900원 ※조기 품절 될 수 있습니다.

  1+1
  1+1 기획팩이 하나 더!
  동원 프리미엄 명란/양념명란 기획(번들팩 1+1)(각 100g×2/냉장/원산지 별도표기) 각16,900원 ※교차구매 가능

  1+1
  한입 꼬마육포/BBQ 통통 오징어(125g/90g) 각9,980원
  ※원산지 별도표기 ※교차구매 가능

  32봉 특별기획
  동원 양반 들기름김 기획팩(4g×16봉×2입/원산지 별도표기) 9,980원

  ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
  ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
  롯데마트 추천 봄맞이 제철과일
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 2,000원
  12Brix 이상 참외만 선별!
  성주 상생참외(3~6입/봉/국산) 7,990원

  12Brix 이상 고당도 오렌지를 한 봉 가득 담아 9,990원
  한 봉 가득 오렌지(봉/미국산)
  ※매장 내 비치된 전용 봉투에 담아 주세요
  ※조기 품절될 수 있습니다

  태국에서 비행기 타고 날아온 망고!
  태국 남독마이 망고(개/태국산) 4개 구매시 9,960원
  ※1개 구매시 3,990원
  ※조기 품절될 수 있습니다

  온가족 나들이 즉석 먹거리 추천

  L.POINT 50%
  반값 알뜰 초밥(20입)(1팩/원산지 별도표기) 9,990원

  L.POINT 25%
  온가족 한통가득 탕수육(1팩/원산지 별도표기) 9,743원

  김밥&유부&샌드 한판샌드(1팩/원산지 별도표기) 11,990원

  L.POINT 30%
  5무 훈제오리(1팩/원산지 별도표기) 11,193원

  국내산 통족발(특대)(1팩/국내산) 19,800원 ※족발매장 입점점 한함

  5,000원 할인
  대용량 가득바삭파이(1팩/원산지 별도표기) 7,900원

  반찬 3+1팩 골라담기(4팩/원산지 별도표기) 9,990원
  ※기간 : 3/27(목) ~ 3/30(일)
  ※비움, 미찬, 도시곳간, 무명찬반,초이스쿡 입점점 한함
  ※정상가 4,000원 이하 상품 한정

  세계맥주 67종(상품별 용량 상이) 5캔 구매시 9,900원
  ※1캔 구매시 각 3,000~3,300원

  이달의 맥주 (500ml) 
  5캔 구매시 10,000원
  ※1캔 구매시 2,500원

  견과류 6종(상품별 용량 상이/원산지 별도표기) 각9,990원

  딸기/토마토/수입포도전품목(상품별 규격 상이/원산지 별도표기) L.POINT 최대 3,000원 할인
  ※딸기, 토마토 전품목 L.POINT 3천원 할인,수입포도 전품목 L.POINT 2천원 할인

  임금님표 이천쌀(8kg/국산)/해보드미(20kg/국산) 29,900/49,900원

  풀무원 특등급 국산 콩나물(200g/국산)/풀무원 1+등급 목초란(대란/15입/국산) 990/5,990원
  ※조기 품절될 수 있습니다

  GAP 팽이버섯(3입/봉/국산)/GAP 향기로운 송이버섯(270g×2봉/국산) 1,490/2,990원

  2송이 이상 각 1,000원 할인
  고산지 바나나(송이/필리핀산) 2송이 이상 구매시 1송이당 각2,990원 ※1송이 구매시 3,990원
  ※점별 운영상품 상이(스위티오/감숙왕/허니글로우)
  ※교차구매 가능 

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
 
  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다 인기 먹거리 최대 1+1 최대 50% !

  1+1 요리하다 포기김치(900g) 12,900원

  1+1 봉지라면 6종(상품별 규격상이) 각3,320~8,800원
  ※팔도&해찬들 차돌된장찌개라면 4개입 外 5종
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1 오뚜기밥 찰현미/오곡/발아흑미(각 210g×3입) 각6,480원 ※교차구매 가능

  1+1 CJ 비비고 열무김치/총각김치(각 900g) 각15,900원 
  ※기간 3/27(목)~4/2(수) ※교차구매 가능

  1+1
  치킨너겟/텐더 13종(상품별 규격 상이) 각5,980~12,980원
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  냉장 꼬치어묵 2종(삼진/고래사) 각9,980원
  ※동일상품에 한함

  1+1
  오뚜기 생쫄면/메밀소바(각 2인분) 각6,980원 ※교차구매 가능

  1+1
  CJ/대림/풀무원 유부초밥 3종(상품별 상이) 각5,480~6,980원
  ※기간 3/27(목)~4/2(수) ※동일상품에 한함

  1+1
  크래프트 슈레드 치즈 4종 각8,990원
  (상품별 상이) ※교차구매 가능

  1+1
  블루샥 라떼, 피넛 라떼(상품별 상이)/이디야 블렌드 아메리카노(1g×100입) 각12,900/25,900원
  ※블루샥 라떼 교차구매 가능 ※이디야 블렌드 아메리카노 동일 품목만 적용 가능

  1+1
  글로벌 수입과자 11종(상품별 상이) 각3,500~15,980원
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  동서 포스트 아몬드 후레이크(620g)/켈로그 첵스초코(570g) 9,780/8,880원 ※동일상품에 한함

  1+1
  상온/냉장커피 음료 63종(상품별 상이) 각1,880~4,390원
  ※동일 브랜드 내 동일 가격간 교차 구매 가능

  1+1
  코카콜라/코카콜라 제로 각3,990원
  (각 1.8L) ※교차구매 가능

  1+1
  롯데마트 단독
  링티 레몬 라이트(1L) 3,980원

  2개 이상 10%
  농심 라면 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,952~5,022원
  ※1개 구매시 각 3,280~5,580원
  ※기간 : 3/27(목)~4/4(금), 4/7(월)~4/9(수)
  ※농심 올리브 짜파게티 5개입 外 14종 ※교차구매가능

  농심 라면 전품목 1만원 이상 구매시 라면 전용 계량컵 증정!
  ※전점 15,000개 한정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
  ※구분자 " ㉡ "를 확인하세요 ※영수증 지참 후 '도와드리겠습니다' 센터에서 수령

  5개 이상 각2,980원 할인
  씨제이 햇반 용기 죽 7종(상품별 상이) 5개 이상 구매시 1개당 각2,000원 ※1개 구매시 각 4,980원 ※교차구매 가능

  2개 이상 50%
  진주햄 천하장사 2종(각 448g, 오리지널/치즈) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 각 7,980원
  ※교차구매 가능

  2개 이상 50%
  한성 크런치 롤 유부초밥(240g) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 7,980원

  2개 이상 50%
  제주우유 3종(각 750ml) 2개 이상 구매시 1개당 각2,490원 ※1개 구매시 각 4,980원
  ※A2유기농/무항생제/저지 ※교차구매 가능

  2개 이상 50%
  매일 헬스케어 셀렉스 9종(상품별 상이) 2개 이상 구매시 1개당 각2,990~17,500원
  ※1개 구매시 각5,980~35,000원
  ※교차구매 가능 ※점별 운영상품 상이

  2개 이상 50%
  화제의 레몬/레몬생강즙 6종(상품별 상이) 2개 이상 구매시 1개당 각4,995~7,950원 ※1개 구매시 각9,990~15,900원 ※교차구매 가능

  동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  동원 수산/과일 통조림 8종(상품별 상이) 2,280~12,480원 ※기간 : 3/27(목)~3/30(일)
  ※동일 가격간 교차구매 가능

  1+1
  양반밥 찰진백미밥/현미밥/흑미밥(각 130g×6입) 각10,480원 ※교차구매 가능

  1+1
  동원 양반 야채바삭 김말이(406g) 8,980원

  1+1
  동원 롤 유부초밥2종/리얼크랩스 더킹오리지널(상품별상이) 각6,980원 ※교차구매 가능

  롯데웰푸드 행사상품 3만원 이상 구매시 10,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  롯데 의성마늘 순한 비엔나/프랑크(상품별 상이) 각7,980/8,980원
  ※동일상품에 한함

  롯데 의성마늘 빅그릴 비엔나(300 g×2) 6,980원

  사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령

  사조대림 실속김밥세트(510g) 7,980원

  1+1
  사조 살코기/마일드/고추참치(각 85g×4입) 각8,980원
  ※교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  2개 이상 50%
  동원 딤섬 4종(상품별 규격상이) 2개 이상 구매시 1개당 각5,490~5,990원
  ※새우하가우/부추창펀/샤오롱바오/부채교 ※교차구매 가능

  2개 이상 50%
  고래사 어묵떡볶이(520g) 2개 이상 구매시 1개당 각3,300원 ※1개 구매시 6,600원
  ※기간 : 3/27(목)~4/2(수)

  2개 이상 50%
  나뚜루 파인트 전품목(각 474ml) 2개 이상 구매시 1개당 각7,950원 ※1개 구매시 각15,900원
  ※교차구매 가능

  2개 이상 50%
  논알콜맥주 22종(상품별 상이) 2개 이상 구매시 1개당 각690~1,650원 ※1개 구매시 각1,380~3,300원 ※교차구매 가능

  2개 이상 40%
  종근당건강 락토핏 6종(상품별 상이) 2개 이상 구매시 1개당 각9,540~17,940원 ※1개 구매시 각15,900~29,900원 ※교차구매 가능

  400원 할인 -> 롯데/BC/신한/NH농협/삼성카드 400원 할인
  오늘좋은 1등급 우유(900ml×2입) 2,990원 ※기간 : 3/27(목) ~ 3/30(일)

  롯데마트 단독
  빙그레 바나나맛우유 수세미 굿즈 기획(240ml×6입) 12,980원

  CJ 비비고 육즙가득 고기만두(360g×2입) 10,980원

  동원 통그릴 비엔나(1kg) 10,980원

  롯데마트 단독
  hy 야쿠르트 1971 키링 기획(450ml×4입) 12,800원

  신상품
  종가 오래아삭 포기김치(3.7kg) 35,900원

  서울 흰우유(2.3L) 5,780원
  ※기간 3/27(목)~4/2(수)

  롯데마트 단독
  칠성사이다 유리병 기획(300ml×4입) 3,980원

  신상품
  해태 생생감자칩 랍스터맛(85g)/샌드에이스 그릭요거트(204g)/자유시간 누가마블 딸기(408g) 2,480/3,980/10,980원

  신상품
  오리온 마켓오 브라우니 바이트 제주말차(240g) 5,280원

  신상품
  롯데 명가 찰떡파이 초코바나나(250g) 5,580원

  샘표식품 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " S "를 확인하세요 ※결제시 계산대에서 수령

  1+1
  샘표 국산 100% 조선고추장/토장 (상품별 규격 상이) 29,900/22,900원 ※동일상품에 한함

  동서식품 전품목 5만원 이상 구매시 5,000원 롯데상품권 증정
  ※동서식품 치즈 제외 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " # "를 확인하세요 ※결제시 계산대에서 수령

  2,000원 할인
  동서 맥심 모카골드 마일드/화이트골드 220입+20입 증량업 기획(상품별 상이) 각31,220원

  CJ제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " C "를 확인하세요 ※결제시 계산대에서 수령

  1+1
  CJ 더건강한 베이컨(90g×2입) 육공육 더얇은 슬라이스 씬(250g) 각9,980원
  ※교차구매 가능

  1+1
  해찬들 원조 태양초 고추장(1.5kg) 18,900원

  2개 이상 각 1,000원 할인
  CJ 고메 소바바 치킨 5종(상품별 상이) 2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원 ※교차구매가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)

  </div>
  `,
  `
  <div>
  땡잡았다! 생활 필수품 최대 1+1 최대 50% !

  1+1 
  케라시스 클린노트 샴푸/트리트먼트(각 800ml) 각12,500원 ※베이비파우더향/화이트코튼향 ※교차구매 가능

  1+1 
  리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 각15,900원
  ※손상모발용/지성두피용 ※교차구매 가능

  1+1 
  아이깨끗해 핸드워시 용기/리필 20종 각5,900~12,980원
  (상품별 상이) ※점별 운영상품 상이
  ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  오랄비 초미세모 잇몸케어 칫솔(3개) 11,900원

  롯데/BC/신한/NH농협/삼성카드 1+1 
  섬유유연제 최대 66종
  ※다우니/피죤/아우라/스너글/버넬 ※점별 운영상품 상이
  ※동일 품목 내 동일 가격간 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참

  1+1 
  섬유 탈취제(용기) 5대 브랜드 각7,500~10,900원
  (상품별 상이) ※일부품목 제외 ※점별 취급품목 상이
  ※동일 브랜드 내 동일가격에 한해 교차구매 가능

  1+1 
  변기 세정제(상품별 상이) 각1,000~8,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  주방세제(상품별 상이) 각1,900~10,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  로션 미용티슈(상품별 상이) 각13,400~16,900원 ※행사상품에 한함
  ※점별 취급품목 상이 ※동일 상품에 한함

  LG생활건강
  행사상품 3만원 이상 구매시
  여행용 세트 증정

  코카콜라
  행사상품 5만원 이상 구매시
  아이스 박스 증정

  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개) 
  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령 
  ※구분자 "@"를 확인하세요 ※사은품 한정수량 ※사은품 소진시 자동 행사 종료

  1+1
  엘라스틴 헤드스파&퍼퓸 샴푸/컨디셔너(각 855ml) 각13,900원
  ※로지드림/디어자스민 ※점별 운영상품 상이 ※교차구매 가능

  1+1
  온더바디 퍼퓸 바디워시(각 900g) 각9,900원
  ※피오니로즈/화이트머스크
  ※점별 운영상품 상이 ※교차구매 가능

  1+1
  페리오 알파치약 기획(185g×4입) 10,900원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할
  ※해당 단일카드로 전액 결제시에 한함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  1+1
  도브 바디케어 전품목(비누/핸드워시/바디워시/바디로션) 각5,900~15,900원
  ※점별 운영상품 상이 ※동일 품목 내 동일 가격간 교차구매 가능

  2개 이상 50%
  도브 베이비 리치모이스처 로션/워시(각 400ml) 2개 이상 구매시 1개당 각7,700~8,000원
  ※1개 구매시 각15,400~16,000원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  2개 이상 50% 
  싸이닉 엔조이 선케어 3종(선크림 200g/선에센스 80ml/선스틱 20g) 2개 이상 구매시 1개당 각6,900~8,900원
  ※1개 구매시 각13,800~17,800원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  2개 이상 50%
  좋은느낌 생리대(상품별 상이)
  ※팬티라이너/탐폰/일부품목 제외 ※교차구매 가능

  2개 이상 50%
  슬릭 면도기/날/쉐이빙폼 등 8종(상품별 상이) 2개 이상 구매시 1개당 각3,100~21,950원
  ※1개 구매시 각6,200~43,900원
  ※점별 입점상품 상이 ※동일 품목간 교차구매 가능

  최대 50%
  스프레이 주거세정제(상품별 상이) 각3,950~7,900원
  ※행사상품에 한함 ※점별 취급품목 상이
  ※동일 브랜드 내 동일가격간 교차구매 가능

  2개 이상 50%
  벚꽃에디션 미용티슈/키친타월/물티슈(상품별 상이) 2개 이상 구매시 1개당 각1,000~3,950원
  ※교차구매 가능 ※1개 구매시 각2,000~7,900원

  롯데/BC/신한/NH농협/삼성카드 2,000원 할인
  벚꽃에디션 무형광 3겹 화장지 9,900원
  (30m×30롤) ※페이지 하단 카드할인 세부내용 참고

  1매당 8.9원 초가성비 물티슈!
  코코토토 데일리 물티슈(100매×10입) 8,900원

  AHC 행사상품 3만원 이상 구매시 
  5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " A "를 확인하세요 ※결제시 계산대에서 수령

  AHC 유스래스팅 아이크림(35ml×2입+7ml×2입)/AHC 바이탈 골든 콜라겐 크림(50g×2입+토너 30ml) 각25,900원

  브랜드 화장품 롯데상품권 증정!
  ※기간 : 3/27(목)~3/30(일) ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※단일 브랜드 결제시에 한함 ※비연속식 증정(1인 1일 1매) ※당일 영수증에 한함
  ※점별 입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  해당카드 5만원 이상 구매시 5,000원 롯데상품권 증정
  해당카드 10만원 이상 구매시 10,000원 롯데상품권 증정

  디펜드 2개 이상 구매시 다용도 가방 증정!
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
  ※구분자 " D "를 확인하세요
  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령

  2+1
  디펜드 37종(상품별 상이) 각5,250~23,900원 ※동일 가격간 교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다 홈 인테리어ㆍ잡화류 최대 1+1 최대 50%

  브랜드 프라이팬/밀폐용기(상품별 상이) 8,900~83,330원 최대 50%  ※입점점에 한함

  40%
  테팔 스타트이지/에브리데이쿡 11종(상품별 상이) 각18,480~35,880원

  50%
  해피콜 쿡메이트 프라이팬/궁중팬 2종(28cm) 각18,900원

  40,000원 할인
  쿡셀 더블 블랙 프라이팬 2P세트(20cm/28cm) 49,900원

  50%
  락앤락 익스트롱 프라이팬 6종(상품별 상이) 각15,450~18,450원

  글라스락 인기 밀폐 시리즈 L.POINT 50%
  ※스마트/퓨어오븐/모듈러/퓨어핸디/간편캡 등

  스텐락 인기 밀폐 시리즈 L.POINT 50%
  ※퓨어/이지오픈
  
  락앤락 인기 밀폐 시리즈 L.POINT 50%
  ※비스프리모듈러/더클리어/클리어비스프리/프리저핏/스마트킵/클래식 등

  1+1 기획
  글라스락 센스 핑크 에디션 1+1기획(상품별 용량 상이)각5,900~19,900원

  2개 이상 50%
  크린랩 이중지퍼백/뽑아쓰는 크린백(상품별 상이) 2개 이상 구매시 1개당 각3,145~7,695원 
  ※1개 구매시 각6,290~15,390원 ※교차구매 가능

  2개 이상 50%
  오늘좋은 데일리 울트라쿨 냉감패드(SS : 110×200cm/Q : 150×200cm) 2개 이상 구매시 1개당 각14,950/19,950원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  990원

  오늘좋은 데일리 무세제 클리너(3P/11×3×6cm) 990원 정상가 1,500원

  오늘좋은 베이직 바스켓 7종/클래식 바스켓 4종(상품별 상이) 990원 정상가 각1,000원~1,500원

  베이직 남성/여성덧신/양말(각 1매) 990원 정상가 2,000원
  ※입점점에 한함 ※해당상품에 한함
  ※점별 재고 상이

  동원 뉴트리플랜 소프트뮨 강아지 습식간식 3종(각 100g) 990원 정상가 각 2,000원

  4,990원

  룸바이홈 럭셔리 호텔 세면타월(각 40×60cm, 네이비/그레이/화이트) 4,990원 정상가 각 6,990원
  ※ 입점점에 한함 ※ 해당상품에 한함 ※ 점별 재고 상이

  맥스 폭발방지 CRV 부탄가스(4입) 4,990원 정상가 6,790원

  향기가득 프리미엄 워셔액 4종(각 1.8L×2) 4,990원 정상가 각 5,900원

  1+1 기회팩
  유니랩 위생장갑(200매+200매)

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  50%
  풍년 마레러스터 압력솥(8인) 59,000원

  롯데/BC/신한/NH농협/삼성카드 최대 50%
  포트메리온(상품별 용량 상이) 5,340~41,665원
  ※입점점에 한함 ※ 점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 40%
  3M 주방 고무장갑/수세미 전품목(상품별 상이) 2개 이상 구매시 1개당 각1,308~6,594원
  ※1개 구매시 각2,180~10,990원
  ※기간 : 3/27(목)~4/2(수) ※교차구매 가능 ※입점점에 한함 ※점별 재고 상이

  2개 이상 50%
  테이프클리너 전품목(상품별 상이) 2개 이상 구매시 1개당 각1,950~10,390원
  ※1개 구매시 각3,900~20,780원
  ※일부품목 제외
  ※조기 품절될 수 있습니다 ※교차구매 가능 ※기간 : 3/27(목)~4/2(수)

  30%
  크린랩 막대걸레 더블헤드(표준형/대형)/크린랩 물걸레 청소포(각 30매, 표준형/대형) 각5,030~18,540원

  50%
  아이리스 시스템 수납함(중형 2호)/빌드업 바스켓 3종(상품별 상이) 각1,000~9,950원 ※입점점에 한함

  30%
  오늘좋은 세탁이 편한 빨아쓰는 베개(소 : 40×60cm/대 : 50×70cm) 15,330/20,930원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각6,930~7,630원 
  ※1개 구매시 각9,900~10,900원 ※교차구매 가능 ※입점점에 한함
  ※뉴발란스, 아디다스, 언더아머, 네파 등 ※점별 상품 상이

  2+1
  남성 브랜드 언더웨어(상품별 상이) 6,900원 ※입점점에 한함
  ※브랜드 : 트라이/보디가드/베이직엘르 외 ※교차구매 가능

  10,000원 할인
  트래커 브리튼 여행가방(16/20/24/28인치) 49,000~89,000
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  7,990원

  SOUP/AD HOC 데일리진(스트레이트/테이퍼드) 7,990원 정상가 각 14,800원

  오늘좋은 심리스 런닝/땀패드 런닝 7,990원 정상가 각 8,900~12,900원 ※입점점에 한함 

  코코도르 차량용 미니멀 디퓨저(50mlX2) 7,990원 정상가 각 9,900원

  오늘좋은 패브릭 발매트 11종(메모리폼/워셔블 外) 7,990원 정상가 각 10,900원~12,900원

  9,990원

  블랙 박스 카트(M, 38×29×85.5cm) 9,990원 정상가 15,900원

  뉴발란스/데상트 러닝 양말(각 2매) 9,990원 ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  에너자이저 맥스10+5입(AA/AAA) 9,990원 정상가 각 12,900원

  대림바스 PVC 샤워호스(실버 1.5m/어반 그레이, 화이트 2m) 9,990원 정상가 13,900/14,900원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)

  </div>
  `,
  `
  <div>
  1+1
  유니랩 셰프장갑(소/중/대)/원데이 일회용 수세미(핑크/그린) 각4,990원 ※동일 상품에 한해 교차구매 가능

  1+1 기획팩
  크린랩 크린장갑(200매)/이중지퍼백(20매,소/중/대)/랩(30X50cm) 각3,190~9,090원

  L.POINT 20%
  오늘좋은 아이스박스 3종(미니 5L/17L/38L) 15,920/31,920/71,920원

  2개 이상20%
  오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 3,920원 ※1개 구매시 4,900원

  1+1
  코렐 냉면기 4종(상품별 용량 상이) 각21,900원
  ※입점점에 한함 ※점별 재고 상이 ※교차구매 가능

  40%
  테팔 인텐시움 통 3중 냄비 3종(상품별상이) 26,280~37,680원

  30%
  코렐 목련/더블링그린티/시나모롤 外 5,530~15,330원

  브랜드 와인잔 2P(슈피겔라우/쇼트즈위젤 外) 각9,900원

  30%
  오늘좋은 데일리 피치스킨 이불(S/Q) 16,030/20,930원
  ※S : 150×200cm/Q : 180×220cm

  2+1
  크린랩 물걸레 청소포 30매(표준형/대형, 각 2P) 14,380/16,580원 ※입점점에 한함 ※행사 상품에 한함

  꽃 피는 다육 포트 3종기획 각9,990원

  5,000원 할인
  오늘좋은 점보 리빙박스(100L/75×51×37cm) 19,900원
  ※입점점에 한함

  르젠 써큘레이터형 스탠드 선풍기(45W) 39,900원

  최대 40%
  세차도구 전품목(상품별 규격상이)
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  2+1
  불스원샷 70000(기획상품) 32,000원
  ※입점 점포에 한함 ※행사 상품에 한함

  러닝용품 전품목 8,720~39,920원 ※입점점에 한함
  ※브랜드 : 나이키/아디다스/언더아머 外

  10,000원 할인
  크록스 아동/성인 크록밴드 클로그 각33,900~39,900원
  ※기간 3/27(목)~4/2(수) ※행사상품에 한함 ※입점점에 한함

  브랜드 반팔&반바지 19,900~29,900원
  ※입점점에 한함 ※브랜드 : 휠라/푸마/르까프 外

  여성 브랜드 언더웨어 브라/팬티 4,900/9,900원
  ※입점점에 한함 ※브랜드 : 비비안/비너스/오테나 外

  쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
  ※기간 3/20(목)~4/23(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령

  최대 20%
  쿡셀 프라이팬/냄비 18종(상품별 상이) 31,120~49,900원

  에너자이저 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
  ※기간 3/27(목)~4/9(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉧"를 확인하세요 ※결제시 계산대에서 수령

  10% 에너자이저 맥스플러스 4입(AA/AAA) 8,370원

  레고 마블 인기 완구 30종 최대 L.POINT 30% 할인

  L.POINT 30%
  레고 콜의 타이탄 드래곤 로봇(71821) 104,300원
  (12세 이상/35.6×7.1×37.8cm) ※입점점에 한함

  L.POINT 30%
  마블 14인치 피규어세트(아이언맨/스파이더맨) 55,930원
  (6세 이상/49×11.2×45cm) ※입점점에 한함

  개이득 냥이득!
  ※기간 : 3/27(목)~4/9(수)

  colioli 득템위크
  반려동물식품&용품 500여종 할인!
  반려동물식품/용품 3만원 이상 구매시 5천원 롯데상품권 증정!
  ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※기간 : 3/27(목)~4/9(수)
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※페이지 하단 카드할인 세부내용 참고

  1+1 기획
  건강백서 사료 5종(상품별 용량 상이) 23,500~28,500원
  ※푸들/말티즈/피부/관절/캣 ※입점점에 한함 ※점별 수량 상이

  콜리올리 깔끔하게 사용하는 1회용 패드(M 100매/M 200매/L 40매) 각7,140~14,340원 ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  L.POINT 10% -> 롯데/BC/신한/NH농협/삼성카드 10% 추가할인
  이나바 챠오츄르 버라이어티(20P/40P) 각12,069/21,465원
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 30% 
  콜리올리 반려동물 방석 3종(상품별 상이) 각16,030~17,430원
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  탑텐, 탑텐키즈, 탑텐밸런스
  롯데마트 단독 5만원 구매 시 5천원 즉시할인
  ▶기간 : 3/27(목) ~ 4/9(수)
  ※최종 결제 금액에서 5천원 차감(탑텐 영수증 확인 필수)
  ※탑텐, 탑텐키즈, 탑텐밸런스 타 할인 쿠폰 행사와 중복 적용 불가

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
  ·전단적용기간 : 2025. 3. 27(목) ~ 2025. 4. 9(수) < 신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 2(수) >
  ·제타플렉스 서울역점

  온국민이 땡잡는 날, 땡큐절
  3.27~4.9

  일주일 내내 땡잡았다!
  롯데/BC/신한/NH농협/삼성카드 초특가!
  기간 : 3/27(목) ~ 4/2(수)
  ※L.POINT 회원 해당카드 최종 결제 금액 기준입니다
  ※페이지 하단 카드할인 세부내용 참고

  TV CF 방영상품
  1등급 한우(냉장) 전품목 50%
  한우 등심(1등급/100g) 5,990원
  ※할인 전 가격 11,980원(냉장/국내산 한우고기) ※카드할인/다다익선 등 상품별 프로모션 상이

  TV CF 방영상품
  활대게 반값! 3,495원
  ※할인 전 가격 6,990원(100g/냉장/러시아산)

  파프리카 반값! 3개 이상 구매시 1개당 1,990원(개/국산)
  ※할인 전 가격 1,990원(개/국산)

  대용량 1.5kg 대추방울토마토! 8,990원
  ※할인 전 가격 12,990원(1.5kg/박스/국산)

  TV CF 방영상품
  행복생생란!
  2판 이상 구매시 1판당 4,490원
  ※(할인 전 가격) 1판 구매시 6,990원(대란/30입/판/국산)
  ※기간 : 3/27(목) ~ 3/30(일) ※조기 품절될 수 있습니다

  TV CF 방영상품
  10호 닭 큰 치킨! 7,495원
  ※할인 전 가격 14,990원 (1마리/국내산 계육)

  땡잡았다! 롯또 혜택!
  ▶ 기 간 : 3/27(목)~4/9(수)
  ※이벤트 세부사항은 QR코드 스캔 후 이벤트 페이지에서 확인하세요

  EVENT 1. 
  매일 오전 10시~오후 1시 최대 10,000원 롯또쿠폰 증정!
  7만원 이상 구매시 10%할인(최대 1만원 한도)

  EVENT 2. 
  총 1천명! 3개 채널에서 각 1만원 이상 구매시 최대 10,000점 스노우플랜 추첨 증정!

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  땡 이 가격! 잡았다! 오늘만 이 가격! 하루특가!

  3/27 목

  놀라운 가격 1천원대 한우
  L.POINT 50% -> 롯데/BC/신한/NH농협/삼성카드 34% 추가할인
  1등급 한우 국거리/불고기(각 100g/냉장/국내산 한우고기)
  ※양지 국거리용 제외
  ※페이지 하단 카드할인 세부내용 참고

  오픈런 필수템!
  L.POINT 3,000원
  상생 딸기(500g/팩/국산) 2,990원
  ※조기 품절될 수 있습니다 ※점별 운영상품 상이
  ※전점 1만팩 한정, 1인 2팩 한정

  대용량 새송이버섯2,990원!
  한가득 상생 새송이버섯(800g/팩/국산) 2,990원
  ※조기 품절될 수 있습니다

  3/28 금

  L.POINT 50% 
  남해안 산지직송!
  남해안 가리비(1.2kg/냉장/국산) 6,450원

  L.POINT 50% 
  연중 최대 할인 도전!
  냉동 번들 새우살(250g×2/냉동/원산지 별도표기) 11,950원

  3/29 토 

  파이/비스켓(상품별 상이) 롯데/BC/신한/NH농협/삼성카드 3개 구매시 9,900원
  ※3의 배수로 적용
  ※브랜드 관계없이 교차구매 가능
  ※3,300원 이하 상품 제외
  ※L.POINT 회원 해당카드 결제시 적용
  ※공구핫딜/기획상품/대용량상품 제외
  ※일부품목 제외 ※1인 1회 최대 9개 한정
  ※페이지 하단 카드할인 세부내용 참고 

  3/30 일

  롯데/BC/신한/NH농협/삼성카드 2개 이상 구매시 50%
  삼다수 그린(2L×6입) 2개 이상 구매시 1개당 각3,240원
  ※1개 구매시 각6,480원
  ※제주점 판매가 상이
  ※페이지 하단 카드할인 세부내용 참고

  땡 잡았다! 단, 4일간 주말특가!
  ※기간 : 3/27(목) ~ 3/30(일)

  1+1
  교자만두/얇은피만두 13종(상품별 규격 상이) 각6,480~12,980원
  ※CJ/풀무원/오뚜기/하림산업
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  사조대림 인기 어묵/맛살 12종(상품별 상이) 2개 이상 구매시 1개당 각990~3,490원
  ※1개 구매시 각1,980~6,980원
  ※스노우크랩킹/로얄크랩/선사각어묵 외 ※점별 운영상품 상이
  ※교차구매 가능

  1+1
  간식 어린이 소시지 4종(상품별 상이) 각6,980~8,480원
  ※CJ 맥스봉 오리지널 외 ※동일 가격 간 교차구매 가능

  1+1
  CJ/대림/청정원/풀무원 떡볶이 6종(각 2인) 각4,980~5,480원
  ※사조대림 국물떡볶이 외 ※동일 브랜드 내 교차구매 가능

  1+1
  오뚜기/풀무원 인기 냉장면 6종(각 2인) 각6,980~9,980원
  ※막국수/쫄면/짜장/짬뽕/우동
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  인기 그릭 요거트 6종(상품별 상이) 2개 이상 구매시 1개당 각1,900~7,000원 
  ※1개 구매시 각3,800~14,000원
  ※점별 운영상품 상이 ※동일 브랜드 내 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 510원 할인
  지평 평생 막걸리(750ml) 990원
  ※점별 한정수량 ※조기 품절될 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  서울 체다 슬라이스 치즈(360g) 8,980원
  ※전점 11,000개 한정 수량

  1+1
  서울우유 유기농 우유(700ml) 4,980원

  3/29 토, 3/30 일
  1+1
  샴푸/컨디셔너/트리트먼트(상품별 상이)
  ※일부 품목 제외 ※동일 품목 내 동일 가격 간 교차구매 가능
  ※일부상품 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)

  3/29 토, 3/30 일
  1+1
  바디워시(상품별 상이)
  ※일부 품목 제외 ※동일 품목 내 동일 가격 간 교차구매 가능
  ※일부상품 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  요리하다 냉동밥 전품목(상품별 규격 상이) 2,000
  ※1입 상품 한정

  1+1
  냉동 안주류 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  냉동 돈까스 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  하코야 닭꼬치 전품목(상품별 규격 상이)
  ※동일가격 간 교차구매 가능

  1+1
  냉동 피자 전품목(상품별 규격 상이)
  ※PB 상품 제외 ※기간 : 3/27(목)~4/2(수)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  상온 국물요리 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  컵밥/프리미엄밥 전품목(상품별 규격 상이)
  ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  카레/짜장 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  포도씨유/카놀라유/해바라기유 전품목(상품별 규격 상이)
  ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  고추장/된장/쌈장 전품목(상품별 규격 상이)
  ※일부품목 제외
  ※기간 : 3/27(목)~3/30(일) ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  케찹/마요네즈 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  파스타소스 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 3/27(목)~3/30(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  땡 잡았다! 인기 카테고리 전품목 세일! 
  ※기간 : 3/27(목) ~ 4/9(수) ※품목별 기간 상이

  1+1
  스테비아/알룰로스 전품목(상품별 규격 상이) 
  ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  티젠/담터 콤부차 30입/립톤 아이스티 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※담터/티젠 브랜드별 교차구매 가능
  ※립톤 아이스티 각 동일 입수별 교차구매 가능

  1+1
  참치액/코인조미료 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  16입 두유 전품목(각 190ml×16입)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  대용량 과채주스 40종(각 1L~1.89L)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  2+1
  스타벅스/네스카페 돌체구스토 캡슐커피 전품목(상품별 상이)
  ※점포별 입고 상품 상이 ※기간 : 3/27(목)~4/2(수)
  ※동일 호환 기기별 교차구매 가능

  2+1
  카누 캡슐 커피 전품목(상품별 상이)
  ※시즌 캡슐/캡슐기획 제외 ※동일 호환 기기별 교차구매

  2개이상 50%
  아기치즈 전품목(상품별 규격 상이)
  ※동일 브랜드 간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  냉장 밀키트 전품목(상품별 상이)
  ※점별 운영상품 상이 ※기간 : 3/27(목)~3/30(일) ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  냉동 밀키트/국/탕 전품목 최대 165종(상품별 규격 상이)
  ※점별 운영상품 상이 ※기획 상품 제외
  ※기간 : 3/27(목)~4/2(수) ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개이상 50%
  세탁세제 전품목(상품별 상이) ※교차구매 가능

  2개이상 50%
  크리넥스/스카트 물티슈 전품목(상품별 상이)※점별 운영상품 상이
  ※기간 : 3/27(목)~3/30(일) ※교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  땡잡았다 롯데마트 추천! 매일 신선 먹거리!
  ·신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 2(수) ※상품별 기간 상이

  ZERO SUGAR
  제로슈가 육류 2종 L.POINT 최대 50%

  L.POINT 50%
  제로슈가 양념 소불고기(600g/냉장/원산지별도표기) 9,900원

  L.POINT 6,000원
  제로 슈가 훈제오리 슬라이스(400g/냉장/원산지 별도표기) 7,990원

  땡큐 닭볶음탕 연중 최저가 도전!
  ※연중 기간 : 24년 1월 1일 ~ 25년 3월
  ※조기 품절될 수 있습니다
  1+1 땡큐 닭볶음탕용(1kg/냉장/국내산) 8,990원

  청정지역 제주돼지 주말 반값! L.POINT 50%
  제주돼지 삼겹살/목심(각 100g/냉장/국내산)
  ※기간 : 3/27(목) ~ 3/30(일)
  ※조기 품절될 수 있습니다
  ※일부점 프로모션 상이

  해양수산부와 함께하는 수산대전 20%
  ※기간 : 3/27(목) ~ 4/2(수) ※해당상품에 한함 ※산지시황에 따라 조기품절될 수 있습니다
  ※L.POINT 회원 기간 내 주차별 1인 최대 1만원 할인 ※이미지 연출컷입니다
  ※페이지 하단 카드할인 세부내용 참고

  롯데/BC/신한/NH농협/삼성카드 40% -> 수산대전 20%
  반값 광어회(450g내외/냉장/광어 : 국산) 24,000원
  ※조기 품절될 수 있습니다
  ※1인 1팩 한정판매 ※입점점에 한함

  롯데/BC/신한/NH농협/삼성카드 40% -> 수산대전 20%
  내가 만드는 광어회필렛(100g/냉장/광어:국산) 4,560원
  ※1인 1팩 한정판매

  롯데/BC/신한/NH농협/삼성카드 10% -> 수산대전 20%
  국산 손질 꽃게(500g/포장/냉동/국산) 10,728원

  롯데/BC/신한/NH농협/삼성카드 20% -> 수산대전 20%
  참조기(대/마리/해동/국산) 2,432원

  롯데/BC/신한/NH농협/삼성카드 30% -> 수산대전 20%
  고등어자반(특대/1손/냉장/고등어,천일염:국산) 3,915원

  롯데/BC/신한/NH농협/삼성카드 25% -> 수산대전 20%
  완도 활 전복(왕/대)(마리/냉장/국산) 3,540/1,980원

  물가안정 가성비 채소 1,990/3,990원

  대파(700g/봉/국산) 1,990원
  ※점별 취급상품 상이

  한가득 시금치(400g/팩/국산) 1,990원

  상생 다다기오이(5입/봉/국산) 3,990원
  ※점별 취급상품 상이

  뉴질랜드 킹 단호박(통/뉴질랜드산) 3,990원

  2팩 이상 구매시 50%
  하루 한끼 한우 11종(품목별 규격 상이)
  ※냉장/국내산 한우고기/1등급 ※교차구매 가능

  L.POINT 50%
  몸보신용 한우 사골/잡뼈(각 3kg/냉동/국내산 한우고기) 6,990/4,990원

  호주청정우 한끼기획 9종(품목별 규격 상이/냉장/호주산) 2개 이상 구매시 1개당 각8,990원
  ※1개 구매시 각9,990원
  ※교차구매 가능

  L.POINT 최대 30%
  동원이 항공 직수입하여 더욱 신선하고 믿을 수 있는 양고기
  동원청정램 전품목(100g/냉장/호주산 양고기)

  L.POINT 15,000원
  칠레 연어 1위 업체 사전 계약 물량 대방출
  프리미엄 파타고니아 생연어 필렛(500g/냉장/칠레산) 17,900원 ※조기 품절 될 수 있습니다.

  1+1
  1+1 기획팩이 하나 더!
  동원 프리미엄 명란/양념명란 기획(번들팩 1+1)(각 100g×2/냉장/원산지 별도표기) 각16,900원 ※교차구매 가능

  1+1
  한입 꼬마육포/BBQ 통통 오징어(125g/90g) 각9,980원
  ※원산지 별도표기 ※교차구매 가능

  32봉 특별기획
  동원 양반 들기름김 기획팩(4g×16봉×2입/원산지 별도표기) 9,980원

  ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
  ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
  롯데마트 추천 봄맞이 제철과일
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 2,000원
  12Brix 이상 참외만 선별!
  성주 상생참외(3~6입/봉/국산) 7,990원

  12Brix 이상 고당도 오렌지를 한 봉 가득 담아 9,990원
  한 봉 가득 오렌지(봉/미국산)
  ※매장 내 비치된 전용 봉투에 담아 주세요
  ※조기 품절될 수 있습니다

  태국에서 비행기 타고 날아온 망고!
  태국 남독마이 망고(개/태국산) 4개 구매시 9,960원
  ※1개 구매시 3,990원
  ※조기 품절될 수 있습니다

  온가족 나들이 즉석 먹거리 추천

  L.POINT 50%
  반값 알뜰 초밥(20입)(1팩/원산지 별도표기) 9,990원

  L.POINT 25%
  온가족 한통가득 탕수육(1팩/원산지 별도표기) 9,743원

  김밥&유부&샌드 한판샌드(1팩/원산지 별도표기) 11,990원

  L.POINT 30%
  5무 훈제오리(1팩/원산지 별도표기) 11,193원

  국내산 통족발(특대)(1팩/국내산) 19,800원 ※족발매장 입점점 한함

  5,000원 할인
  대용량 가득바삭파이(1팩/원산지 별도표기) 7,900원

  반찬 3+1팩 골라담기(4팩/원산지 별도표기) 9,990원
  ※기간 : 3/27(목) ~ 3/30(일)
  ※비움, 미찬, 도시곳간, 무명찬반,초이스쿡 입점점 한함
  ※정상가 4,000원 이하 상품 한정

  세계맥주 67종(상품별 용량 상이) 5캔 구매시 9,900원
  ※1캔 구매시 각 3,000~3,300원

  이달의 맥주 (500ml) 
  5캔 구매시 10,000원
  ※1캔 구매시 2,500원

  견과류 6종(상품별 용량 상이/원산지 별도표기) 각9,990원

  딸기/토마토/수입포도전품목(상품별 규격 상이/원산지 별도표기) L.POINT 최대 3,000원 할인
  ※딸기, 토마토 전품목 L.POINT 3천원 할인,수입포도 전품목 L.POINT 2천원 할인

  임금님표 이천쌀(8kg/국산)/해보드미(20kg/국산) 29,900/49,900원

  풀무원 특등급 국산 콩나물(200g/국산)/풀무원 1+등급 목초란(대란/15입/국산) 990/5,990원
  ※조기 품절될 수 있습니다

  GAP 팽이버섯(3입/봉/국산)/GAP 향기로운 송이버섯(270g×2봉/국산) 1,490/2,990원

  2송이 이상 각 1,000원 할인
  고산지 바나나(송이/필리핀산) 2송이 이상 구매시 1송이당 각2,990원 ※1송이 구매시 3,990원
  ※점별 운영상품 상이(스위티오/감숙왕/허니글로우)
  ※교차구매 가능 

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
 
  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다 인기 먹거리 최대 1+1 최대 50% !

  1+1 요리하다 포기김치(900g) 12,900원

  1+1 봉지라면 6종(상품별 규격상이) 각3,320~8,800원
  ※팔도&해찬들 차돌된장찌개라면 4개입 外 5종
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1 오뚜기밥 찰현미/오곡/발아흑미(각 210g×3입) 각6,480원 ※교차구매 가능

  1+1 CJ 비비고 열무김치/총각김치(각 900g) 각15,900원 
  ※기간 3/27(목)~4/2(수) ※교차구매 가능

  1+1
  치킨너겟/텐더 13종(상품별 규격 상이) 각5,980~12,980원
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  냉장 꼬치어묵 2종(삼진/고래사) 각9,980원
  ※동일상품에 한함

  1+1
  오뚜기 생쫄면/메밀소바(각 2인분) 각6,980원 ※교차구매 가능

  1+1
  CJ/대림/풀무원 유부초밥 3종(상품별 상이) 각5,480~6,980원
  ※기간 3/27(목)~4/2(수) ※동일상품에 한함

  1+1
  크래프트 슈레드 치즈 4종 각8,990원
  (상품별 상이) ※교차구매 가능

  1+1
  블루샥 라떼, 피넛 라떼(상품별 상이)/이디야 블렌드 아메리카노(1g×100입) 각12,900/25,900원
  ※블루샥 라떼 교차구매 가능 ※이디야 블렌드 아메리카노 동일 품목만 적용 가능

  1+1
  글로벌 수입과자 11종(상품별 상이) 각3,500~15,980원
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  동서 포스트 아몬드 후레이크(620g)/켈로그 첵스초코(570g) 9,780/8,880원 ※동일상품에 한함

  1+1
  상온/냉장커피 음료 63종(상품별 상이) 각1,880~4,390원
  ※동일 브랜드 내 동일 가격간 교차 구매 가능

  1+1
  코카콜라/코카콜라 제로 각3,990원
  (각 1.8L) ※교차구매 가능

  1+1
  롯데마트 단독
  링티 레몬 라이트(1L) 3,980원

  2개 이상 10%
  농심 라면 15종(상품별 규격 상이) 2개 이상 구매시 1개당 각2,952~5,022원
  ※1개 구매시 각 3,280~5,580원
  ※기간 : 3/27(목)~4/4(금), 4/7(월)~4/9(수)
  ※농심 올리브 짜파게티 5개입 外 14종 ※교차구매가능

  농심 라면 전품목 1만원 이상 구매시 라면 전용 계량컵 증정!
  ※전점 15,000개 한정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
  ※구분자 " ㉡ "를 확인하세요 ※영수증 지참 후 '도와드리겠습니다' 센터에서 수령

  5개 이상 각2,980원 할인
  씨제이 햇반 용기 죽 7종(상품별 상이) 5개 이상 구매시 1개당 각2,000원 ※1개 구매시 각 4,980원 ※교차구매 가능

  2개 이상 50%
  진주햄 천하장사 2종(각 448g, 오리지널/치즈) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 각 7,980원
  ※교차구매 가능

  2개 이상 50%
  한성 크런치 롤 유부초밥(240g) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 7,980원

  2개 이상 50%
  제주우유 3종(각 750ml) 2개 이상 구매시 1개당 각2,490원 ※1개 구매시 각 4,980원
  ※A2유기농/무항생제/저지 ※교차구매 가능

  2개 이상 50%
  매일 헬스케어 셀렉스 9종(상품별 상이) 2개 이상 구매시 1개당 각2,990~17,500원
  ※1개 구매시 각5,980~35,000원
  ※교차구매 가능 ※점별 운영상품 상이

  2개 이상 50%
  화제의 레몬/레몬생강즙 6종(상품별 상이) 2개 이상 구매시 1개당 각4,995~7,950원 ※1개 구매시 각9,990~15,900원 ※교차구매 가능

  동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  동원 수산/과일 통조림 8종(상품별 상이) 2,280~12,480원 ※기간 : 3/27(목)~3/30(일)
  ※동일 가격간 교차구매 가능

  1+1
  양반밥 찰진백미밥/현미밥/흑미밥(각 130g×6입) 각10,480원 ※교차구매 가능

  1+1
  동원 양반 야채바삭 김말이(406g) 8,980원

  1+1
  동원 롤 유부초밥2종/리얼크랩스 더킹오리지널(상품별상이) 각6,980원 ※교차구매 가능

  롯데웰푸드 행사상품 3만원 이상 구매시 10,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  롯데 의성마늘 순한 비엔나/프랑크(상품별 상이) 각7,980/8,980원
  ※동일상품에 한함

  롯데 의성마늘 빅그릴 비엔나(300 g×2) 6,980원

  사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령

  사조대림 실속김밥세트(510g) 7,980원

  1+1
  사조 살코기/마일드/고추참치(각 85g×4입) 각8,980원
  ※교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  2개 이상 50%
  동원 딤섬 4종(상품별 규격상이) 2개 이상 구매시 1개당 각5,490~5,990원
  ※새우하가우/부추창펀/샤오롱바오/부채교 ※교차구매 가능

  2개 이상 50%
  고래사 어묵떡볶이(520g) 2개 이상 구매시 1개당 각3,300원 ※1개 구매시 6,600원
  ※기간 : 3/27(목)~4/2(수)

  2개 이상 50%
  나뚜루 파인트 전품목(각 474ml) 2개 이상 구매시 1개당 각7,950원 ※1개 구매시 각15,900원
  ※교차구매 가능

  2개 이상 50%
  논알콜맥주 22종(상품별 상이) 2개 이상 구매시 1개당 각690~1,650원 ※1개 구매시 각1,380~3,300원 ※교차구매 가능

  2개 이상 40%
  종근당건강 락토핏 6종(상품별 상이) 2개 이상 구매시 1개당 각9,540~17,940원 ※1개 구매시 각15,900~29,900원 ※교차구매 가능

  400원 할인 -> 롯데/BC/신한/NH농협/삼성카드 400원 할인
  오늘좋은 1등급 우유(900ml×2입) 2,990원 ※기간 : 3/27(목) ~ 3/30(일)

  롯데마트 단독
  빙그레 바나나맛우유 수세미 굿즈 기획(240ml×6입) 12,980원

  CJ 비비고 육즙가득 고기만두(360g×2입) 10,980원

  동원 통그릴 비엔나(1kg) 10,980원

  롯데마트 단독
  hy 야쿠르트 1971 키링 기획(450ml×4입) 12,800원

  신상품
  종가 오래아삭 포기김치(3.7kg) 35,900원

  서울 흰우유(2.3L) 5,780원
  ※기간 3/27(목)~4/2(수)

  롯데마트 단독
  칠성사이다 유리병 기획(300ml×4입) 3,980원

  신상품
  해태 생생감자칩 랍스터맛(85g)/샌드에이스 그릭요거트(204g)/자유시간 누가마블 딸기(408g) 2,480/3,980/10,980원

  신상품
  오리온 마켓오 브라우니 바이트 제주말차(240g) 5,280원

  신상품
  롯데 명가 찰떡파이 초코바나나(250g) 5,580원

  샘표식품 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " S "를 확인하세요 ※결제시 계산대에서 수령

  1+1
  샘표 국산 100% 조선고추장/토장 (상품별 규격 상이) 29,900/22,900원 ※동일상품에 한함

  동서식품 전품목 5만원 이상 구매시 5,000원 롯데상품권 증정
  ※동서식품 치즈 제외 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " # "를 확인하세요 ※결제시 계산대에서 수령

  2,000원 할인
  동서 맥심 모카골드 마일드/화이트골드 220입+20입 증량업 기획(상품별 상이) 각31,220원

  CJ제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 " C "를 확인하세요 ※결제시 계산대에서 수령

  1+1
  CJ 더건강한 베이컨(90g×2입) 육공육 더얇은 슬라이스 씬(250g) 각9,980원
  ※교차구매 가능

  1+1
  해찬들 원조 태양초 고추장(1.5kg) 18,900원

  2개 이상 각 1,000원 할인
  CJ 고메 소바바 치킨 5종(상품별 상이) 2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원 ※교차구매가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)

  </div>
  `,
  `
  <div>
  땡잡았다! 생활 필수품 최대 1+1 최대 50% !

  1+1 
  케라시스 클린노트 샴푸/트리트먼트(각 800ml) 각12,500원 ※베이비파우더향/화이트코튼향 ※교차구매 가능

  1+1 
  리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 각15,900원
  ※손상모발용/지성두피용 ※교차구매 가능

  1+1 
  아이깨끗해 핸드워시 용기/리필 20종 각5,900~12,980원
  (상품별 상이) ※점별 운영상품 상이
  ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  오랄비 초미세모 잇몸케어 칫솔(3개) 11,900원

  롯데/BC/신한/NH농협/삼성카드 1+1 
  섬유유연제 최대 66종
  ※다우니/피죤/아우라/스너글/버넬 ※점별 운영상품 상이
  ※동일 품목 내 동일 가격간 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참

  1+1 
  섬유 탈취제(용기) 5대 브랜드 각7,500~10,900원
  (상품별 상이) ※일부품목 제외 ※점별 취급품목 상이
  ※동일 브랜드 내 동일가격에 한해 교차구매 가능

  1+1 
  변기 세정제(상품별 상이) 각1,000~8,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  주방세제(상품별 상이) 각1,900~10,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 품목 내 동일 가격간 교차구매 가능

  1+1 
  로션 미용티슈(상품별 상이) 각13,400~16,900원 ※행사상품에 한함
  ※점별 취급품목 상이 ※동일 상품에 한함

  LG생활건강
  행사상품 3만원 이상 구매시
  여행용 세트 증정

  코카콜라
  행사상품 5만원 이상 구매시
  아이스 박스 증정

  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개) 
  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령 
  ※구분자 "@"를 확인하세요 ※사은품 한정수량 ※사은품 소진시 자동 행사 종료

  1+1
  엘라스틴 헤드스파&퍼퓸 샴푸/컨디셔너(각 855ml) 각13,900원
  ※로지드림/디어자스민 ※점별 운영상품 상이 ※교차구매 가능

  1+1
  온더바디 퍼퓸 바디워시(각 900g) 각9,900원
  ※피오니로즈/화이트머스크
  ※점별 운영상품 상이 ※교차구매 가능

  1+1
  페리오 알파치약 기획(185g×4입) 10,900원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할
  ※해당 단일카드로 전액 결제시에 한함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  1+1
  도브 바디케어 전품목(비누/핸드워시/바디워시/바디로션) 각5,900~15,900원
  ※점별 운영상품 상이 ※동일 품목 내 동일 가격간 교차구매 가능

  2개 이상 50%
  도브 베이비 리치모이스처 로션/워시(각 400ml) 2개 이상 구매시 1개당 각7,700~8,000원
  ※1개 구매시 각15,400~16,000원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  2개 이상 50% 
  싸이닉 엔조이 선케어 3종(선크림 200g/선에센스 80ml/선스틱 20g) 2개 이상 구매시 1개당 각6,900~8,900원
  ※1개 구매시 각13,800~17,800원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  2개 이상 50%
  좋은느낌 생리대(상품별 상이)
  ※팬티라이너/탐폰/일부품목 제외 ※교차구매 가능

  2개 이상 50%
  슬릭 면도기/날/쉐이빙폼 등 8종(상품별 상이) 2개 이상 구매시 1개당 각3,100~21,950원
  ※1개 구매시 각6,200~43,900원
  ※점별 입점상품 상이 ※동일 품목간 교차구매 가능

  최대 50%
  스프레이 주거세정제(상품별 상이) 각3,950~7,900원
  ※행사상품에 한함 ※점별 취급품목 상이
  ※동일 브랜드 내 동일가격간 교차구매 가능

  2개 이상 50%
  벚꽃에디션 미용티슈/키친타월/물티슈(상품별 상이) 2개 이상 구매시 1개당 각1,000~3,950원
  ※교차구매 가능 ※1개 구매시 각2,000~7,900원

  롯데/BC/신한/NH농협/삼성카드 2,000원 할인
  벚꽃에디션 무형광 3겹 화장지 9,900원
  (30m×30롤) ※페이지 하단 카드할인 세부내용 참고

  1매당 8.9원 초가성비 물티슈!
  코코토토 데일리 물티슈(100매×10입) 8,900원

  AHC 행사상품 3만원 이상 구매시 
  5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " A "를 확인하세요 ※결제시 계산대에서 수령

  AHC 유스래스팅 아이크림(35ml×2입+7ml×2입)/AHC 바이탈 골든 콜라겐 크림(50g×2입+토너 30ml) 각25,900원

  브랜드 화장품 롯데상품권 증정!
  ※기간 : 3/27(목)~3/30(일) ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※단일 브랜드 결제시에 한함 ※비연속식 증정(1인 1일 1매) ※당일 영수증에 한함
  ※점별 입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  해당카드 5만원 이상 구매시 5,000원 롯데상품권 증정
  해당카드 10만원 이상 구매시 10,000원 롯데상품권 증정

  디펜드 2개 이상 구매시 다용도 가방 증정!
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
  ※구분자 " D "를 확인하세요
  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령

  2+1
  디펜드 37종(상품별 상이) 각5,250~23,900원 ※동일 가격간 교차구매 가능

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다 홈 인테리어ㆍ잡화류 최대 1+1 최대 50%

  브랜드 프라이팬/밀폐용기(상품별 상이) 8,900~83,330원 최대 50%  ※입점점에 한함

  40%
  테팔 스타트이지/에브리데이쿡 11종(상품별 상이) 각18,480~35,880원

  50%
  해피콜 쿡메이트 프라이팬/궁중팬 2종(28cm) 각18,900원

  40,000원 할인
  쿡셀 더블 블랙 프라이팬 2P세트(20cm/28cm) 49,900원

  50%
  락앤락 익스트롱 프라이팬 6종(상품별 상이) 각15,450~18,450원

  글라스락 인기 밀폐 시리즈 L.POINT 50%
  ※스마트/퓨어오븐/모듈러/퓨어핸디/간편캡 등

  스텐락 인기 밀폐 시리즈 L.POINT 50%
  ※퓨어/이지오픈
  
  락앤락 인기 밀폐 시리즈 L.POINT 50%
  ※비스프리모듈러/더클리어/클리어비스프리/프리저핏/스마트킵/클래식 등

  1+1 기획
  글라스락 센스 핑크 에디션 1+1기획(상품별 용량 상이)각5,900~19,900원

  2개 이상 50%
  크린랩 이중지퍼백/뽑아쓰는 크린백(상품별 상이) 2개 이상 구매시 1개당 각3,145~7,695원 
  ※1개 구매시 각6,290~15,390원 ※교차구매 가능

  2개 이상 50%
  오늘좋은 데일리 울트라쿨 냉감패드(SS : 110×200cm/Q : 150×200cm) 2개 이상 구매시 1개당 각14,950/19,950원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  990원

  오늘좋은 데일리 무세제 클리너(3P/11×3×6cm) 990원 정상가 1,500원

  오늘좋은 베이직 바스켓 7종/클래식 바스켓 4종(상품별 상이) 990원 정상가 각1,000원~1,500원

  베이직 남성/여성덧신/양말(각 1매) 990원 정상가 2,000원
  ※입점점에 한함 ※해당상품에 한함
  ※점별 재고 상이

  동원 뉴트리플랜 소프트뮨 강아지 습식간식 3종(각 100g) 990원 정상가 각 2,000원

  4,990원

  룸바이홈 럭셔리 호텔 세면타월(각 40×60cm, 네이비/그레이/화이트) 4,990원 정상가 각 6,990원
  ※ 입점점에 한함 ※ 해당상품에 한함 ※ 점별 재고 상이

  맥스 폭발방지 CRV 부탄가스(4입) 4,990원 정상가 6,790원

  향기가득 프리미엄 워셔액 4종(각 1.8L×2) 4,990원 정상가 각 5,900원

  1+1 기회팩
  유니랩 위생장갑(200매+200매)

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  50%
  풍년 마레러스터 압력솥(8인) 59,000원

  롯데/BC/신한/NH농협/삼성카드 최대 50%
  포트메리온(상품별 용량 상이) 5,340~41,665원
  ※입점점에 한함 ※ 점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 40%
  3M 주방 고무장갑/수세미 전품목(상품별 상이) 2개 이상 구매시 1개당 각1,308~6,594원
  ※1개 구매시 각2,180~10,990원
  ※기간 : 3/27(목)~4/2(수) ※교차구매 가능 ※입점점에 한함 ※점별 재고 상이

  2개 이상 50%
  테이프클리너 전품목(상품별 상이) 2개 이상 구매시 1개당 각1,950~10,390원
  ※1개 구매시 각3,900~20,780원
  ※일부품목 제외
  ※조기 품절될 수 있습니다 ※교차구매 가능 ※기간 : 3/27(목)~4/2(수)

  30%
  크린랩 막대걸레 더블헤드(표준형/대형)/크린랩 물걸레 청소포(각 30매, 표준형/대형) 각5,030~18,540원

  50%
  아이리스 시스템 수납함(중형 2호)/빌드업 바스켓 3종(상품별 상이) 각1,000~9,950원 ※입점점에 한함

  30%
  오늘좋은 세탁이 편한 빨아쓰는 베개(소 : 40×60cm/대 : 50×70cm) 15,330/20,930원
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각6,930~7,630원 
  ※1개 구매시 각9,900~10,900원 ※교차구매 가능 ※입점점에 한함
  ※뉴발란스, 아디다스, 언더아머, 네파 등 ※점별 상품 상이

  2+1
  남성 브랜드 언더웨어(상품별 상이) 6,900원 ※입점점에 한함
  ※브랜드 : 트라이/보디가드/베이직엘르 외 ※교차구매 가능

  10,000원 할인
  트래커 브리튼 여행가방(16/20/24/28인치) 49,000~89,000
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  7,990원

  SOUP/AD HOC 데일리진(스트레이트/테이퍼드) 7,990원 정상가 각 14,800원

  오늘좋은 심리스 런닝/땀패드 런닝 7,990원 정상가 각 8,900~12,900원 ※입점점에 한함 

  코코도르 차량용 미니멀 디퓨저(50mlX2) 7,990원 정상가 각 9,900원

  오늘좋은 패브릭 발매트 11종(메모리폼/워셔블 外) 7,990원 정상가 각 10,900원~12,900원

  9,990원

  블랙 박스 카트(M, 38×29×85.5cm) 9,990원 정상가 15,900원

  뉴발란스/데상트 러닝 양말(각 2매) 9,990원 ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  에너자이저 맥스10+5입(AA/AAA) 9,990원 정상가 각 12,900원

  대림바스 PVC 샤워호스(실버 1.5m/어반 그레이, 화이트 2m) 9,990원 정상가 13,900/14,900원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)

  </div>
  `,
  `
  <div>
  1+1
  유니랩 셰프장갑(소/중/대)/원데이 일회용 수세미(핑크/그린) 각4,990원 ※동일 상품에 한해 교차구매 가능

  1+1 기획팩
  크린랩 크린장갑(200매)/이중지퍼백(20매,소/중/대)/랩(30X50cm) 각3,190~9,090원

  L.POINT 20%
  오늘좋은 아이스박스 3종(미니 5L/17L/38L) 15,920/31,920/71,920원

  2개 이상20%
  오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 3,920원 ※1개 구매시 4,900원

  1+1
  코렐 냉면기 4종(상품별 용량 상이) 각21,900원
  ※입점점에 한함 ※점별 재고 상이 ※교차구매 가능

  40%
  테팔 인텐시움 통 3중 냄비 3종(상품별상이) 26,280~37,680원

  30%
  코렐 목련/더블링그린티/시나모롤 外 5,530~15,330원

  브랜드 와인잔 2P(슈피겔라우/쇼트즈위젤 外) 각9,900원

  30%
  오늘좋은 데일리 피치스킨 이불(S/Q) 16,030/20,930원
  ※S : 150×200cm/Q : 180×220cm

  2+1
  크린랩 물걸레 청소포 30매(표준형/대형, 각 2P) 14,380/16,580원 ※입점점에 한함 ※행사 상품에 한함

  꽃 피는 다육 포트 3종기획 각9,990원

  5,000원 할인
  오늘좋은 점보 리빙박스(100L/75×51×37cm) 19,900원
  ※입점점에 한함

  르젠 써큘레이터형 스탠드 선풍기(45W) 39,900원

  최대 40%
  세차도구 전품목(상품별 규격상이)
  ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

  2+1
  불스원샷 70000(기획상품) 32,000원
  ※입점 점포에 한함 ※행사 상품에 한함

  러닝용품 전품목 8,720~39,920원 ※입점점에 한함
  ※브랜드 : 나이키/아디다스/언더아머 外

  10,000원 할인
  크록스 아동/성인 크록밴드 클로그 각33,900~39,900원
  ※기간 3/27(목)~4/2(수) ※행사상품에 한함 ※입점점에 한함

  브랜드 반팔&반바지 19,900~29,900원
  ※입점점에 한함 ※브랜드 : 휠라/푸마/르까프 外

  여성 브랜드 언더웨어 브라/팬티 4,900/9,900원
  ※입점점에 한함 ※브랜드 : 비비안/비너스/오테나 外

  쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
  ※기간 3/20(목)~4/23(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령

  최대 20%
  쿡셀 프라이팬/냄비 18종(상품별 상이) 31,120~49,900원

  에너자이저 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
  ※기간 3/27(목)~4/9(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉧"를 확인하세요 ※결제시 계산대에서 수령

  10% 에너자이저 맥스플러스 4입(AA/AAA) 8,370원

  레고 마블 인기 완구 30종 최대 L.POINT 30% 할인

  L.POINT 30%
  레고 콜의 타이탄 드래곤 로봇(71821) 104,300원
  (12세 이상/35.6×7.1×37.8cm) ※입점점에 한함

  L.POINT 30%
  마블 14인치 피규어세트(아이언맨/스파이더맨) 55,930원
  (6세 이상/49×11.2×45cm) ※입점점에 한함

  개이득 냥이득!
  ※기간 : 3/27(목)~4/9(수)

  colioli 득템위크
  반려동물식품&용품 500여종 할인!
  반려동물식품/용품 3만원 이상 구매시 5천원 롯데상품권 증정!
  ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※기간 : 3/27(목)~4/9(수)
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매)
  ※페이지 하단 카드할인 세부내용 참고

  1+1 기획
  건강백서 사료 5종(상품별 용량 상이) 23,500~28,500원
  ※푸들/말티즈/피부/관절/캣 ※입점점에 한함 ※점별 수량 상이

  콜리올리 깔끔하게 사용하는 1회용 패드(M 100매/M 200매/L 40매) 각7,140~14,340원 ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  L.POINT 10% -> 롯데/BC/신한/NH농협/삼성카드 10% 추가할인
  이나바 챠오츄르 버라이어티(20P/40P) 각12,069/21,465원
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 30% 
  콜리올리 반려동물 방석 3종(상품별 상이) 각16,030~17,430원
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  탑텐, 탑텐키즈, 탑텐밸런스
  롯데마트 단독 5만원 구매 시 5천원 즉시할인
  ▶기간 : 3/27(목) ~ 4/9(수)
  ※최종 결제 금액에서 5천원 차감(탑텐 영수증 확인 필수)
  ※탑텐, 탑텐키즈, 탑텐밸런스 타 할인 쿠폰 행사와 중복 적용 불가

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202504_1";
const category = [];

const data = {
  L101: {
    title: "스마트전단지-전점101",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L103: {
    title: "스마트전단지-제타플렉스 잠실점103",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L105: {
    title: "스마트전단지-제타플렉스 서울역점105",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
