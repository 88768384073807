/* 209 롯데슈퍼(~4/9) */
const superData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2025. 4. 3(목) ~ 2025. 4. 9(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이
    온국민이 땡잡는 날 땡큐절 3.27~4.9

    롯데 안에서 쓰면 쓸수록 더 커지는 혜택!
    롯데멤버스 카드 출시기념!
    롯데마트 월 최대 20,000원 할인
    롯데슈퍼 월 최대 16,000원 할인
    ※GO APP 쿠폰으로 발급 ※자세한 내용은 롯데마트GO APP 및 롯데카드 홈페이지 참고

    일주일 내내 땡 잡았다!
    초특가 2탄 기 간 : 4/3(목) ~ 4/9(수)

    TV CF 방영상품
    미국산 냉동 꽃갈비 29,900원 50% 육즙 가득
    L.POINT 회원 
    할인 전 가격 59,800원 
    (1kg/냉동/미국산)
    ※조기 품절될 수 있습니다
    ※L.POINT 회원 행사카드 결제시에 한함
    ※페이지 하단 카드할인 세부내용 참고

    TV CF 방영상품
    농가돕기 사과 9,990원 5,000원 할인 2Kg 대용량
    L.POINT 회원 롯데카드/신한카드/삼성카드
    할인 전 가격 14,990원 
    (2kg/봉/국산)
    ※L.POINT 회원 행사카드 결제시에 한함 ※페이지 하단 카드할인 세부내용 참고

    TV CF 방영상품
    4/3 목 4/4 금 4/5 토 4/6 일
    냉장 돼지 삼겹살/목심 100g 990원 50%
    L.POINT 회원 롯데카드/신한카드/삼성카드
    ※조기 품절될 수 있습니다 ※L.POINT 회원 행사카드 결제시에 한함
    ※페이지 하단 카드할인 세부내용 참고

    TV CF 방영상품
    바다를 통째로 한판전복 9,950원 50%
    L.POINT 회원 롯데카드/신한카드/삼성카드
    할인 전 가격 19,900원 (10마리/냉장/국산)
    ※L.POINT 회원 행사카드 결제시에 한함 ※취급 점포에 한함
    ※페이지 하단 카드할인 세부내용 참고 ※이미지 연출 컷입니다

    4/4 금 4/5 토 4/6 일
    삼겹살과 함께먹기 좋아요~
    인기 비빔라면 전품목 50% 롯데카드/신한카드/삼성카드
    L.POINT 회원 
    ※L.POINT 회원 행사카드 결제시에 한함 ※한정물량으로 조기 소진될 수 있습니다
    ※점별 취급 품목 상이 ※페이지 하단 카드할인 세부내용 참고

    스테비아 토마토 전품목 (상품별 용량 상이/국산) 2개 이상 구매시 각4,000원 할인

    풀무원 SOGA 크고 단단한 두부 (900g/원산지 별도표기) ※점별 취급 상이 2,990원

    2송이 이상 각 1,000원 할인
    B750 바나나(송이/필리핀산) 2송이 이상 구매시 1송이당 각1,990원
    ※1송이 구매시 2,990원 ※기간 : 4/3(목)~4/6(일)

    미국산 고당도 오렌지 (8~10입/박스/미국산) 9,990원
    
    농심 인기스낵(255g) ※새우깡, 꿀꽈배기, 바나나킥, 감자깡 3,490원

    L.POINT 50%
    반값 득템 초밥(30입) (팩/원산지 별도표기) ※델리 운영점에 한함 14,990원
    ※ 본 이미지는 연출컷으로 실제와 다를 수 있음

    땡 잡았다! 롯또 혜택! 
    기 간 : 3/27(목)~4/9(수)
    ※ 이벤트 세부사항은 QR코드 스캔 후 이벤트 페이지에서 확인하세요
    EVENT 1 매일 오전 10시~오후 1시 5,000원 롯또쿠폰 증정!
    EVENT 2 3개 채널에서 각 1만원 이상 구매시 최대 10,000점 스노우플랜 추첨 증정! 총 1천명

    ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    오늘만 이 가격! 하루특가
    4/3 목
    L.POINT 50% 수제 모둠 소시지 (500g/냉장/원산지 별도표기) 3,990원
    4/4 금 스위텔토마토(800g/팩/국산)
    ※조기 품절될 수 있습니다 6,990원
    L.POINT 3,000원 
    4/5 토
    2080 어드밴스 블루/그린(각 140g×3입) 각3,560원
    4/6 일
    2개 이상 각 1,000원 할인
    뉴질랜드 단호박 (개/뉴질랜드산) 2개 이상 구매시 1개당 각1,990원
    ※1개 구매시 2,990원
    롯데/신한/삼성카드 290원 할인
    롯데 꼬깔콘 3종/치토스 2종/쌀로별 오리지널(상품별 용량 상이) 각890원
    ※페이지 하단 카드할인 세부내용 참고

    단, 4일간 주말특가 기간 : 4/3(목)~4/6(일)
    2팩 이상 각2,000원 할인
    골라담는 돼지고기 특수부위 2팩 이상 구매시 1팩당 각11,900원 
    돼지고기 귀한부위 항정살/갈매기살/등심덧살
    (각 300g/냉동/국내산 돼지고기) ※조기 품절될 수 있습니다 ※교차구매 가능 ※1팩 구매시 각13,900원
    킹스베리(450g/팩/국산)/세자 딸기(500g/팩/국산) L.POINT 각2,000원 할인
    ※점별 운영상품 상이
    전점 4천팩 한정 서두르세요!
    뒷다리 불고기 두근팩 (1.2kg/냉장/국내산 돼지고기) 11,900원
    ※조기 품절될 수 있습니다
    L.POINT 50%
    1등 급 한우!
    요리하다 1등급 양념 한우불고기 (300g/냉장/원산지 별도표기) 8,490원
    2개 이상 50%
    핫도그 6종(상품별 규격 상이) 2개 이상 구매시 1개당 각4,245~4,995원 
    ※동일브랜드간 교차구매 가능
    ※1개 구매시 각8,490~9,990원 
    ※ CJ 고메크리스피 핫도그치즈/풀무원 탱글뽀득, 왕감자 모짜렐라, 모짜렐라/오뚜기 후랑크(오리지널)/롯데 켄터키
    롯데/신한/삼성카드 2,000원 할인
    인기만두 4종(상품별 상이) 각5,990원
    ※ CJ비비고 물만두, 군만두,생야채물만두(단독기획)/풀무원 생물만두
    ※페이지 하단 카드할인 세부내용 참고
    
    초특가 땡전딜!
    매일 바이오 그릭요거트 (150g) 1,990원
    롯데/신한/삼성카드 500원 할인
    하림 수비드 닭 가슴살 3종(각 100g/원산지 별도표기) 각1,990원
    ※페이지 하단 카드할인 세부내용 참고
    오늘좋은 벚꽃 단백질바 (40g×3입) 2,990원
    오늘좋은 아샷추 복숭아/망고제로(각 1.5L)
    L.POINT 1,500원 광천 달인 김자반 (60g×2/원산지 별도표기) 2,990원
    창립기념 특별기획!
    롯데/신한/삼성카드 2,000원 할인
    벚꽃에디션 무형광 3겹 화장지(30m×30롤) 9,900원
    ※페이지 하단 카드할인 세부내용 참고
    2개 이상 50%
    벚꽃에디션 키친/미용/물티슈(상품별 규격 상이) 2개 이상 구매시 1개당 각1,000~3,950원
    ※1개 구매시 각2,000~7,900원
    2개 이상 50%
    피죤 섬유유연제/스프레이 체리블라썸(벚꽃)(상품별 규격 상이) 2개 이상 구매시 1개당 각3,450~4,450원
    ※1개 구매시 각6,900~8,900원
    홈술족들 모여라! 새롭고 이로운 와인장터 주주총회
    하이볼
    코코하이볼 (캔 355ml, 오렌지/포도) 3캔 구매시 9,990원
    ※1캔 구매시 각3,500원
    사케
    북극곰의 눈물 준마이 봄 기획 (도자기잔 세트)(720ml) 24,900원
    ※점별 준비 상품은 조기 소진될 수 있습니다
    맥주
    이달의 맥주 (캔 500ml) 4캔 구매시 7,800원
    ※1캔 구매시 2,500원

    ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    땡잡았다! 신선 먹거리! 초특가!
    봄 인기과일 다 모여! L.POINT 최대 5,000원 할인
    ※조기 품절될 수 있습니다 ※이미지 연출컷입니다
    L.POINT 5,000원 제주 천혜향 (1.5kg/박스/국산) 12,990원
    L.POINT 2,000원 칠레산 블랙세이블 포도 페루산 오톰크리스피 청포도 (800g, 팩, 칠레산/600g, 팩, 페루산) 각7,990원
    국산 블루베리 전품목(상품별 상이) L.POINT 각 2천원 할인
    태국산 골드 망고(개/태국산) 4개 구매시 9,960원 ※1개 구매시 3,990원
    스마트팜/산설향/한판 딸기 (각 500g/팩/국산) L.POINT 각 2천원 할인
    L.POINT 2,000원 롯데/신한/삼성카드 1,000원 할인
    성주 상생참외(1.2kg/봉/국산) 9,990원
    ※페이지 하단 카드할인 세부내용 참고
    제스프리 루비레드 키위 (8~11입/팩/뉴질랜드산) L.POINT 3,000원 8,990원
    ※조기 품절될 수 있습니다
    반했다! 반값 소! ※조기 품절될 수 있습니다 ※이미지 연출컷입니다
    L.POINT 50% 호주산 찜갈비 (1kg/냉장/호주산 소고기) 27,980원
    L.POINT 60% 체리부로 닭가슴살 모음전 4종 (상품별 상이/냉장/원산지 별도표기) 1,160원
    L.POINT 50% 호주산 와규윗등심/국거리/불고기 (각 100g/냉장/호주산 소고기)
    L.POINT 40% 국내산 돼지고기 앞다리 (100g/냉장/국내산 돼지고기) 1,494원
    ※조기 품절될 수 있습니다
    L.POINT 50% 미국산 냉장 부채살/우삼겹 (300g/400g, 냉장, 미국산 소고기) 8,990/9,990원
    ※산소포장팩 상품만 적용
    롯데/신한/삼성카드 20% 한우 국거리/불고기(1등급) (각 100g/냉장/국내산 한우고기)
    ※페이지 하단 카드할인 세부내용 참고
    L.POINT 50% 
    훈제오리 슬라이스 (400g/냉장/원산지 별도표기) 8,990원
    2팩 이상 구매시 각 3,000원 할인
    최강록의 나야 시리즈 전품목 6종 ※취급점 한정 
    (품목별 상이/냉장/미국산 소고기, 돼지고기) ※교차구매 가능

    물가안정 가성비 채소 ※조기 품절될 수 있습니다 ※이미지 연출컷입니다
    한가득 감자 (2kg/봉/국산) 6,990원 
    한가득 고구마 (2kg/봉/국산) 6,990원
    애호박(개/국산) 1,990원
    한가득 모듬쌈 (300g/팩/국산) 2,990원
    한가득 장아찌용 청양고추(400g/봉/국산) 3,990원
    동원 양반 들기름 기획팩 (4g×16봉×2ea) 9,980원 32봉 특별기획
    L.POINT 4,000원
    칠레 현지 1위 업체 사전 계약물량 대방출 파타고니아 생연어 (250g/냉장/칠레산) 9,900원
    L.POINT 2,500원 동원 데친 문어 (200g/팩/냉장/원산지 별도표기) 8,490원
    1+1 풀무원 노을해심 꼬마김밥키트 (39.7g/원산지 별도표기) 3,480원
    GAP 완전미 고시히카리(10kg/국산) 29,900원
    견과류 6종 (상품별 중량 상이) 각9,990원
    ※원산지 별도표기
    ※점별 취급 상품 상이
    찹쌀/현미(각 2.5kg) 각9,990원
    ※원산지 별도표기
    ※조기 품절될 수 있습니다
    ※기간 : 4/3(목)~4/6(일)
    온가족 즉석 먹거리 추천
    ※델리 운영점에 한함 ※조기 품절될 수 있습니다 ※이미지 연출컷입니다
    L.POINT 50%
    갱엿순살닭강정(대) (팩/원산지 별도표기) 8,995원
    훈제 오리구이 (반마리) (팩/원산지 별도표기) 6,990원
    대용량 미니누네띠네 (팩/원산지 별도표기) 7,900원
    ※취급점에 한함 ※조기 품절될 수 있습니다
    해물 양장피 (팩/원산지 별도표기) 10,900원

    ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)

  </div>
  `,
  `
  <div>
    땡 잡았다! 하나 사면 하나 더 최대 50% ※매장별 취급 상품 상이
    1+1  떠먹는 요구르트 21종
    (상품별 규격 상이) ※매일 3종/빙그레 8종/남양 3종/다논 7종
    ※동일 브랜드 동일가격 간 교차구매 가능
    2개 이상 50%
    치킨너겟 7종(상품별 규격 상이)
    ※일부상품 제외 ※동일 브랜드 동일가격 간 교차구매 가능
    2개 이상 50%
    냉동 피자 22종(상품별 규격 상이)
    ※일부상품 제외 ※동일 브랜드 동일가격 간 교차구매 가능
    2개 이상 50%
    하겐다즈 파인트 전품목 (상품별 규격 상이) ※교차구매 가능
    1+1
    CJ/사조 포도씨유, 카놀라유, 해바라기유 7종(상품별 규격 상이)
    1+1
    코인육수 11종(상품별 규격 상이)
    ※CJ/청정원/동원/샘표/풀무원 ※동일 브랜드 동일가격 간 교차구매 가능
    1+1
    테크 액체세제 12종 (상품별 규격 상이) ※교차구매 가능
    1+1
    핸드워시 7종
    ※아이깨끗해 250ml 3종/리필 600ml,세꼼마 250ml 2종
    ※동일 브랜드 동일가격 간 교차구매 가능
    1+1 
    풀무원 새콤달콤 유부초밥(330g) 5,690원
    1+1 
    풀무원 새콤달콤 쫄면 (2인/460g) 6,990원
    1+1 
    빙수/빙과류 6종 (상품별 규격 상이) 각3,000원
    ※동일 브랜드 동일가격 간 교차구매 가능 ※팥빙수/끌레도르파르페 초코, 체리블라썸/색고드름외 2종
    1+1
    삼진 생생 꼬불어묵꼬치 (416g) 9,990원
    1+1
    CJ 함박스테이크/미트볼 3종 (상품별 규격 상이) 각3,990원
    ※교차구매 가능 ※고메함박/고메치즈함박/고메토마토미트볼
    1+1
    인기라면 4종 (상품별 규격 상이)
    50% CJ 혼합곡밥 작은밥 (각 130g×6) 각6,240원
    1+1 사조참치 3종(각 85g×4) 각8,980원
    ※살코기/고추/마일드 ※교차구매 가능
    1+1 매일 상하 스트링치즈 (18g×10) 12,980원
    1+1 해물모둠 (500g/냉동/원산지 별도표시) 15,900원
    L.POINT 50% ASC 새우살 2종 (각 400g/냉동/원산지 별도표기) 각10,950원
    1+1 꽃샘 밤 티라미수라떼 (18g×20입) 10,980원
    1+1 이디야 블렌드 아메리카노 (1g×100입) 24,900원
    1+1 롯데 의성마늘 비엔나 (160g×2) 7,990원 
    1+1 진주햄 천하장사 오리지날 (448g) 7,690원 
    1+1 요리하다 포기김치 (900g) 12,900원
    1+1 삼립 누네띠네 딸기/딥초코 (각 120g) 각4,980원
    ※교차구매 가능
    1+1 스프라이트/스프라이트 제로 (각 1.5L) 각3,040원
    ※교차구매 가능
    요리하다 숯불데리야끼 파닭꼬치 (480g) L.POINT 1,000원 10,990원
    공구핫딜 한성 몬스터 크랩 (142g×3) 5,980원
    롯데 꼬깔콘 20% 중량UP (각 134g, 고소한맛/군옥수수맛) 각2,890원
    맥심모카믹스/화이트골드믹스 증량업 기획(220입+20입) 4,560원 할인 각31,220원
    ※한정수량으로 조기 품절될 수 있습니다
    1+1 온더바디 퍼퓸 바디워시 (각 900g) 각9,980원
    ※피오니로즈/화이트머스크 ※교차구매 가능
    공구핫딜 미에로화이바 (1.2L×2입) 3,400원
    공구핫딜 아임비타 이뮨플러스 7+3(23.5g×10병) 14,990원
    롯데/신한/삼성카드 4,000원 할인 헤드앤숄더 샴푸/컨디셔너(각 850ml) 각9,900원
    ※페이지 하단 카드할인 세부내용 참고
    2개 이상 50% 
    다우니 3종(각 1L, 블루/퍼플/핑크) 2개 이상 구매시 1개당 각4,100원 
    ※1개 구매시 각8,200원
    ※교차구매 가능
    2개 이상 50% 
    깨끗한나라 순수소프트 3겹 화장지(30m×30롤) 2개 이상 구매시 1개당 각15,250원
    ※1개 구매시 30,500원

    ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div>
  `,
];

/* 109 롯데슈퍼(~4/2) */
const superNewData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
  ·전단적용기간 : 2025. 3. 27(목) ~ 2025. 4. 2(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이

  땡큐절 3.27 ~ 4.9
  온국민이 땡잡는 날

  일주일 내내 땡 잡았다!
  롯데/신한/삼성카드 초튿가!
  기간 : 3/27(목) ~ 4/2(수)
  ※L.POINT 회원 해당카드 최종 결제 금액 기준입니다
  (개/국산) ※페이지 하단 카드할인 세부내용 참고

  TV CF 방영상품
  1등급 한우(냉장) 전품목 50%

  한우 등심(1등급/100g) 6,950원
  할인 전 가격 13,900원(냉장/국내산 한우고기) ※카드할인/다다익선 등 상품별 프로모션 상이

  대추방울토마토 1kg 6,990원 할인 전 가격 9,990원(1kg/박스/국산)

  파프리카 990원
  할인 전 가격 1,190원(개/국산)
  ※점별 취급상품 상이

  무항생제 판계란 4,990원
  기간 외 할인 전 가격 6,490원(30입/대란/국산)
  ※기간 : 3/27(목) ~ 3/30(일)

  L.POINT 5,000원
  대용량
  상생 딸기(1.4kg/박스/국산) 9,990원

  L.POINT 2,000원
  12Brix 이상 선별 완료
  성주 상생참외(1kg/봉/국산) 8,990원
  ※조기 품절될 수 있습니다

  12 BRIX 이상 고당도 오렌지를 한 봉 가득 담아 9,990원!
  한봉가득 오렌지(봉/미국산) 9,990
  ※매장 내 비치된 전용 봉투에 담아주세요
  ※조기 품절될 수 있습니다

  풀무원 특등급 국산 콩나물(200g/국산) 990원
  ※점별 취급 상이 ※조기 품절될 수 있습니다

  서울우유(2.3L) 5,990원

  농심 인기스낵(255g) 3,490원
  ※새우깡, 꿀꽈배기, 바나나킥, 감자깡

  10호 닭 큰치킨(마리/원산지 별도표기) 7,495원
  ※델리 운영점에 한함 ※조기 품절될 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  땡잡았다! 롯또 혜택! 
  ▶ 기 간 : 3/27(목)~4/9(수)
  ※이벤트 세부사항은 QR코드 스캔 후 이벤트 페이지에서 확인하세요

  EVENT 1. 매일 오전 10시~오후 1시 5,000원 롯또쿠폰 증정
  5만원 이상 구매시 5천원 할인

  EVENT 2.
  총 1천명 3개 채널에서 각 1만원 이상 구매시 
  최대 10,000점 스노우플랜 추첨 증정!

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
    `,
  `
  <div>
  오늘만 이 가격! 하루 특가

  3/27 목
  L.POINT 55% -> 롯데/신한/삼성카드 12% 초특가
  1등급 한우 국거리/불고기(각 100g/냉장/국내산 한우고기)
  ※조기 품절될 수 있습니다 ※양지 국거리용 제외
  ※페이지 하단 카드할인 세부내용 참고

  3/28 금
  L.POINT 3,000원 
  밀양 얼음골 상생사과(1.8kg/봉/국산) 14,990원
  ※조기 품절될 수 있습니다 ※전점 1만봉 한정

  3/29 토
  롯데/신한/삼성카드 1,480원 할인
  해태 홈런볼 초코 4번들 묶음(41g×4) 3,300원

  3/30 일
  1+1
  코카콜라(2L) 4,680원

  단,4일간 주말특가
  기간 : 3/27(목)~3/30(일)

  L.POINT 50%
  청정지역 제주돼지 주말 반값!
  제주돼지 삼겹살/목심(각 100g/냉장/국내산) 각2,590원 ※조기 품절될 수 있습니다 ※일부점 프로모션 상이

  L.POINT 6,500원
  CJ 스팸 클래식(120g×5) 9,980원

  1+1
  롯데 알뜰소세지(500g) 3,790원

  L.POINT  2,000원
  스마트팜/산설향 딸기(각 500g/팩/국산) 5,990원 ※점별 운영상품 상이

  1+1
  CJ 비비고 갈비왕교자/하림 더미식 육즙 고기교자,김치교자(420g×2/각 350g×2) 각10,990원 ※동일브랜드에 한함

  브랜드 냉동 돈까스(상품별 규격 상이) 2개 이상 구매시 1개당 각50% 할인
  ※CJ 고메통등심돈카츠/롯데푸드 쉐푸드 미니돈까스 外

  냉동 안주류(상품별 규격 상이) 2개 이상 구매시 1개당 각50% 할인
  ※청정원 안주야 소곱창볶음한판/동원 심야식당 매콤오돌뼈 外

  초특가 땡전딜!

  매일 바이오 그릭요거트(150g) 1,990원

  롯데/신한/삼성카드 500원 할인
  하림 수비드 닭 가슴살 3종(각 100g, 원산지 별도표기) 각1,990원
  ※페이지 하단 카드할인 세부내용 참고

  신상품오늘좋은 벚꽃 단백질바(40g×3입) 2,990원

  신상품 오늘좋은 아샷추 복숭아/망고제로(각 1.5L) 2,990원

  L.POINT 1,500원 광천 달인 김자반(60g×2/원산지 별도표기) 2,990원

  창립기념 특별기획!

  롯데/신한/삼성카드 2,000원 할인
  벚꽃에디션 무형광 3겹 화장지(30m×30롤) 9,900원

  2개 이상 50%
  벚꽃에디션 키친/미용/물티슈(상품별 규격 상이) 2개 이상 구매시 1개당 각1,000~3,950원
  ※1개 구매시 각2,000~7,900원

  2개 이상 50%
  피죤 섬유유연제/스프레이 체리블라썸(벚꽃)(상품별 규격 상이) 2개 이상 구매시 1개당 각3,450~4,450원
  ※1개 구매시 각6,900~8,900원

  홈술족들 모여라!
  새롭고 이로운 와인장터
  주주총회
  세계맥주 39종(상품별 규격 상이) 4캔 구매시 7,800원 ※1캔 구매시 각2,500~3,800원

  롯데/신한/삼성카드 5,000원
  1865 헤리티지 블렌드(750ml) 29,900원

  2개 이상 50%
  논알콜릭 맥주 20종(상품별 규격 상이) 각1,380~3,800원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  봄 제철 과일 여기 여기 모여라
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  수입포도 전품목(상품별 규격상이/팩) L.POINT 2,000원 할인
  ※원산지 별도표기 ※조기 품절될 수 있습니다 ※점별 운영상품 상이

  AI선별 제주 한라봉(1.5kg/박스/국산) 9,990원

  L.POINT 1,000원
  고산지 바나나(송이/필리핀산) 2,990원
  ※조기 품절될 수 있습니다

  태국에서 비행기 타고 날아온 망고!
  태국 골드 망고(개/태국산) 4개 구매시 9,960원
  ※1개 구매시 3,990원
  ※조기 품절될 수 있습니다

  L.POINT 2,000원
  이탈리아 그린키위(7~10입/팩/이탈리아산) 5,990원
  ※조기 품절될 수 있습니다

  ZERO SUGAR

  L.POINT 50% 제로슈가 양념 소불고기(600g/냉장/원산지 별도표기) 10,900원

  2개 이상 구매시 50%
  하루 한끼 한우 11종(품목별 규격 상이)
  ※1등급/냉장/국내산 한우고기
  ※취급점 한정 ※교차구매 가능

  L.POINT 50% 
  땡큐 닭볶음탕용(600g/냉장/국내산) 3,490원

  호주산 한끼기획팩 전품목(품목별 상이/냉장/호주산 소고기) 2팩 이상 구매시 1팩당 각4,000원 할인

  1+1
  노랑오리 마늘치킨 훈제슬라이스(400g/냉장) 9,900원 ※원산지 별도표기

  롯데/신한/삼성카드 50%
  몸보신용 한우 냉동 뼈류 3종(한우꼬리반골 6kg/한우 잡뼈 3kg/한우 사골 3kg)
  ※취급점 한함 ※페이지 하단 카드할인 세부내용 참고

  최강록의 나야 시리즈 6종 ※취급점 한정(품목별 상이/냉장/미국산 소고기, 돼지고기) L.POINT 각2,000원 할인 
  ※L.POINT 회원에 한함

  땡잡았다 신선 먹거리! 초특가!

  물가안정 가성비 채소
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  대파(700g/봉/국산) 1,990원
  ※점별 취급상품 상이

  상생 다다기 오이 4,990원
  (5입/봉/국산) ※점별 취급상품 상이

  한가득 시금치(400g/봉/국산) 1,990원

  킹 단호박(개/뉴질랜드산) 3,990원

  32봉 특별기획
  동원 양반 들기름 기획팩(4g×16봉×2ea) 9,980원

  L.POINT 4,000원 
  노르웨이 생연어(200g/팩/냉장/노르웨이산) 8,900원
  ※조기 품절될 수 있습니다

  롯데/신한/삼성카드 3,000원 할인
  손질오징어(3마리/해동/원양산) 8,990원

  1+1
  한입 꼬마육포/BBQ 통통 오징어(125g/90g) 각9,980원 ※원산지 별도표기
  ※점포별 운영상품 상이 ※교차구매 가능

  임금님표 이천쌀(8kg/국산) 29,900원

  농부의미소(20kg/국산) 49,900원
  ※조기 품절될 수 있습니다
  ※기간 : 3/27(목)~3/30(일)

  맑은물에 실속두부(520g/원산지 별도표기) 990원
  ※점별 취급 상이
  ※조기 품절될 수 있습니다
  ※기간 : 3/27(목)~3/29(토)

  견과류 6종(상품별 중량 상이) 9,990원
  ※원산지 별도표기 ※점별 취급 상품 상이

  온가족 즉석 먹거리 추천
  ※델리 운영점에 한함 ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  반값 알뜰초밥(팩/원산지 별도표기) 9,990원

  2개 이상 50%
  수산명가 명란(100g/냉장/원산지 별도표기) 2개 이상 구매시 1개당 각8,490원
  ※프리미엄 명란/양념명란 교차구매 가능

  대용량 미니 누네띠네(팩/원산지 별도표기) 7,900원
  ※운영점에 한함

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)

  </div>
  `,
  `
  <div>
  땡잡았다! 하나 사면 하나 더 최대 50% ※매장별 취급 상품 상이

  1+1
  떠먹는 요구르트 21종(상품별 규격 상이) 
  ※매일 3종/빙그레 8종/남양 3종/다논 7종
  ※동일 브랜드 동일가격 상품 교차구매 가능

  2개 이상 50%
  치킨너겟(상품별 규격 상이) ※일부상품 제외

  2개 이상 50%
  냉동 피자(상품별 규격 상이) ※일부상품 제외

  1+1
  상온국탕찌개류(상품별 규격 상이) 
  ※기간 : 3/27(목)~3/30(일)
  ※일부상품 제외

  1+1
  즉석&분말카레/짜장(상품별 규격 상이) 
  ※기간 : 3/27(목)~3/30(일)
  ※수입 상품 제외

  1+1
  참/들기름(상품별 규격 상이)
  ※기간 : 3/27(목)~3/30(일)
  ※롯데마트 단독 브랜드 제외

  1+1
  아이스티/콤부차(상품별 규격 상이)
  ※립톤/담터/티젠/이디야 브랜드별/동일 입수별 교차구매 가능
  ※롯데마트 단독 브랜드 제외

  1+1
  파스타소스(상품별 규격 상이)
  ※기간 : 3/27(목)~3/30(일)
  ※롯데마트 단독 브랜드 제외

  1+1
  인기라면 4종(상품별 규격 상이)

  50%
  CJ 혼합곡밥 작은밥(각 130g×6)

  1+1
  컵밥/프리미엄밥(상품별 규격 상이) 
  ※기간 : 3/27(목)~3/30(일)
  ※동일가격대, 동일브랜드 교차구매 가능

  1+1
  사조참치 3종(각 85g×4) ※살코기/고추/마일드

  1+1
  고추장/된장/쌈장/간장 59종(상품별 규격 상이)
  ※기간 : 3/27(목)~3/30(일)

  1+1
  매일 상하 스트링치즈(18g×10) 12,980원

  1+1
  동원 롤유부초밥 2종(각 254g) 각6,490원
  ※새콤한맛/고소한맛 ※교차구매 가능

  1+1
  고래사 국탕용 모둠어묵(415g) 7,990원

  1+1
  풀무원 메밀막국수 2종(각 2인, 513g/391g) 각6,990원 
  ※교차구매 가능
  ※춘천식메밀/들기름메밀 

  L.POINT 50%
  간편 새우살 묶음 3종(각250g×2입/냉동/원산지 별도표기) 각11,950원

  2개 이상 50%
  테크 액체세제(상품별 규격 상이) 2개 이상 구매시 1개당 각5,950~9,900원 
  ※1개 구매시 각11,900~19,800원 ※교차구매 가능

  1+1
  CJ 함박스테이크/미트볼 3종(상품별 규격 상이) 각3,990원 
  ※교차구매 가능
  ※고메함박/고메치즈함박/고메토마토미트볼

  2개 이상 50%
  샴푸/바디워시 ※기간 : 3/29(토)~3/30(일)

  LG생활건강 x 코카콜라 
  전품목 1만2천원 이상 구매시 경품 응모권 증정
  1등 스탠바이비(1명)
  2등 코카콜라 아이스박스(30명)
  3등 베스킨라빈스 싱글레귤러 교환권(300명)
  ※자세한 내용은 매장내 홍보물을 확인하세요

  1+1
  엘라스틴 헤드 스파퍼퓸샴푸(각 855ml) 각13,900원 ※로지드림/디어자스민 ※교차구매 가능

  1+1
  스프라이트/스프라이트 제로(각 1.5L) 각3,040원 ※교차구매 가능

  1+1
  롯데 의성마늘 비엔나(160g×2) 7,990원

  1+1
  진주햄 천하장사 오리지날(448g) 7,690원

  1+1
  요리하다 포기김치(900g) 12,900원

  1+1
  삼립 누네띠네 딸기/딥초코(각 120g) 각4,980원 ※교차구매 가능

  롯데/신한/삼성카드 4,000원 할인
  인기 전골류 밀키트(상품별 상이) 각6,990원
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 1,000원
  요리하다 숯불데리야끼 파닭꼬치(480g) 10,990원

  공구핫딜 한성 몬스터 크랩(142g×3) 5,980원

  롯데 꼬깔콘 20% 중량UP(각 134g, 고소한맛/군옥수수맛) 각2,890원

  4,560원 할인
  맥심모카믹스/화이트골드믹스 증량업 기획(220입+20입) 각31,220원
  ※한정수량으로 조기 품절될 수 있습니다

  롯데/신한/삼성카드 400원 할인
  평생 막걸리(PET)(750ml) 990원
  ※기간 : 3/27(목)~3/30(일)
  ※기간 외 1,390원 ※한정수량 5,980원 ※페이지 하단 카드할인 세부내용 참고

  공구핫딜 미에로화이바 (1.2L×2입) 3,400원

  공구핫딜 아임비타 이뮨플러스 7+3(23.5g×10병) 14,990원

  롯데/신한/삼성카드 4,000원 할인
  헤드앤숄더 샴푸/컨디셔너(각 850ml) 각9,900원
  ※페이지 하단 카드할인 세부내용 참고

  퍼실 그린 기획팩(4.65L+900ml) 각23,800원

  1매당 8.9원 초가성비 물티슈!
  코코토토 데일리 물티슈(100매×10입) 8,900원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에
  
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getSuperData = () => {
  const dataArray = [];
  superData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getSuperNewData = () => {
  const dataArray = [];
  superNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L209: {
    title: "스마트전단지-슈퍼209",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202504_2/02",
    contents: getSuperData(),
  },
  L109: {
    title: "스마트전단지-슈퍼109",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202504_1/02",
    contents: getSuperNewData(),
  },
};

export default data;
