/* 701, 751 빅마켓(~6/12) */
const vicData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX

    수박/탄산음료/폴로,챔피언 패션잡화브랜드 20%
    추 가 할 인
    
    전용카드
    회원만!
    ▶ 기간 : 5/30(수) ~ 6/12(수)
    ▶ 해당카드 :
    롯데마트&MAXX카드,
    LOCA for 롯데마트카드/롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가

    수박 전품목 20% 추가 할인 12,784~16,784원
    당도선별 수박(6~10kg 미만/통/국산)
    ※점별 운영 중량 상이
    ※ 해당카드 미적용시
    17,980~22,980원
    ※ 롯데/신한카드 결제시
    15,980~20,980원

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    탄산음료 전품목
    (콜라/사이다/혼합탄산음료)
    ※트레비 토닉워터 얼그레이 제외

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    폴로/챔피언 브랜드 의류(성인/아동)
    ※해당카드 미적용시 9,980~79,800원

    61월 한달 내내 이 가격 ※기간 : 6/1(토) ~ 6/30(일)

    3,500원 할인 캐나다산 카무트(2kg/캐나다산) 18,980원
    2,000원 할인  롤링롤링 오징어(150g/원산지 별도표기) 7,980원
  </div>
  `,
  `
  <div>
    3,000원 할인 어포튀각 9,980원
    (400g/원산지 별도표기)

    하림 더 건강한 두마리 치킨 (팩 : 두마리/닭고기 : 국내산) 15,980원

    2,000원 할인 하림 용가리 치킨 9,980원
    (1.2kg)
    
    1,000원 할인 큐원 올리고당 7,380원
    (1.2kg×2입)
    
    1+1 자임 곤약젤리 사과/
    패션후르츠(150g×10입) 각16,980원
    
    1,500원 할인 삼립 뉴 젤리뽀 7,480원
    (2.4kg)
    
    2,500원 할인 유키앤러브 파인애플
    케이크(25g×20입) 13,480원

    롯데카드/신한카드 1,000원 할인 상쾌환(3g×12입) 14,980원
    
    10,000원 할인 블루밍가든 누들볼 29,800원
    (4P)
    
    롯데카드/신한카드 3,000원 할인 싸이닉 엔조이 선에센스 9,980원
    (50ml×2)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    시원한 할인가로
    슬기로운 여름 준비!

    롯데카드/신한카드 2,000원 할인 미국산 체리 12,980원
    (900g/팩)
    
    제스프리 한판 골드키위 14,980원
    (12입/박스/뉴질랜드산)

    단 8일간, 핫프라이스!
    롯데카드/신한카드 50% 할인  한우 등심(100g/냉장/국내산) 3,990원
    ※기간 : 5/30(목)~6/6(목) ※2등급

    L.POINT 25& 할인 1등급 한우 국거리/불고기 2,835원
    (각 100g/냉장/국내산)

    L.POINT 25& 할인 호주산 곡물비육 부채살 1,935원
    (100g/냉장/호주산)
    
    L.POINT 25& 할인 캐나다산 돼지 삼겹살/목심 각1,260원
    (각 100g/냉장/캐나다산)
  </div>
  `,
  `
  <div>
    1,000원 할인 청수 물냉면 5,280원
    (720g)
    
    3,000원 할인 풀무원 함흥비빔냉면 3,980원
    2인(460g)

    롯데카드/신한카드 2,000원 할인 티오 아이스티 복숭아맛 17,980원
    (13g×150입)
    
    스페쇼우 젤리스트로우
    플러스(1,254g) 10,980원

    4,020원 할인 트레비 토닉워터
    얼그레이 3,960원

    2,000원 할인 티젠 콤부차 매실 10,980원
    (5g×30입)

    롯데카드/신한카드 35% 할인 캐나다 활 랍스터 12,935원
    (마리/냉장)

    L.POINT 5,000원 할인 미국산 냉동 차돌박이 39,800원
    (1.2kg/냉동/미국산)

    10,000원 할인 젠텍 14인치 터보펜 에어 서큘레이터/
    젠텍 20인치 공업용 선풍기 각79,800원

    순면 누빔 편백메밀베개
    (블루/차콜) 각9,980원
     
    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    해양수산부와 함께하는 수산대전20%

    수산대전20% 프리미엄 광어회 29,984원
    (500g 내외/팩/광어 : 국산)

    수산대전20% ASC인증 완도 활전복 19,840원
    (대/14미/10마리)

    수산대전20% 원양산 오징어 2,384원
    (마리/해동)
 
    수산대전20% 영광 법성포 굴비 27,984원
    (1.4kg/팩/냉동/국산)

    수산대전20% 국산 고등어필렛 8,784원
    (1kg/팩/냉동)

    수산대전20% 황태채(400g/팩/러시아산)/
    볶음용, 조림용멸치 각9,984원
    (각 500g/팩/멸치:국산)

    완숙 토마토 10,980원
    (2.4kg/팩/국산)

    1,000원 할인 옛날방앗간 미숫가루 8,980원
    (1.2kg/원산지 별도표기) 

    1,500원 할인 냉장 다진마늘 9,480원
    (800g/국산)

    GAP 파프리카 980원
    (1개/국산) 

    다다기오이 4,480원
    (10입/봉/국산)

    
    </div>
  `,
  `
  <div>
    롯데카드/신한카드 1,800원 할인 신선한 계란
    (특/30입/1.8kg/국산) 5,480원

    3,500원 할인 귀한 쌀 26,480원
    (10kg/국산)

    각 2,000원 할인 구운아몬드/구운 점보캐슈넛 11,980/6,980원
    (1kg/400g, 원산지 별도표기)

    물가안정 갈치/참조기 각3,600/1,400원
    (각 32미/180미/마리, 해동, 국산)
 
    L.POINT 5,000원 할인 생새우살 31-40 16,980원
    (908g/팩/냉동/베트남산)

    L.POINT 20% 할인 호주산 곡물비육 살치살 3,664원
    (100g/냉장/호주산)
    
    L.POINT 25% 할인 호주산 찜갈비 1,860원
    (100g/냉장/호주산)

    L.POINT 3,000원 할인 정다운 허브 훈제오리
    (600g/냉장/원산지 별도표기) 11,980원
    
    L.POINT 1,500원 할인 하림 동물복지 닭볶음탕 8,480원
    (1.3kg/냉장/국내산)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    L.POINT 4,000원 할인 양념 목심 칼집구이 23,980원
    (1.7kg/원산지 별도표기) 

    L.POINT 5,000원 할인 수원식 양념돼지 왕갈비 구이 24,980원
    (1.7kg/원산지 별도표기)

    L.POINT 50% 할인 풀무원 국산콩 와사비 나또 5,700원
    (44.5g×8입/원산지 별도표기)

    패밀리팩 초밥 24,980원
    (40P/팩/원산지 별도표기)

    씨푸드 쉐이크 우동 3,980원
    (팩/원산지 별도표기)

    핫 스파이시 윙봉 13,980원
    (팩/원산지 별도표기)

    날치알 새꼬막 비빔밥 8,980원
    (팩/원산지 별도표기)

    2,000원 할인 피크닉 마들렌 10,900원
    (20입/1팩/원산지 별도표기)

    각 1,000원 할인 RTC 피자 2종/파스타 1종 11,900~4,900원
    (듬뿍불고기/핫 페퍼로니)
    ※원산지 별도표기 ※빅마켓 영등포점에 한함
  </div>
  `,
  `
  <div>
    3,000/1,000원 할인 하림 닭가슴살 블랙페퍼(510g)/
    사조대림 로얄크랩(100g×2입) 9,480/4,480원

    1,000원 할인 백종원의 홍콩반점 해물육교자 6,980원
    (350g×2입)

    6,000원 할인 하림 치킨쌀너겟 9,980원
    (1.2 kg)

    각 2,000원 할인 쉐푸드 등심통돈까스(600g)/
    롯데 라퀴진 모짜렐라 치즈스틱(800g) 12,980/11,980원

    2,000원 할인 남가네 설악 추어탕 14,980원
    (450g×3입)

    각 2,000원 할인 문옥례 명인 전통 고추장/된장 15,580/11,980원
    (각 900g)

    각 2,000/1,000원 할인 움트리 생와사비(120g×2입)/
    육류n생와사비랑(120g×2입)/
    양념 초고추장(1kg×2입) 7,480/8,380/5,980원

    1,000원 할인 고기엔 참소스
    (800g×2입) 8,480원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  `,
  `
 <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    테이스티 말보로
    소비뇽 블랑(750ml) 19,800원

    커티삭 오리지널/프로히비션(각 700ml) 24,800/42,800원

    1,000원 할인 풀무원 다논 그릭 플레인(90g×10입) 4,680원

    롯데카드/신한카드 1,500원 할인 대파&베이컨 크림치즈(50g×4입) 5,480원

    롯데카드/신한카드 5,000원 할인 덴마크 산양 프로틴 고단백 14,480원
    슬라이스 치즈(220g×4입) 

    3,500원 할인 밀카 초코무 비스킷(40g×24입) 11,980원

    1,000원 할인 해태 홈런볼 초코(46g×9입) 7,980원
 
    2,500원 할인 액트투 전자렌지용 팝콘 버터맛 14,480원
    (1,404g)
  </div> 
  `,
  `
  <div>
    2,000원 할인 켈로그 고소한 현미 그래놀라 11,680원
    (500g×2입)

    롯데카드/신한카드 5,000원 할인 베지밀 17곡 한끼두유 11,980원
    (190ml×24입)

    롯데카드/신한카드 1,500원 할인 MAXX아메리카노 리얼블랙 1,980원

    2,000/1,000원 할인 사과당 애플밀크티/애플크러쉬 4,980/3,980원
    (각 18g×20입)

    종근당건강 전품목 3만원 이상
    5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령

    L.POINT 5,000원 할인 종근당건강 생유산균 락토핏 코어
    더블세트(60포×2입) 27,800원

    롯데카드/신한카드 1,000원 할인 독일 비타하임 발포 멀티비타민
    (4,500mg×80정) 8,980원

    4,000원 할인 뉴트리원 장용성 초임계 알티지
    오메가3 트리플(1,200mg×60캡슐) 21,980원

    5,000원 할인 내추럴박스 산양유프로틴
    (15g×42포) 29,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    
    롯데카드/신한카드 2,000원 할인 MAXX 프리미엄 화장지
    (40m×30롤) 17,980원

    L.POINT 2.500원 할인 아우라 피오니 블러썸 16,480원
    (5.5L)

    L.POINT 4,000원 할인 테크 베이킹+구연산 액체세제 11,980원
    (4.8L)

    2,000원 할인 히말리야 핑크솔트 아이스 15,980원
    (160g×5)

    2,000원 할인 미쟝센 퍼펙트
    오리지널 세럼 8,980원
    샴푸(900ml)

    2,000원 할인 밀크바오밥
    세라 바디워시
    베이비 파우더 
    (1,200ml) 11,980원
    
    L.POINT 최대 4,000원 할인 INTEX 플라밍고 라이드온/
    미니프레임풀(블루) 17,980/32,800원

    2,000원 할인 애견 쿨아이스 방석(스카이블루) 10,980원
  </div>
  `,
  `
  <div>
    락앤락 저장용품
    ※세부 품목은 매장에서 확인하세요
    전품목 할인!
    7,000원 할인 락앤락 클래식 밀폐용기 12P 14,980원
    
    3,000원 할인 니트릴 장갑 100매 7,980원
    (소/중/대)
    2,000원 할인 냉감 일체형 베게 10,980원
    1,000원 할인 마작 대방석(내추럴) 7,980원
    9,820원 할인 블랙야크 워크웨어
    반소매 카라티셔츠 29,980원
    5,000원 할인 SOUP 슬리브리스 포인트 원피스 34,800원
    5,000원 할인 비비안 숨쉬는 브라
    (2매입, M/L/XL) 29,800원
    5,000원 할인 나이키 코르테즈 파우치(블랙) 39,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

/* 718 maxx(~4/9) */
const maxxData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
  ·전단적용기간 : 2025. 3. 27(목) ~ 2025. 4. 9(수)
  ·롯데마트맥스 : 금천, 목포, 상무, 송천, 영등포, 창원중앙점

  온국민이 땡잡는 날, 땡큐절
  3.27 ~ 4.9

  땡큐절 기념 고객참여 이벤트!
  매일 오전 10시~오후 1시 10만원 이상 구매고객 최대 15,000원 롯또쿠폰 증정!
  ※기간 : 3/27(목)~4/9(수)
  ※이벤트 세부내용은 롯데마트GO앱에서 확인하실 수 있습니다

  10만원 이상 구매시 최대 1만 5천원 할인 10% 할인

  롯데/신한카드 5,000원 할인
  성주 참외(1.5kg/봉/국산) 9,980원
  ※페이지 하단 카드할인 세부내용 참고

  롯데/신한카드 3,000원 할인
  썬키스트 고당도 킹 오렌지(8입/봉/미국산) 9,980원
  ※페이지 하단 카드할인 세부내용 참고

  롯데/신한카드 3,000원 할인
  대추방울토마토(1.5kg/팩/국산)/GAP파프리카(700g/봉/국산) 8,980/2,980원
  ※기간 : 3/27(목)~4/2(수) ※페이지 하단 카드할인 세부내용 참고

  롯데/신한카드 30% 할인
  미국산 초이스 부채살(100g/냉장/미국산) 1,946원
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 25% 할인
  미국산 프라임 척아이롤(100g/냉장/미국산) 2,235원

  L.POINT 25% 할인
  항공직송 노르웨이 생연어(100g/냉장/노르웨이산) 2,985원

  롯데/신한카드 50% 할인 
  러시아 활대게(100g/냉장/러시아산) 3,495원
  ※페이지 하단 카드할인 세부내용 참고

  5,000원 할인
  서울우유 체다슬라이스치즈(1.8kg) 16,480원

  2개 이상 50%
  마니커 리얼치킨 통살치킨너겟(1kg) 2개 이상 구매시 1개당 각6,490원 ※1개 구매시 12,980원

  롯데/신한카드 2개 이상 50%
  크라운 산도 딸기크림치즈/죠리퐁 마시멜로(161g×6입/39g×15입) 2개 이상 구매시 1개당 각4,490/4,990원 
  ※1개 구매시 8,980/9,980원 ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 9,000원 할인
  비비랩 저분자콜라겐 세라마이드(2g×60포) 19,980원

  5,000원 할인
  탑스텝 3in1 캡슐 세탁세제(100개입) 14,980원

  10,000원 할인
  고탄력 스판스트레이트 남성데님 19,980원
  
  땡 잡았다! 4일간 초특가! 1탄 
  기간 : 3/27(목) ~ 3/30(일)

  롯데/신한카드 40% 할인
  캐나다산 돼지 삼겹살/목심(각 100g/냉장/캐나다산) 각888원
  ※조기 품절될 수 있습니다
  ※페이지 하단 카드할인 세부내용 참고

  롯데/신한카드 4,600원 할인
  신선한 계란(대/60입/국산) 8,980원
  ※페이지 하단 카드할인 세부내용 참고
  ※조기 품절될 수 있습니다

  서울 멸균 흰우유(200ml×24입) 12,980원

  2,000원 할인
  링티제로 레몬라임맛(1L×6입) 8,980원

  롯데/신한카드 50% 할인 
  종근당건강 락토핏 골드(2g×90포) 10,490원
  ※페이지 하단 카드할인 세부내용 참고

  2,000원 할인
  2080 미백/시그니처 치약(100g×4입/160g×8입) 8,480/11,480원

  땡 잡았다! 4일간 초특가! 2탄
  기간 : 4/3(목) ~ 4/6(일)

  롯데/신한카드 50% 할인 
  오뚜기 진라면 매운맛/순한맛 소컵(각 15개입) 5,240원
  ※1인 1일 1회 총 10개 구매한정
  ※페이지 하단 카드할인 세부내용 참고

  새우살 4종(각 908g/팩/냉동/베트남산) L.POINT 각 4,000원 할인
  ※한정물량으로 조기 품절될 수 있습니다

  애호박(개/국산) 1,480원
  ※기간 : 4/3(목)~4/9(수)

  1,000원 할인
  클룹 애사비소다 오리지널(500ml×6입) 6,480원

  L.POINT 9,000원 할인
  리얼닥터 프리미엄 비타민C1000(2g×200포) 15,980원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외

  </div>
  `,
  `
  <div>
  땡 잡았다! 초특가! 땡전딜 9,980원! 

  양반 들기름 김(4g×32봉/원산지 별도표기) 9,980원

  태국 망고(4입/팩/태국산)/어텀 크리스피 청포도(900g/페루산) 각9,980원

  L.POINT 5,000원 할인
  정다운 마늘담은 훈제오리(200g×3입/냉장/원산지 별도표기) 9,980원

  L.POINT 3,000원 할인
  아구포채(250g/원산지 별도표기) 9,980원

  7,000원 할인
  이태리안 피자치즈(420g×2입) 9,980원

  2,000원 할인
  롯데 남도 별미 떡갈비(1kg) 9,980원

  4,000원 할인
  사조해표 식용유(1.7L×2입) 9,980원

  롯데 단독 신상품
  해태 자유시간 누가마블 딸기(408g) 9,980원

  3,000원 할인
  해피바스 프레시팝 주스스무디 옐로우 바디워시(1,100ml) 9,980원

  각2,000원 할인
  리엔 보윤 샴푸/컨디셔너(손상)(각 1,000ml) 9,980원

  3,000원 할인
  밀크바오밥 세라 트리트먼트 화이트 머스크(1,200ml) 9,980원

  신상품
  시티라인 티셔츠 9,980원

  땡잡았다! maxx 추천상품 1+1 50%

  1+1
  동원 떡볶이의 신 로제 떡볶이(360g)/국물 떡볶이(422g)/즉석 라볶이(404g) 각5,980원 ※교차구매 가능

  1+1
  파스퇴르 쾌변 요구르트(150ml×8입) 10,780원

  1+1
  삼진 그랩어바이트 바삭칩(240g) 7,980원

  1+1
  아이브루 플러스 헤이즐넛향(1g×200T) 19,980원

  1+1
  하림 땡큐 닭볶음탕(1kg/냉장/국내산) 8,980원

  L.POINT 50% 할인
  양념 한우불고기(1kg/팩/냉장/원산지 별도표기) 19,900원

  롯데/신한카드 50% 
  ASC인증 완도 활전복(18미/10마리/국산) 9,900원 ※기간 : 4/3(목)~4/9(수)

  롯데/신한카드 50% 
  삼립 폭신폭신 플레인 치즈케익(70g×9입) 해당카드로 2개 이상 구매시 1개당 각4,240원 ※1개 구매시 8,480원

  롯데/신한카드 50% 
  쉐푸드 등심 통돈까스(600g) 해당카드로 2개 이상 구매시 1개당 각7,490원 ※1개 구매시 14,980원

  롯데/신한카드 50%
  큐피 참깨 드레싱(1L) 해당카드로 2개 이상 구매시 1개당 각5,990원 ※1개 구매시 11,980원

  50%
  동원 참치액 진(900g×2입) 11,490원

  2개 이상 50%
  레몬즙 5종(상품별 상이) 2개 이상 구매시 1개당 각4,990~7,890원 
  ※1개 구매시 각9,980~15,780원
  ※해당 브랜드 : 내추럴박스/다정헌/테일러 ※교차구매 가능

  2개 이상 50%
  크리넥스 마이비데 클린케어 물티슈(46매×4입) 2개 이상 구매시 1개당 각5,950원 ※1개 구매시 11,900원

  ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
  ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
  땡 잡았다! 한달내내 이 가격! 기간 : 4/1(화)~4/30(수)

  1등급 한우 등심/국거리, 불고기(각 100g/냉장/국내산 한우고기) 6,980/2,980원 ※리테일팩 제외

  L.POINT 각3,000원 할인
  롱스틱 직화육포(180g)/꾸이포대(1kg) 6,980/19,980원 ※원산지 별도표기

  L.POINT  2개 이상 각1,500원 할인
  머거본 커피/꿀 땅콩(각 600g)  2개 이상 구매시 1개당 각4,480원 ※1개 구매시 각5,980원 ※교차구매 가능

  L.POINT 40% 할인
  갱엿 순살 닭강정(팩/원산지 별도표기) 8,388원

  2,000원 할인
  고래사 5가지 야채 듬뿍 사각어묵(600g) 4,480원

  5,000원 할인
  산루카스 엑스트라버진 아보카도 오일(1L) 22,880원

  디벨라 전품목(제품별 규격 상이) 최대40% 할인
  ※스파게티니/스파게티/링귀네/푸실리/펜네/홀토마토

  L.POINT 6,000원 할인
  하이뮨 프로틴 밸런스 단백질바 미니(300g) 9,980원

  땡 잡았다! 신선먹거리!

  L.POINT 2개 이상 각2,000원 할인

  스테비아 대추방울 토마토(500g/팩/국산) 2팩 이상 구매시 1팩당 각4,980원 ※1팩 구매시 6,980원 ※기간 : 4/3(목)~4/9(수)

  상생 다다기 오이(5입/봉/국산)/대파(700g/봉/국산) 3,980/1,980원 ※기간 : 3/27(목)~4/2(수)

  한가득 시금치(400g/봉/국산)/풀무원 특등급 콩나물(200g/국산) 1,980/990원
  ※기간 : 3/27(목)~4/2(수)

  최대14,000원 할인
  GAP 완전미 고시히카리(10kg/국산)/찹쌀(4.5kg/국산) 29,800/16,800원 ※조기 품절될 수 있습니다 

  L.POINT 20% 할인
  패밀리팩 초밥(40P/팩/원산지 별도표기) 22,784원

  밀리 런치박스(팩/원산지 별도표기) 14,980원

  L.POINT 15% 할인
  미국산 초이스 살치살/꽃갈비살(각 100g/냉장/미국산) 5,253/8,483원

  L.POINT 각8,000원 할인
  미국산/호주산 LA식 꽃갈비(각 2kg, 냉동, 미국산/호주산) 69,800원

  L.POINT 최대10,000원 할인
  1등급 한우 차돌박이/1등급 한우 갈비살(700g/600g, 냉장, 국내산 한우고기) 47,800/69,800원

  L.POINT 30% 할인
  1등급 이상 돼지갈비/돼지등갈비(각 100g/냉장/국내산) 966/1,876원

  L.POINT 10% 할인 -> 롯데/신한카드 20% 추가할인 
  하림 더 건강한 두마리치킨(팩/닭고기 : 국내산) 12,946원
  ※페이지 하단 카드할인 세부내용 참고

  990원 할인
  천원 베이커리 뺑오 쇼콜라(1입/원산지 별도표기) 1,000원
  ※롯데마트맥스 목포, 상무점에 한함

  1,000원 할인
  곡물가득 고소한 치아바타(6입/팩/원산지 별도표기) 7,900원
  ※롯데마트맥스 영등포, 금천, 송천, 창원중앙점에 한함

  땡 잡았다! 온가족 인기 먹거리!

  5,000원 할인
  그릭데이 시그니처(800g) 14,980원

  4,500원 할인
  풀무원 우삼겹 김치볶음밥(5인분/1,250g) 10,480원

  2입 기획팩
  공구핫딜 납작지짐만두 2입 기획(700g×2) 9,980원

  3,400원 할인
  청정원 두번달여 더 진한 진간장(840ml×3입) 8,980원

  6,000원 할인
  씨제이 스팸 클래식(200g×10입) 29,680원

  1+1 맘으로 뼈없는 감자탕(500g) 5,980원

  2개 이상 15%
  오리온 땅콩강정(50g×11입)/꼬북칩 콘스프맛 미니팩(30g×12입) 2개 이상 구매시 1개당 각6,783/5,508원 ※1개 구매시 7,980/6,480원

  2개 이상 30% 
  아미카 엘도라다 포테이토칩(400g) 2개 이상 구매시 1개당 각7,686원 ※1개 구매시 10,980원

  롯데/신한카드 각5,000원 할인
  동서 맥심 카누 마일드미니/다크미니(각 0.9g×160입) 각29,230원

  L.POINT 각1,000원 할인
  매일유업 피크닉 사과/피크닉 청포도 제로(각 200ml×24입) 7,780/8,480원

  공구핫딜 내몸애 저분자 콜라겐 100+30(2.5g×130포) 17,990원

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 
  ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 
  ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  ※전단적용기간 : 2025. 3. 27(목) ~ 4. 9(수)
    
  </div>
  `,
  `
  <div>
  홈술족들 모여라! 새롭고 이로운 와인장터 주주총회

  롯데/신한카드 결제 고객!
  ▶ 카드할인 행사기간 : 3/27(목) ~ 4/2(수)
  15만원 이상 구매시 15,000원 할인
  30만원 이상 구매시 60,000원 할인

  꼬네따블 딸보 2021(750ml) 49,900원
  ※점별 6병 한정
  ※한정 수량으로 조기 품절될 수 있습니다

  L.POINT 10,000원 할인
  몬테스 알파 까르미네르 패키지(750ml) 27,800원

  L.POINT 50% 할인
  트리벤토 골든리저브(750ml) 20,000원

  L.POINT 10,000원 할인
  카를로 수수마니엘로(750ml) 14,900원

  L.POINT 5,000원 할인
  모토진판델(750ml) 9,900원

  L.POINT 4,000원 할인
  칼로로시 아이스피치(750ml) 5,900원

  L.POINT 10,000원 할인
  파스쿠아 하프보틀 3입 패키지(375ml×3) 19,900원

  북극곰의눈물 벚꽃에디션(720ml+사케잔 증정) 24,900원
  ※한정 수량으로 조기 품절될 수 있습니다

  L.POINT 61,100원 할인
  글렌알라키 10년 CS 배치 11(700ml) 128,800원

  L.POINT 71,800원 할인
  라가불린/탈리스커 디스틸러스 에디션(각 700ml) 118,000/99,800원

  L.POINT 32,200원 할인
  로얄 브라클라 12년(700ml) 92,800원

  L.POINT 5,180원 할인
  버팔로 트레이스(750ml) 39,800원 ※매장내 지류쿠폰 적용시 3,000원 추가할인

  땡 잡았다! 인기 생활필수품!

  1,000원 할인 -> 롯데/신한카드 3,000원 추가할인
  생활공감ON 천연펄프 화장지(30m×30롤) 9,880원
  ※페이지 하단 카드할인 세부내용 참고

  what is maxx 미용티슈(180매×6입) 5,980원

  최대5,000원 할인
  오비노비앙코 세탁세제(2.6L×2)/에이센트 3X퍼퓸드 섬유유연제(4L) 17,980/12,980원

  1,000원 할인 -> 롯데/신한카드 3,200원 추가할인
  싸이닉 엔조이 슈퍼 마일드 선 에센스(50ml×2입) 9,780원
  ※페이지 하단 카드할인 세부내용 참고

  3,000원 할인
  MAXX 시그니처 인덕션 프라이팬 2P 세트(블랙 22cm+28cm) 18,980원

  4,000원 할인
  MAXX 인덕션 에그팬 그릴팬 2P 세트 15,880원

  2,000원 할인
  글라스 멀티쿠커(대) 5,980원

  최대5,000원 할인
  비비안/베이직엘르 외 브랜드 언더웨어(상품별 상이) 13,980~36,800원

  내열유리 밀폐용기 2P(1,000ml/1,600ml) 7,980/8,980원

  5,000원 할인
  보덤 비스트로 머그컵 4P(300ml×4입) 29,800원

  7,820원 할인
  블랙야크 워크웨어 쿨 반집업 티셔츠 29,980원

  크록스 바야 클로그 39,800원

  롯데/신한 제휴카드 결제 고객 대상 40% 놀라운 혜택!
  ▶ 기간 : 3/27(목) ~ 4/9(수)
  ※해당카드 : 롯데마트&MAXX카드, LOCA for 롯데마트카드, 롯데마트맥스 신한카드

  계란 20% ※대란 60입 제외

  올리브유 전품목(상품별 상이) 40% ※기간 : 4/3(목) ~ 4/6(일)

  롯데칠성 인기 탄산음료 30% ※기간 : 3/27(목) ~ 3/30(일)

  ※정상가란? 2025년 3월 6일(목) ~ 3월 26일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
 
  </div>
  `,
];

/* 703,753 빅마켓(~6/26) */
const vicNewData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX 전용카드 회원만!
    미국산 초이스 소고기/즉석밥 20% 추가할인

    ▶ 기간 : 6/13(목) ~ 6/26(수)
    ▶ 해당카드 : 롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외                                      
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    미국산 초이스 소고기 20% 추가 할인

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    미국산 초이스 부채살/살치살 (각 100g/냉장/미국산) 1,584/3,584원
    ※해당카드 미적용시 1,980/4,480원

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    즉석밥 전품목(상품별 상이) 20% 추가 할인

    딱!! 100일간 이 가격 ※기간 : 6/10(월)~9/17(화)

    동물복지 백색 유정란 (대 30입/1.56kg/국내산) 1,500원 할인 7,980원
    건망고 (400g/베트남산) 2,000원 할인 7,980원

    L.POINT 2,000원 할인
    롱스틱 직화육포 (90g×2입/원산지 별도표기) 6,980원

  </div>
  `,
  `
  <div>
    스위트 마운틴 바나나 (1.2kg 내외/봉/원산지 별도표기) 2,780원

    1등급 국내산 돼지 앞다리(100g/냉장/국내산) 980원

    왕새우튀김 (팩/원산지 별도표기) 8,980원

    MAXX 1A 저지방우유(2.3L) 400원 할인 4,980원

    롯데 설레임 밀크쉐이크 (160ml×12입) 3,000원 할인 8,980원

    뮤엘올리바 카놀라유(2L) 1,000원 할인 8,980원

    크라운 초코하임(142g×5입) 2,000원 할인 7,480원

    토레타/파워에이드 마운틴 블라스트 (각 900ml×12입) 3,500원 할인 각11,480원

    L.POINT 4,000원 할인
    비비랩 가르시니아 플러스 (1,000mg×112정) 15,980원

    에고라운드 두피쿨링 민트 샴푸(1,000ml) 2,000원 할인 11,980원

    MAXX 테이크아웃 아이스컵 80P 세트 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    슬기로운 여름생활!

    롯데카드/신한카드 2,000원 할인
    AI선별 수박(6~10kg 미만/통/국산) 14,980~19,980원

    천도 복숭아(2kg/팩/국산) 12,980원

    롯데카드/신한카드 25% 할인
    1등급 국내산 돼지 삼겹살/목심 (각 100g/냉장/국내산) 각1,860원

    L.POINT 7,000원 할인 
    1등급 한우 차돌박이/갈비살 (700g/600g, 냉장, 국내산) 47,800/72,800원

    롯데카드/신한카드 50% 할인
    러시아 활 킹크랩 (100g/냉장/러시아산) 5,990원

    롯데카드/신한카드 2,000원 할인
    파스퇴르 쾌변 요구르트 (150ml×8입) 8,780원

  </div>
  `,
  `
  <div>
    1,000원 할인
    스페쇼우 젤리스트로우 플러스 (1,254g) 9,980원

    2팩 이상 20% 할인
    제주 삼다수 그린 (2L×6입/500ml×20입) 2팩 이상 구매시 1팩당 5,184/7,680원
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    풀무원 평양 물냉면(2,538g)/칠갑 얼음찬 메밀소바(804g) 2,000원 할인 8,480/5,480원

    롯데 스낵 버라이어티팩(894g) 2,000원 할인 11,980원

    기능성 반팔티셔츠/7부팬츠, 5부팬츠 3,000원 할인 12,980/14,980원

    MAXX 스탠다드 단목 양말 (6족, 남성/여성) 1,000원 할인 각7,980원
    ※일부 색상 조기 소진 가능
    ※기간 : 6/10(월)~9/17(화)

    주방 필수템!
    키친유 스텐 밀폐용기 2P(2.0L/3.6L) 최대 3,000원 할인 17,980/24,980원

    에찌모토 식과도 가위세트(3P) 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    6월 여름맞이 특별전 해양수산부와 함께하는 수산대전20%

    ※기간 : 6/13(목) ~ 6/19(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다 ※이미지 연출컷입니다
    ※L.POINT 회원 기간 중 1인 최대 1만원 할인 ※기간 內 주차별 1인/1만원 한도

    수산대전20%
    프리미엄 광어회 (500g 내외/팩/광어 : 국산) 29,984원

    수산대전20%
    ASC인증 완도 활전복 (대/14미/10마리) 19,840원

    수산대전20%
    원양산 오징어 (마리/해동) 2,384원

    수산대전20%
    영광 법성포 굴비 (1.4kg/팩/냉동/국산) 27,984원

    수산대전20%
    국산 고등어필렛 (1kg/팩/냉동) 8,784원

    수산대전20%
    황태채(400g/팩/러시아산)/볶음용, 조림용멸치(각 500g/팩/멸치:국산) 각9,984원

    제스프리 점보 골드키위(8~12입/팩/뉴질랜드산) 14,980원

    스윗탱고 토마토 (1.5kg/국산) 10,480원

    시즌컬러 채소모둠 (500g/팩/국내산) 4,680원

    양파(3kg/국내산) 5,380원
    ※기간 : 6/13(목)~6/19(수)

    새송이 버섯 (600g/팩/국내산) 2,780원

    양상추 (1봉/국내산) 1,980원

  </div>
  `,
  `
  <div>
    가바현미 7곡 (4.2kg/국내산) 2,000원 할인 14,980원

    귀한쌀(20kg/국내산) 7,000원 할인 49,800원

    하루한줌 고메넛츠 (30g×25봉/원산지 별도표기) 2,000원 할인 15,980원

    호두(1kg/미국산) 1,500원 할인 9,480원

    손질민물장어 (500g/팩/냉장/국내산) 34,980원

    데친 문어 (100g/냉장/베네수엘라산) 2,480원

    L.POINT 5,000원 할인
    생칵테일새우살 51-70 (908g/팩/냉동/베트남산) 14,980원

    L.POINT 3,000원 할인
    한판 모둠 소시지 (1.1kg/냉장/원산지 별도표기) 8,980원

    L.POINT 2,000원 할인
    요리하다 다리살로만 훈제오리(450g/냉장/원산지 별도표기) 8,980원

    L.POINT 각2,000원 할인
    하림 IFF 6개 품목 ※냉동/국내산 (상품별 상이/품목 2kg) 

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    L.POINT 15% 할인
    참한 새우초밥 (24P/팩/냉장/원산지 별도표기) 19,533원

    오징어볼/쉬림프칩 (240g/160g/원산지 별도표기) 2,000원 할인 각7,980원

    L.POINT 6,000원 할인
    100g당 1,290원
    양념 소불고기 (2kg/팩/냉장/원산지 별도표기) 25,800원

    서리태 콩국물/국산콩 콩국물(각 1L/국내산) 5,880/4,980원

    경양식 함박스테이크(9입/팩/원산지 별도표기) 13,980원

    일품 월남쌈 세트 (팩/원산지 별도표기) 13,980원

    가성비 식사/사이드 세트, 감탄박스 (1팩/원산지 별도표기) 8,900원

    오리지널 핫도그 (5입/1팩/원산지 별도표기) 2,000원 할인 8,900원

  </div>
  `,
  `
  <div>
    최대3,000원 할인 
    동원 고추참치(100g×10입)/동원 리챔 더블라이트(300g×6입) 15,480/21,980원
    
    2,740/4,000원 할인
    티아시아 골든 버터 난(200g)/쉐푸드 생크림버터 치킨카레,토마토 비프카레(각 170g×4입) 2,740/5,980원

    최대 40% 할인
    디벨라 파스타면 5종(상품별 규격 상이) 
    ※ 스파게티니, 스파게티, 링귀네, 푸실리, 펜네

    롯데카드/신한카드 4,000원 할인
    매일 상하 체다 슬라이스 치즈 (1.36kg/80매) 13,480원

    빙그레 딸기맛 우유 (240ml×8입) 1,000원 할인 7,480원

    5개 구매시 5,980원
    삼립 인기빵 10종(상품별 규격 상이) 5개 이상 구매시 1개당 각1,196원 
    ※주종발효 단팥크림빵, 주종발효 카스타드 소보루 外
    ※1개 구매시 각 1,480원

    트리코 고프레 프렌치 쿠키(450g) 14,980원

    트윅스 미니스 (1.02kg) 5,000원 할인 16,480원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    1+1 & 50% 할인

    1+1
    CJ 삼호 오뎅한그릇(360g)/구이한판 스모크 그릴(60g×4) 3,680/8,480원

    1+1
    사세 쏘스치킨 윙봉/순살 단짠갈릭맛(300g/375g) 각8,900원

    50%
    사조대림 국물떡볶이 (373g) 2,340원

    1+1
    풀무원 납작지짐만두(700g) 8,980원

    1+1
    동원 양반 수라 왕갈비탕/도가니설렁탕/보양추어탕/통다리삼계탕(각 460g) 각8,980원

    1+1
    담터 콤부차 샤인머스캣 포켓몬 기획(5g×30입) 15,980원

    1+1
    립톤 아이스티 피치(14g×120입) 37,980원
    ※ 점별 재고 한정

    1+1
    락티브 어린이 면역엔 멀티비타민 젤리(4g×60EA) 24,980원

  </div>
  `,
  `
  <div>
    크러시 쿨러백 (355ml×18입) 26,800원

    볼쓰 멜론×파쏘아 하이볼 패키지 (700ml×2ea+리유저블컵 3ea) 39,800원

    트레비 탄산수 3종 (각 300ml×20입) 각7,980원
    ※ 레몬/라임/플레인

    남양 프렌치카페 믹스 (10.9g×300입) 2,000원 할인 27,780원

    롯데카드/신한카드 5,000원 할인
    베지밀 검은콩과 검은참깨 두유 (190ml×24입) 11,980원

    멘토스 뉴레인보우 츄잉캔디 (미니/1kg) 1,800원 할인 9,480원

    뉴트리원 프리미엄 프리바이오틱스(5g×50포) 4,000원 할인 12,980원

    내추럴박스 카무트 브랜드 밀효소 (3g×15포×2입) L.POINT 2,000원 할인 19,980원

    롯데카드/신한카드 4,000원 할인
    바이탈슬림 리얼컷 아이돌물 녹차꿀맛 슬림(20ml×14포) 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    롯데카드/신한카드 3,000원 추가할인
    잘풀리는집 명품 로션티슈 에센셜(30m×30롤) 16,980원

    롯데카드/신한카드 2,000원 추가할인
    탑스텝 3in1 캡슐 세탁세제(100입) 15,980원

    옹달샘 습기제거제(18입) 2,000원 할인 12,980원

    롯데카드/신한카드 3,000원 할인
    시린메드 검케어(125g×5) 18,980원

    퍼펙트 건조대(대) 5,000원 할인 19,980원

    3M 이지 극세사 막대걸레 세트 (막대걸레 1P+극세사패드4매 구성) 6,000원 할인 18,980원

    제크롤 후면식 선풍기 (JK BF1400) 4,000원 할인 31,800원

    모리츠 메탈 20인치 선풍기 (MO 200MF) 4,000원 할인 55,800원

  </div>
  `,
  `
  <div>
    최대 70,000원 할인
    라코스테 클래식 카라/그래픽 반소매 티셔츠/블랙야크 웨크웨어 반소매 카라/라운드 티셔츠 24,980~59,800원
    
    나이키 브라질리아 더플백 (S, 41L) 3,000원 할인 32,800원

    NEW Arrival

    두툼한 손질 갈치 (400g/냉동/팩/모로코산) 6,880원

    MSC 짜먹는 냉동 명란 (30g×10팩/냉동/원산지 별도표기) 12,800원

    간편 고등어구이 (90g×4봉/냉동/원산지 별도표기) 13,800원

    뚜껑과 빨때를 제거해 환경까지 생각한 마이카페라떼 그린!
    매일카페라떼 마일드 컴포리드 (200ml×8입) 9,480원

    뉴트리 에버콜라겐 고백 (750mg×84정) 32,980원

    헤어플러스 아쿠아 워터 에센스 (200ml×2) 19,980원

    에이센트 3X 고농축 퍼퓸드 섬유유연제(4L) 15,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
];

/* 738 maxx(~3/26) */
const maxxNewData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
    ·전단적용기간 : 2025. 3. 13(목) ~ 2025. 3. 26(수)
    ·롯데마트맥스 : 금 ·전단적용기간 : 2025. 3. 13(목) ~ 2025. 3. 26(수) 천, 목포, 상무, 송천, 영등포, 창원중앙점
    믿을 수 없는 미친 가격
    MAD MAXX maxx로 질주하라!
    롯데카드/신한카드 최대5,000원 할인
    썬키스트 고당도 오렌지(10~15입/박스/미국산)/카라카라 오렌지(9~15입/봉/미국산) 14,980/11,980원
    ※페이지 하단 카드할인 세부내용 참고
    롯데카드/신한카드 4,000원 할인
    복음자리 딸기잼(1kg) 9,980원
    ※페이지 하단 카드할인 세부내용 참고 
    롯데카드/신한카드 30% 할인
    호주산 곡물비육 부채살 (100g/냉장/호주산) 1,995원
    ※페이지 하단 카드할인 세부내용 참고
    롯데카드/신한카드 5,000원 할인
    오래오래 맛있는 종가집 맛김치(2.5kg) 15,980원
    ※페이지 하단 카드할인 세부내용 참고
    L.POINT 15% 할인 광어회 (500g 내외/팩/냉장/광어 : 국산) 33,983원
    L.POINT 20% 생연어초밥 (22P/팩/냉장/원산지 별도표기) 14,384원
    롯데카드/신한카드 5,000원 할인 요리하다 양념 소불고기 (2kg/냉장/원산지 별도표기) 24,800원
    ※페이지 하단 카드할인 세부내용 참고
    롯데카드/신한카드 2,000원 할인 MAXX 프리미엄 화장지(40m×30롤) 17,980원
    ※페이지 하단 카드할인 세부내용 참고
    스윗텔토마토 (1.5kg/팩/국산) 12,980원
    공구핫딜 3종(상품별 규격 상이) 각9,980원
    ※매일견과/구운땅콩/바나나칩
    롯데카드/신한카드 5,000원 할인 서리태(1.2kg) 9,980원 
    ※페이지 하단 카드할인 세부내용 참고
    1등급 왕란(18입/1.22kg/국산) 1,500원 할인 3,980원
    L.POINT 4,000원 할인 광천 파래식탁김 (5g×36봉/원산지 별도표기) 8,980원
    2개 이상 45% 하림 닭볶음탕 (800g/냉장/국내산) 2개 이상 구매시 1개당 각4,345원
    ※1개 구매시 7,900원
    롯데카드/신한카드 2,000원 할인 매일 바이오 그릭요거트(800g) 5,780원
    ※페이지 하단 카드할인 세부내용 참고
    롯데카드/신한카드 2,000원 할인 오리온 예감 오리지널/치즈 그라탕(각 512g) 각5,980원
    ※페이지 하단 카드할인 세부내용 참고
    남양 프렌치카페 믹스 (10.9g×300입) 4,000원 할인 30,480원
    L.POINT 8,000원 할인 리얼닥터100억 유산균(30캡슐×2병) 17,980원
    1,500원 할인 -> 롯데카드/신한카드 1,000원 추가할인 
    리스테린 토탈케어 검케어 마일드(1L×2입) ※페이지 하단 카드할인 세부내용 참고 14,980원
    썬터치 베이직 가스버너 4,000원 할인 15,980원
    maxx PB 인기상품 최대 5,000원 할인 
    2개 이상 10% 2개 이상 15%
    MAXX 미네랄 워터 ECO 2종 (2L×6입/500ml×20입) 2개 이상 구매시 1개당 각1,602/2,703원
    ※동일규격 상품에 한함 ※1개 구매시 각1,780/3,180원
    MAXX 1A 신선한 우유(2.3L) 5% 4,446원
    MAXX 국내산 쌀누룽지(2kg) 5,000원 할인 18,980원
    MAXX 스탠다드 단목/중목양말(각 6매입) 1,000원 할인 각7,980원
    MAXX 초극세모 칫솔 (20개입) 3,000원 할인 8,980원
    초특가 4일장 1차 ※기간 : 3/13(목) ~ 3/16(일)
    롯데카드/신한카드 3,000원 할인
    상생 사과(1.8kg/국산) 9,980원
    ※페이지 하단 카드할인 세부내용 참고
    1등급 한우 전품목 (100g/냉장/국내산 한우고기) L.POINT 최대 35%할인
    ※냉장/냉동 규격제품, 리테일팩 제외
    롯데카드/신한카드 5,000원 할인
    동서 맥심 모카골드믹스(12g×310입)/화이트골드 믹스(11.7g×310입) 각35,280원
    ※페이지 하단 카드할인 세부내용 참고 ※인당 3개 구매제한
    L.POINT 7,000원 할인 오늘좋은 초저분자 콜라겐(2.5g×50포) 9,980원
    다우니 에이프릴 프레쉬(3.83L) 2,000원 할인 17,980원
    초특가 4일장 2차 ※기간 : 3/20(목) ~ 3/24(월) ※3/23(일) MAXX 정기휴무일
    까다롭게 선별한 1+등급란(특/30입/1.8kg/국산) 2,500원 할인 5,980원
    블랙사파이어 포도 (900g/팩/호주산) 9,980원
    미국산 프라임 립아이캡 (100g/냉장/미국산) 롯데카드/신한카드 35% 할인 1,937원
    ※페이지 하단 카드할인 세부내용 참고
    사조해표 카놀라유/포도씨유(각 900ml×4입) 최대 10,000원 할인 12,980/21,980원
    비비랩 가르시니아 플러스 (1,000mg×112정) 9,000원 할인 10,980원
    롯데/신한 제휴카드 결제 고객 대상 최대 30% 놀라운 혜택!
    ▶ 기간 : 3/13(목) ~ 3/26(수) ▶ 해당카드 : 롯데마트&MAXX카드, LOCA for 롯데마트카드, 롯데마트맥스 신한카드
    봉지라면 전품목 30% ※농심 신라면 30개입 外 기간 : 3/14(금) ~ 3/16(일)
    코카콜라 전품목 30% 기간 : 3/20(목) ~ 3/24(월) 해당상품 2만 3천원 이상 구매시 5천원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※결제시 계산대에서 수령
    세계 강아지의 날(3/23) 기념 반려동물식품 전품목 20% ※점별 운영상품 상이

    ※정상가란? 2025년 2월 20일(목) ~ 3월 12일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외

  </div>
  `,
  `
  <div>
    냉동과일 전품목(상품별 상이) L.POINT 각 2,000원 할인
    ※냉동 미국산 블루베리(1.81kg) 외 ※곶감세트 제외
    GAP 다다기오이(5입/봉/국산)/원데이샐러드(3입/270g/원산지 별도표기) 5,980/3,280원
    ※기간 : 3/13(목)~3/19(수)
    GAP 양송이버섯(350g/팩/국산)/과일 파프리카(500g/팩/국산) 5,280/6,280원
    귀한 쌀(20kg/국산) 4,000원 할인 54,400원
    노르웨이 고등어 필렛 (1.5kg/팩/냉동/노르웨이산) 롯데카드/신한카드 7,000원 할인 15,980원
    새우살 4종 (각 908g/팩/냉동/베트남산) L.POINT 각 3,000원 할인
    ※이미지 연출컷입니다
    L.POINT 최대 30% 할인 호주산 곡물비육 살치살/찜갈비(각 100g/냉장/호주산) 3,984/2,436원
    L.POINT 20% 할인 호주산 양목심/숄더렉 (각 100g/냉장/호주산) 3,184/3,584원
    L.POINT 각 3,000원 할인 국내산 대패 삼겹살/국내산 삼겹 한입구이(각 1kg/냉동/국내산) 각18,980원
    1등급 이상 돼지 앞다리 (100g/냉장/국내산) 980원
    하림 허니 치킨봉 (팩/닭고기 : 국내산) 12,980원
    하림 닭목살 구이 (팩/닭고기 : 국내산) 11,980원
    2025 대한민국수산대전 해양수산부와 함께하는 수산대전 20%
    ※기간 : 3/20(목) ~ 3/26(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다 ※L.POINT 회원 기간 내 주차별 1인 최대 1만원 할인 ※이미지 연출컷입니다
    수산대전 20% 강도다리 세꼬시 (250g/팩/냉장/도다리 : 국산) 15,984원
    수산대전 20% ASC인증 완도활전복 (대/10마리/국산) 19,840원
    수산대전 20% 원양산 오징어 (마리/해동) 2,624원
    수산대전 20% 볶음용/볶음조림용 멸치 (각 500g/멸치 : 국산) 각9,984원
    수산대전 20% 속살황태채/황태채 (250g/400g, 원산지 별도표기) 각9,984원
    수산대전 20% 대천 곱창김 (30매/100g/김 : 국산) 8,784원
    버터향 가득, 생크림 스콘(1입/원산지 별도표기)
    ※ 롯데마트맥스 상무, 목포점에 한함 500원 할인 1,990원
    돌돌 뽀드득 소시지빵(4입/원산지 별도표기) 1,000원 할인 7,900원
    ※베이커리팩토리 입점점에 한함
    1+1 햇반/소프트밀 4종(각 420g) 각4,980/5,980원
    ※ 단호박죽, 누룽지 닭백숙죽/한우소고기죽, 낙지김치죽
    ※동일가격 간 교차구매 가능
    1+1 동원 양반 진국 사골곰탕(500g) 1,980원
    2개 이상 50% 폰타나 드레싱 4종(각 270g) 2개 이상 구매시 1개당 각2,240원
    ※그릭요거트, 레몬알리올리, 오리엔탈, 이탈리안 드레싱 ※1개 구매시 각4,480원 ※교차구매 가능
    1+1 자임 하루하나 유기농 레몬즙/하루하나 유기농 생강 레몬즙 (각 20g×14입) 각15,800원
    ※교차구매 가능
    1+1 다정헌 유기농 레몬즙 2종 (상품별 상이) 각15,900원
    ※생강/레몬생강 ※교차구매 가능
    1+1 이디야 믹스커피/믹스 커피 바닐라 (각 1L) 각4,390원
    ※교차구매 가능
    2개 이상 50% 동원 개성 감자만두(1.2kg) 2개 이상 구매시 1개당 각5,240원
    ※1개 구매시 10,480원
    2개 이상 50% 하림 치킨쌀너겟 (1,200g) 2개 이상 구매시 1개당 각7,990원
    ※1개 구매시 15,980원
    L.POINT 50% 할인 더바이오마 폴리코사놀 22(500mg×60정) 23,500원
    1+1 일동후디스 산양유 단백질 (280g) 25,980원
    최대 30% 스트링치즈 전품목 (상품별 상이) 각9,086~12,480원
    ※덴마크 인포켓 外
    샘표 진간장 금S(860ml×2입) 3,000원 할인 4,980원
    큐원 트루스위트 알룰로스(1.18kg) 1,500원 할인 11,480원
    켈로그 첵스초코(1,100g)/크랜베리 그래놀라 파우치(1,200g) 각5,000원 할인 8,480/11,980원
    롯데단독 4,000원 할인 해태 아이비 아몬드크래커(712g) 9,980원
    L.POINT 2,880원 할인 디아블로 2종 (까베르네소비뇽/말벡) 각9,900원
    액츠 고농축 퍼펙트(4.0L) 5,000원 할인 7,980원
    고농축 피죤 옐로우 미모사 (4.05L) 5,000원 할인 8,980원
    프리미엄 플러스 TS 샴푸 기획세트(500g) 2,000원 할인 18,980원
    우르오스 스킨워시 (500ml×2입) 2,000원 할인 31,280원
    썬부탄가스(4P) 1,500원 할인 4,480원
    MAXX 박스종이컵(1,000P)/테이크아웃 종이컵(100P) 각 1,000원 할인 6,980/9,980원
    MAGNA 2WAY 핸드카트 10,000원 할인 69,800원
    블랙야크 워크웨어 긴팔 카라티셔츠/반집업 긴팔티셔츠 각39,800원
    ※95,100,105/BLACK,NAVY
    코튼 에센셜 티셔츠 각19,980원
    ※55~88 ※PINK,GREEN,IVORY,NAVY ※자료협찬 : (주)선도
    MD추천! 고탄력 스판 남성 스트레이트 데님 각29,800원
    ※3컬러/30, 32, 34, 36 ※고탄력 스트레치 원단 사용
    퀸센스 피크닉 IH 그리들(40cm) 5,000원 할인 24,980원
    애견꿀잠범퍼방석(75×58×19cm) 1,000원 할인 18,980원
    NEW Arrival
    생파인애플&포도 리코타 샐러드 (팩/원산지 별도표기) 9,980원
    손질아귀 (800g 내외/팩/냉장/국산) 12,980원
    롯데 간식자판기(858g) 15,300원
    ※ 전점 1,500개 한정
    시루조아 영양찰떡(600g) 7,980원
    Alluflon 인덕션 프라이팬(28cm)/Alluflon 인덕션 궁중팬(28cm+유리뚜껑) 15,980/22,980원
    Alluflon 가위 세트 2P 6,980원

    ※정상가란? 2025년 2월 20일(목) ~ 3월 12일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외

  </div>  
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getVicData = () => {
  const dataArray = [];
  vicData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxData = () => {
  const dataArray = [];
  maxxData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getVicNewData = () => {
  const dataArray = [];
  vicNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxNewData = () => {
  const dataArray = [];
  maxxNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L705: {
    title: "스마트전단지-빅마켓705",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L755: {
    title: "스마트전단지-빅마켓755",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L718: {
    title: "스마트전단지-맥스718",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202504_1/07",
    contents: getMaxxData(),
  },
  L703: {
    title: "스마트전단지-빅마켓703",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L735: {
    title: "스마트전단지-빅마켓735",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L738: {
    title: "스마트전단지-맥스738",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202503_3/07",
    contents: getMaxxNewData(),
  },
};

export default data;
