import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
  ·전단적용기간 : 2025. 4. 3(목) ~ 2025. 4. 9(수) < 비신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 9(수) >

  온국민이 땡잡는 날, 땡큐절 3.27 ~ 4.9

  롯데 안에서 쓰면 쓸수록 더 커지는 혜택!
  롯데멤버스 카드 출시기념!
  롯데마트 월 최대 20,000원 할인
  롯데슈퍼 월 최대 16,000원 할인
  ※GO APP 쿠폰으로 발급 ※자세한 내용은 롯데마트GO APP 및 롯데카드 홈페이지 참고

  일주일 내내 땡잡았다!
  롯데/BC/신한/NH농협/삼성카드 초특가 2탄 
  기 간 : 4/3(목) ~ 4/9(수)
  ※L.POINT 회원 해당카드 최종 결제 금액 기준입니다
  ※페이지 하단 카드할인 세부내용 참고

  척아이롤/부채살 연중 최저가 도전!
  ※연중 기간 : 24년 4월~25년 2월

  미국산 소고기 전품목 50%

  TV CF 방영상품 척아이롤 1,390원
  할인 전 가격 2,780원 (100g/냉장/미국산 소고기)

  미국산 부채살 1,590원
  할인 전 가격 3,180원 (100g/냉장/미국산 소고기)

  12Brix 이상 당도 선별 완료
  TV CF 방영상품 농가돕기 사과 2kg 대용량 9,990원
  할인 전 가격 14,990원 (2kg/봉/국산)

  단, 4일간!
  TV CF 방영상품 돼지고기 냉장 삼겹살/목심 반값! (각 100g/냉장/수입산)
  ※기간 : 4/3(목) ~ 4/6(일)
  ※조기 품절될 수 있습니다

  TV CF 방영상품 한 판 전복 10마리 반값! 9,950원
  할인 전 가격 19,900원 (10마리/냉장/국산)

  4/5 토, 4/6 일
  TV CF 방영상품
  봉지라면 전품목 3개 구매시 9,900원(상품별 규격 상이)
  ※브랜드 관계없이 교차구매 가능
  ※1인 1일 1회 9개 한정
  ※정상가 3,300원 미만,10개입/20개입 박스 상품 제외
  ※해당상품에 한함
  
  땡잡았다! 롯또 혜택! 
  ▶ 기 간 : 3/27(목)~4/9(수)
  ※이벤트 세부사항은 QR코드 스캔 후 이벤트 페이지에서 확인하세요

  EVENT 1
  매일 오전 10시~오후 1시 최대 10,000원 롯또쿠폰 증정!
  7만원 이상 구매시 10%할인 최대 1만원 한도

  EVENT 2
  총 1천명
  3개 채널에서 각 1만원 이상 구매시 최대 10,000점 스노우플랜 추첨 증정!

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한
  </div>
  `,
  `
  <div>
  땡잡았다! 오늘만 이 가격! 하루특가!

  4/3 목
  1+1
  수제 모둠 소시지(1kg/팩/원산지 별도표기) 12,980원

  4/4 금
  L.POINT 50%
  손질 주꾸미&낙지(500g/팩/냉동/원산지 별도표기) 9,900원

  대용량 상생 표고버섯(400g/봉/국산) 4,990원

  4/5 토 
  L.POINT 4,000원
  오픈런 필수템! 전점 1만봉 한정
  성주 꼬마참외(1kg/봉/국산) 5,990원 ※1인 2봉 구매한정

  4/6 일
  2개 이상 각1,000원 할인
  뉴질랜드 단호박 벌크(개/뉴질랜드산) 2개 이상 구매시 1개당 각1,990원 ※1개 구매시 2,990원

  롯데/BC/신한/NH농협/삼성카드 2+1
  스낵(상품별 상이) 890~11,900원
  ※브랜드 관계없이 교차구매 가능
  ※3개 구매시 34% 할인 ※3의 배수로 적용
  ※L.POINT 회원 해당카드 결제시 적용
  ※공구핫딜/기획상품 제외
  ※일부품목 제외 ※1인 1회 최대 9개 한정
  ※페이지 하단 카드할인 세부내용 참고 

  땡잡았다! 단,4일간 주말특가! ※기간 : 4/3(목) ~ 4/6(일)

  롯데/BC/신한/NH농협/삼성카드 1+1
  동원 퀴진 감자튀김 3종(상품별 규격 상이) 각7,980~8,980원
  ※케이준 양념감자/해쉬브라운 스틱/클링클컷
  ※동일 가격간 교차구매 가능

  1+1
  CJ 고메 함박스테이크/치즈 함박 스테이크(상품별 상이) 각9,980원 ※교차구매 가능

  1+1
  핸드워시 용기/리필(상품별 상이)
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  페이셜클렌징 인기 브랜드 70종
  ※VT/식물나라/뉴트로지나/해피바스/온더바디 등
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  마스크팩 인기 브랜드 130종
  ※메디힐/VT/린제이/JM솔루션/메디앤서/바노바기 외
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 품목 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  비요뜨 4종(상품별 상이) 2개 이상 구매시 1개당 각1,440원 ※1개 구매시 각2,880원
  ※링크/초코링/크런치볼/쿠키앤크림 ※교차구매 가능

  2개 이상 50%
  서울우유 트리플 슈레드 치즈(1kg) 2개 이상 구매시 1개당 각8,990원 ※1개 구매시 17,980원 ※전점 15,000개 한정

  2개 이상 50%
  그릭데이 4종(상품별 상이) 2개 이상 구매시 1개당 각1,900~7,000원 ※1개 구매시 각3,800~14,000원
  ※라이트/시그니처 ※교차구매 가능

  2개 이상 50%
  롯데칠성 음료 브랜드 대전(상품별 상이)
  ※PB 및 일부 상품 제외 ※교차구매 가능

  사케 2병 이상 20% 전통 증류주 2병 이상 10%
  사케 전품목/증류식 전통소주(상품별 상이) ※동일 카테고리 상품에 한함
  ※일부 품목 제외(사케전용잔패키지/처분상품 등)

  2개 이상 50%
  깨끗한나라 브랜드 37종(상품별 상이) ※교차구매 가능

  최대 30% + 10% 추가할인
  화장품 샵 베스트 어워즈
  ※참여 브랜드 : LG생활건강/아모레퍼시픽/이니스프리
  ※아이오페 레티놀/슈퍼바이탈/수려한 수분샘크림/이니스프리 그린티 외 11품목
  ※당일영수증에 한함 ※점별 입점점에 한함
  ※브랜드별 할인상품 상이 ※기간 : 4/5(토)~4/6(일)

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데
  </div>
  `,
  `
  <div>
  2,000원 요리하다 냉동밥 전품목(상품별 규격 상이) ※1입 상품 한정

  1+1
  하코야 닭꼬치 전품목(상품별 규격 상이)
  ※동일가격 간 교차구매 가능

  1+1
  냉동 핫도그 전품목(상품별 규격 상이)
  ※PB상품 제외 ※기간 : 4/3(목)~4/6(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  바릴라 파스타소스/면 전품목(상품별 규격 상이)
  ※동일 가격 상품 간 교차구매 가능

  1+1
  포도씨유/카놀라유/해바라기유 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  스테비아/알룰로스 전품목(상품별 규격 상이) ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  참치액/코인조미료 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  16입 두유 전품목(각 190ml×16입)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  콤부차 30입 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※콤부차 기획 상품 제외
  ※동일 브랜드별 교차구매 가능

  땡잡았다! 인기 카테고리 전품목 세일! ※기간 : 3/27(목) ~ 4/9(수) ※품목별 기간 상이

  1+1
  아이스티 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※아이스티 기획 상품 제외
  ※동일 브랜드 내 동일가격(규격) 간 교차구매 가능

  1+1
  섬유탈취제 용기 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 4/3(목)~4/6(일)
  ※페브리즈 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)
  ※동일 브랜드 내 동일가격 간 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  하겐다즈 파인트 전품목(상품별 규격 상이) ※교차구매 가능

  2+1
  카누 캡슐 커피 전품목(상품별 규격 상이)
  ※시즌 캡슐/캡슐기획 제외 ※동일 호환 기기별 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개 이상 50%
  냉동 물만두/군만두 전품목(상품별 규격 상이)
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  아기치즈 전품목(상품별 규격 상이)
  ※동일 브랜드 간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개 이상 50%
  프로쉬 식기세척기 세제 전품목
  (상품별 규격 상이) ※기간 : 4/3(목)~4/6(일) ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  크리넥스 마스크 전품목(상품별 규격 상이)
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능

  2개 이상 50%
  도루코 주방용품 전품목
  (상품별 규격 상이)※일부품목 제외
  ※기간 : 4/4(금)~4/6(일) ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 롯데마트 추천! 매일 신선 먹거리!

  와고메 와규 전품목 전품목 반값

  롯데/BC/신한/NH농협/삼성카드 50%
  호주 와규 협회 주관 컨테스트 수상브랜드로만 선별!(품목별 상이/냉장, 냉동)
  ※호주산 소고기 ※페이지 하단 카드할인 세부내용 참고

  초특가 4일장 ※기간 : 4/3(목) ~ 4/6(일)

  2개 이상 각2,000원 할인
  돼지고기 귀한부위 항정살/갈매기살/등심덧살(각 300g/냉동/국내산 돼지고기)
  ※1개 구매시 각 11,900원 ※교차구매 가능
  ※조기 품절될 수 있습니다 ※제주점 제외

  전점 2만팩 한정 서두르세요
  뒷다리 불고기 두근팩(1.2kg/냉장/국내산 돼지고기) 9,900원
  ※조기 품절될 수 있습니다
  ※제주점 제외

  1+1
  요리하다 1등급 한우 소불고기(300g/냉장/원산지 별도표기) 14,980원

  L.POINT 57%
  훈제오리 슬라이스(400g/냉장/원산지 별도표기) 5,990원

  연어는 롯데마트 반값 SUPER 세일 
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 50%
  연중 최저가 도전! 사전계약 물량 대방출! 연중 기간 : 24년 1월 ~25년 2월
  SUPER 생연어(횟감용/구이용)(각 100g/냉장/노르웨이산) 2,990/2,890원

  L.POINT 40%
  SUPER 생연어초밥(16입/냉장/연어:노르웨이산) 11,700원

  L.POINT 5,000원
  일식 끝판왕 장호준 셰프와 함께한 프리미엄 숙성 연어회
  장호준 셰프의 네기 연어회(120g/냉장/연어:노르웨이산) 9,900원

  L.POINT 10%
  손질오징어(3마리/해동/원양산) 9,810원

  한가득 드리는 가성비 채소 5종 추천
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  한가득 감자/고구마(각 2kg/봉/국산) 6,990/5,990원

  한가득 장아찌용 청양고추(400g/봉/국산) 3,990원

  한가득 모둠쌈(300g/팩/국산) 2,990원

  한가득 1mm 양배추 샐러드 2종(각 400g/봉/국산) 각2,990원

  롯데/BC/신한/NH농협/삼성카드 4,000원 할인
  최강록 나야시리즈 전품목(품목별 규격 상이/냉장/미국산)
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 30%
  무항생제 한우 전품목(1+등급)(각 100g/냉장/국내산 한우고기)

  국내산 닭가슴살 원료육 사용
  닭가슴살 6종 골라담기(상품별 규격 상이/냉장/원산지 별도표기) 오픈런! 닭가슴살 990원!
  ※허브/페퍼/오리지널/대파크림/숯불구이/스윗불닭맛
  ※한정수량 전점 200,000개
  ※조기 품절될 수 있습니다

  L.POINT  각4,000원 할인
  스페인산 냉동 대패류, 한입 삼겹살/목심(품목별 상이/냉동/스페인산 돼지고기)

  L.POINT 50%
  데친 홍게(1마리/냉장/국산) 4,495원

  1+1
  라면부터 파인다이닝까지! 만능 요리재료를 한봉 더!
  해물모둠(500g/냉동/팩/원산지 별도표기) 15,900원

  1+1
  동원 양반 김부각 6종/CJ 비비고칩 4종(상품별 용랑 상이/원산지 별도표기 ) 각4,470/3,720원 ※브랜드별 교차구매 가능

  32봉 특별기획
  동원 양반 들기름김 기획팩(4g×16봉×2입/원산지 별도표기) 9,980원

  ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
  ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다
  </div>
  `,
  `
  <div>
  롯데마트 차별화 과일 추천
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 3,000원
  AI로 선별한 성주참외(3~6입/봉/국산) 9,990원 ※기간 : 4/3(목) ~ 4/6(일)

  해발 750m 이상 고산지 재배! 쫀득한 식감! 오직 롯데에서만!
  2송이 이상 각1,000원 할인
  B750 바나나(송이/베트남산) 2송이이상 구매시 1송이당 각1,990원
  ※1송이 구매시 2,990원
  ※기간 : 4/3(목)~4/6(일)

  스마트팜 딸기(500g/팩/국산) L.POINT 3,000원 할인 ※해당상품 포함 딸기 전품목 L.POINT 3천원 할인

  L.POINT 3,000원 
  스마트팜 방울토마토(스위텔/스윗탱고/스윗허니)(상품별 중량 상이/국산) 7,990원
  ※점별 운영상품 상이

  L.POINT 4,000원 
  스테비아 방울토마토 전품목(상품별 규격 상이/국산) 5,990원

  온가족 나들이 즉석 먹거리 추천
  초밥 30입 반값
  L.POINT 50%
  반값 득템 초밥(30입)(1팩/원산지 별도표기) 14,990원 

  L.POINT 20%
  해물 양장피(1팩/원산지 별도표기) 15,992원

  L.POINT 50%
  갱엿 순살 닭강정(대)(1팩/원산지 별도표기) 8,995원

  L.POINT 20%
  온가족 모둠 도시락 13,592원

  L.POINT 25%
  로스트 오븐치킨(1팩/원산지 별도표기) 9,743원

  국내산 통족발(특대)(1팩/국내산) 19,800원 ※족발매장 입점점 한함

  5,000원 할인
  대용량 가득바삭파이(1팩/원산지 별도표기) 7,900원 ※베이커리팩토리/쁘띠르뽀미에 입점점 한함

  홈술족들 모여라! 새롭고 이로운 와인장터 주주총회
  주류코너에서 QR코드 찍으면 행사상품을 확인할 수 있어요

  L.POINT 24,100원
  파이퍼하이직 뀌베 브뤼(750ml) 64,900원

  L.POINT 15,100원
  하트브라더스 피티드(700ml) 34,800원

  L.POINT  36,000원
  시바스리갈 13년 셰리(1L) 49,800원

  이달의 맥주(500ml) 5캔 구매시 10,000원 ※1캔 구매시 2,500원

  세계맥주 67종(상품별 용량 상이) 5캔 구매시 9,900원
  ※1캔 구매시 각 3,000~3,300원

  2개 이상 50%
  찹쌀/현미(각 2.5kg/국산) 2개이상 구매시 1개당 각9,995원
  ※1개 구매시 각 19,990원
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능 ※조기 품절될 수 있습니다

  미국산 고당도 오렌지(8~10입/박스/미국산) 9,990원

  L.POINT  3,000원
  컷팅 파인애플(900g/팩/필리핀산) 6,990원
  ※조기 품절될 수 있습니다

  L.POINT 5,000원
  제주 천혜향(1.5kg/박스/국산) 12,990원

  GAP 완전미 고시히카리(10kg/국산)/해보드미(20kg/국산) 29,900/49,900원

  풀무원 Soga 크고 단단한 두부(900g/원산지 별도표기) 2,990원 ※조기 품절될 수 있습니다

  2개이상 각500원 할인
  애호박(개/국산) 2개이상 구매시 1개당 각1,490원 ※1개 구매시 1,990원
  ※조기 품절될 수 있습니다

  태국 망고(개/태국산) 4개 구매시 9,960원
  ※1개 구매시 3,990원 ※조기 품절될 수 있습니다

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 인기 먹거리 최대 1+1 최대 50%

  1+1
  오뚜기 생쫄면/메밀소바(각 2인분) 각6,980원
  ※교차구매 가능

  1+1
  냉장 꼬치어묵 2종(삼진/고래사) 각9,980원
  ※동일 상품에 한함

  1+1
  치킨너겟/텐더 13종(상품별 규격 상이) 각5,980~12,980원
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  동원 리챔 오리지널/더블라이트(각 200gX3입) 각15,980원
  ※교차구매 가능

  1+1
  롯데 알뜰 소시지(500g) 3,790원
  ※기간 : 4/3(목)~4/6(일)

  1+1
  사세 치킨가라아게/사세 통새우 튀김(상품별 상이) 각9,980원 ※교차구매 가능

  1+1
  마니커 바사삭 치킨/버팔로우 닭다리 골드(상품별 상이) 10,980/11,980원 ※동일 상품에 한함

  1+1
  오뚜기 볶음밥 2종(각 450g) 각6,580원 ※교차구매 가능

  1+1
  봉지라면 6종(상품별 규격상이) 각3,320~8,800원
  ※팔도&해찬들 차돌된장찌개라면 4개입 外 5종
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  오뚜기밥 찰현미/오곡/발아흑미(각 210g×3입) 각6,480원 ※교차구매 가능

  1+1
  매일 상하 스트링 치즈(18g×10입) 12,980원

  1+1
  코카콜라/코카콜라 제로(각 1.8L) 각3,990원 ※교차구매 가능

  1+1
  빙그레 끼리 크림치즈 포션 플레인/벨큐브 스패니시 스타일(상품별 상이) 각6,380/11,180원
  ※동일 상품에 한함

  1+1
  꽃샘 밤티라미수 라떼(18g×20입) 10,980원

  1+1
  삼립 휘낭시에 2종(크림치즈/크림치즈 딸기/각 360g) 각8,480원 ※교차구매 가능

  1+1
  글로벌 수입과자 11종(상품별 상이) 각3,500~15,980원
  ※점별 입고 물량 상이 ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  블루샥 라떼/피넛 라떼(상품별 상이) ※교차구매 가능
  이디야 블렌드 아메리카노(1g×100입)
  각12,900/각25,900원

  1+1
  상온/냉장커피 음료 69종(상품별 상이) 각1,880~4,390원
  ※동일 브랜드 내 동일 가격간 교차 구매 가능

  1+1
  수입음료 26종 ※점별 입고 물량 상이 3,680/7,980원
  ※동일 가격간 교차 구매 가능

  1+1
  청우 브루느와(165g) / 청우 쫀득초코칩(240g) 4,690/5,290원 ※동일 상품에 한함
  
  2+1
  CJ 냉동볶음밥/비빔밥 9종(상품별 상이) 각6,480~6,580원
  ※동일 가격간 교차구매 가능

  2개 이상 50%
  풀무원 노엣지피자 3종(상품별 규격상이) 2개 이상 구매시 1개당 각4,990원 ※1개 구매시 각9,980원
  ※코리안 BBQ/포테이토&콘/토마토&미트콤보 ※교차구매 가능

  2개 이상 50%
  빙그레 요플레 9종(상품별 상이) 2개 이상 구매시 1개당 각1,890원 ※1개 구매시 각 3,780원 ※교차구매 가능

  2개 이상 50%
  논알콜맥주 22종(상품별 상이) 2개 이상 구매시 1개당 각690~1,650원 ※1개 구매시 각1,380~3,300원
  ※교차구매 가능

  동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  양반밥 찰진백미밥/현미밥/흑미밥(각 130g×6입) 각10,480원 ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 1+1
  동원 퀴진 크리스피 돈까스 2종(상품별 규격 상이) 각10,480원 ※치즈/통등심 ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  동원 롤 유부초밥2종/리얼크랩스 더킹오리지널(상품별상이) 각6,980원 ※교차구매 가능

  롯데웰푸드 전품목 3만원 이상 구매시 10,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  롯데 의성마늘 순한 비엔나/프랑크(상품별 상이) 각7,980/8,980 ※동일 상품에 한함

  롯데 의성마늘 빅그릴 비엔나(300 g×2) 6,980원

  사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령

  사조대림 실속김밥세트(510g) 7,980원

  1+1 사조 살코기/마일드/고추참치(각 85g×4입) 각8,980원
  ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데

  </div>
  `,
  `
  <div>
  2개 이상 50%
  한성 크런치 롤 유부초밥(240g) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 7,980원

  2개 이상 50%
  진주햄 천하장사 2종(각 448g, 오리지널/치즈) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 각 7,980원
  ※교차구매 가능

  2개 이상 50%
  동원 딤섬 4종(상품별 규격상이) 2개 이상 구매시 1개당 각5,490~5,990원
  ※새우하가우/부추창펀/샤오롱바오/부채교 ※교차구매 가능
  ※1개 구매시 각10,980~11,980원

  2개 이상 50%
  진진 꿔바로우/탕수육(상품별 규격 상이) 2개 이상 구매시 1개당 각4,950~5,450원
  ※1개 구매시 각9,900~10,900원
  ※교차구매 가능

  2개 이상 20%
  동원 자연치즈 5종(상품별 상이) 2개 이상 구매시 1개당 각4,784~5,984원 ※1개 구매시 각5,980~7,480원 ※교차구매 가능

  2개 이상 50% 
  프레지덩 쁘띠 치즈, 버터 5종(상품별 상이) 2개 이상 구매시 1개당 각3,495~6,995원 ※1개 구매시 각6,990~13,990원
  ※쁘띠 브리치즈, 무염버터 外 ※교차구매 가능

  2개 이상 50%
  화제의 레몬즙 6종(상품별 상이) 2개 이상 구매시 1개당 각4,995~7,950원 ※1개 구매시 각9,990~15,900원 ※교차구매 가능

  2개 이상 50%
  매일 헬스케어 셀렉스 9종(상품별 상이) 2개 이상 구매시 1개당 각2,990~17,500원
  ※1개 구매시 각5,980~35,000원
  ※교차구매 가능 ※점별 운영상품 상이

  2개 이상 40%
  종근당건강 락토핏 6종(상품별 상이) 2개 이상 구매시 1개당 각9,540~17,940원 
  ※1개 구매시 각15,900~29,900원
  ※교차구매 가능

  5개 이상 각2,980원 할인
  씨제이 햇반 용기 죽 7종(상품별 상이) 5개 이상 구매시 1개당 각2,000원 
  ※1개 구매시 각 4,980원 ※교차구매 가능

  요리하다 찰통순대 기획(500g×2) 5,990원

  L.POINT 2,000원
  요리하다 마르게리따 피자(354g) 3,990원

  서울 흰우유(2.3L) 5,980원

  오늘좋은 얼큰한 번데기탕(280g) 1,740원

  CJ 비비고 육즙가득 고기만두(360g×2입) 10,980원

  동원 통그릴 비엔나(1kg) 10,980원

  오리온 마켓오 브라우니 바이트 제주말차(240g)
  롯데 명가 찰떡파이 초코바나나(250g) 5,280/ 5,580원

  공구핫딜 덴마크 피자모짜렐라 쫄깃한 치즈(300g×2) 9,980원

  공구핫딜 서울우유 제로 요구르트(270ml×6입) 3,980원

  롯데마트 단독
  칠성사이다 유리병 기획(300ml×4입) 3,980원

  샘표식품 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "S"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  샘표 국산 100% 조선고추장/토장(상품별 규격 상이) 29,900/ 22,900원 ※동일상품에 한함 

  동서식품 전품목 5만원 이상 구매시 5,000원 롯데상품권 증정
  ※동서식품 치즈 제외 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "  # "를 확인하세요 ※결제시 계산대에서 수령

  2,000원 할인
  동서 맥심 모카골드 마일드/화이트골드 220입+20입 증량업 기획(상품별 상이) 각31,220원 

  CJ제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "C"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  CJ 더건강한 베이컨(90g×2입) 육공육 더얇은 슬라이스 씬(250g) 각9,980원
  ※교차구매 가능 

  1+1
  해찬들 원조 태양초 고추장(1.5kg) 18,900원

  2개 이상 각1,000원 할인
  CJ 고메 소바바 치킨 5종(상품별 상이) 2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원 ※교차구매가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수))  ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함  ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡 잡았다! 생활필수품 최대 1+1 최대 50%

  1+1
  케라시스 클린노트 샴푸/트리트먼트(각 800ml) 각12,500원 ※베이비파우더향/화이트코튼향 ※교차구매 가능

  1+1
  리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 각15,900원
  ※손상모발용/지성두피용  ※교차구매 가능

  1+1
  메디안 잇몸과학 치약(각 120g×3입, 스트롱민트/클린민트)
  메디안 잇몸과학 칫솔(4입) 각12,980원 ※교차구매 가능 

  1+1
  오랄비 초미세모 잇몸케어 칫솔(3개) 11,900원

  롯데/BC/신한/NH농협/삼성카드 1+1
  섬유유연제 최대 66종
  ※다우니/피죤/아우라/스너글/버넬 
  ※점별 운영상품 상이
  ※동일 브랜드 내 동일가격에 한해 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고 

  1+1
  변기 세정제(상품별 상이) 각1,000~8,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 브랜드, 동일가격에 한해 교차구매 가능

  1+1
  주방세제(상품별 상이) 각1,900~10,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 브랜드, 동일가격에 한해 교차구매 가능

  1+1
  도브 바디케어 전품목(비누/핸드워시/바디워시/바디로션) 각5,900~15,900원
  ※점별 운영상품 상이 ※동일 품목내 동일 가격간 교차구매 가능

  LG생활건강 행사상품 3만원 이상 구매시 
  여행용 세트 증정
  코카콜라 행사상품 5만원 이상 구매시 
  아이스 박스 증정
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령
  ※구분자 "@"를 확인하세요  ※사은품 한정수량  ※사은품 소진시 자동 행사 종료

  1+1
  엘라스틴 헤드스파&퍼퓸 샴푸/컨디셔너(각 855ml) 각13,900원
  ※로지드림/디어자스민
  ※점별 운영상품 상이 ※교차구매 가능 

  1+1
  온더바디 퍼퓸 바디워시(각 900g) 각9,900원
  ※피오니로즈/화이트머스크 
  ※점별 운영상품 상이 ※교차구매 가능 

  1+1
  페리오 알파치약 기획(185g×4입) 10,900원

  헨켈 그린 모음전 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령

  L.POINT 4,100원 퍼실 그린 기획(세탁세제 총 4.65L+섬유유연제 900ml) 23,800원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  2+1
  디펜드 언더웨어 전품목(상품별 규격 상이) 
  ※일부 품목 제외 ※동일 가격 간 교차구매 가능

  2개 이상 50%
  도브 베이비 리치모이스처 로션/워시(각 400ml) 
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2,000원 할인
  벚꽃에디션 무형광 3겹 화장지(30m×30롤) 9,900원 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  좋은느낌 생리대(상품별 상이) 
  ※팬티라이너/탐폰/일부품목 제외 ※교차구매 가능

  2개 이상 50%
  슬릭 면도기/날/쉐이빙폼 등 8종(상품별 상이) 2개 이상 구매시 1개당 각3,100~21,950원 ※1개 구매시 각6,200~43,900원
  ※점별 입점상품 상이 ※동일 품목간 교차구매 가능

  최대 50%
  주거세정제(상품별 상이) ※행사상품에 한함
  ※점별 취급품목 상이  ※동일 브랜드, 동일가격에 한해 교차구매 가능

  2개 이상 50%
  벚꽃에디션 미용티슈/키친타월/물티슈(상품별 상이) 2개 이상 구매시 1개당 각1,000~3,950원 ※1개 구매시 각2,000~7,900원 ※교차구매 가능

  2개 이상 50%
  싸이닉 엔조이 선케어 3종(선크림 200g/선에센스 80ml/선스틱 20g) 2개 이상 구매시 1개당 각6,900~8,900원 
  ※1개 구매시 각13,800~17,800원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  1매당 8.9원 초가성비 물티슈!
  코코토토 데일리 물티슈(100매×10입) 8,900원

  AHC 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정 
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "A"를 확인하세요 ※결제시 계산대에서 수령

  AHC 유스래스팅 아이크림(35ml×2입+7ml×2입)/ AHC 바이탈 골든 콜라겐 크림(50g×2입+토너 30ml) 각25,900원

  피앤지 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ㉬ "를 확인하세요 ※결제시 계산대에서 수령

  최대 50%
  다우니/페브리즈/팬틴/질레트(상품별 상이)

  하기스 행사상품 8만원 이상 구매시 10,000원 롯데상품권 증정
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)   
  ※구분자 "㉻"를 확인하세요 ※결제시 계산대에서 수령 

  2개 이상 50%
  하기스 기저귀 19종(상품별 규격 상이) 2개 이상 구매시 각15,300~22,950원 ※1개 구매시 각30,600~45,900원 ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 홈 인테리어ㆍ잡화류 최대 1+1ㆍ최대 50%

  브랜드 프라이팬/밀폐용기 최대 50%(상품별 상이) ※입점점에 한함 

  40%
  테팔 스타트이지/에브리데이쿡 11종(상품별 상이) 각18,480~35,880원

  50%
  해피콜 쿡메이트 프라이팬/궁중팬 2종(각 28cm) 각18,900원

  40,000원 할인
  쿡셀 더블 블랙 프라이팬 2P세트(20cm/28cm) 49,900원

  50%
  락앤락 익스트롱 프라이팬 6종(상품별 상이) 각15,450~18,450원

  L.POINT 50%
  글라스락 인기 밀폐 시리즈
  ※스마트/퓨어오븐/모듈러/퓨어핸디/간편캡 등

  L.POINT 50%
  스텐락 인기 밀폐 시리즈
  ※퓨어/이지오픈

  L.POINT 40%
  락앤락 인기 밀폐 시리즈
  ※ 비스프리모듈러/더클리어/클리어비스프리/ 프리저핏/스마트킵/클래식 등

  1+1 기획
  글라스락 센스 핑크 에디션 1+1기획(상품별 용량 상이) 각5,900~19,900원

  2개 이상 50%
  크린랩 이중지퍼백/뽑아쓰는 크린백(상품별 상이) 2개 이상 구매시 1개당 각3,145~7,695원
  ※1개 구매시 각6,290~15,390원
  ※교차구매 가능

  2개 이상 50%
  오늘좋은 데일리 울트라쿨 냉감패드(SS : 110×200cm/Q : 150×200cm) 2개 이상 구매시 1개당 각14,950/19,950원
  ※교차구매 가능  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  땡잡았다! 초특가을 만나보세요!
  ※매장에 더 많은 상품이 준비되어 있습니다.

  990원

  오늘좋은 데일리 무세제 클리너(3P/11×3×6cm) 990원 정상가 1,500원

  오늘좋은 베이직 바스켓 7종/클래식 바스켓 4종(상품별 상이) 990원 정상가 각1,000원~1,500원

  베이직 남성/여성 덧신/양말(각 1매) 990원 정상가 각 2,000원

  동원 뉴트리플랜 소프트뮨 강아지 습식간식 3종(각 100g) 990원 정상가 각 2,000원

  4,990원

  룸바이홈 럭셔리 호텔 세면타월(각 40×60cm, 네이비/그레이/화이트) 4,990원 정상가 각 6,990원
  ※ 입점점에 한함 ※ 해당상품에 한함 ※ 점별 재고 상이

  맥스 폭발방지 CRV 부탄가스(4입) 4,990원 정상가 6,790원

  향기가득 프리미엄 워셔액 4종(각 1.8L×2) 4,990원 정상가 각 5,900원

  1+1 기획팩 유니랩 위생장갑(200매+200매) 4,990원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외   ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  땡잡았다! 홈 인테리어ㆍ잡화류 최대 1+1ㆍ최대 50%

  50%
  풍년 마레러스터 압력솥(8인) 59,000원

  롯데/BC/신한/NH농협/삼성카드 50%
  포트메리온(상품별 용량 상이) 5,340~41,665원 ※입점점에 한함 
  ※ 점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고 

  50%
  아이리스 시스템 수납함(중형 2호)/빌드업 바스켓 3종(상품별 상이) 각1,000~9,950원 ※입점점에 한함  

  9,910원 할인
  오늘좋은 아로마 세면타월(각 4P, 유칼립투스/아쿠아/라벤더/블라썸) 각9,990원
  ※입점 점포에 한함 ※점별 재고 상이

  10,000원 할인
  트래커 브리튼 여행가방(16/20/24/28인치) 49,000~89,000원
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  30%
  오늘좋은 세탁이 편한 빨아쓰는 베개(소 : 40×60cm/대 : 50×70cm) 15,330/20,930원
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  2개 이상 30%
  브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각6,930~7,630원 ※교차구매 가능 ※1개 구매시 각9,900~10,900원
  ※뉴발란스, 아디다스, 언더아머, 네파 등 ※점별 상품 상이

  2+1
  남성 브랜드 언더웨어(상품별 상이) 6,900원
  ※브랜드 : 트라이/보디가드/베이직엘르 외  ※교차구매 가능

  땡잡았다! 초특가을 만나보세요!
  ※매장에 더 많은 상품이 준비되어 있습니다.

  7,990원

  SOUP/AD HOC 데일리진(스트레이트/테이퍼드) 7,990원 정상가 각 14,800원

  오늘좋은 쿠션/방석 10종 7,990원
  ※ 상품별 상이
  ※ 해당상품에 한함

  코코도르 차량용 미니멀 디퓨저(50ml×2) 7,990원 정상가 각 9,900원

  오늘좋은 패브릭 발매트 11종(메모리폼/워셔블 外) 7,990원 정상가 각 10,900원~12,900원

  오늘좋은 심리스 런닝/땀패드 런닝 7,990원 정상가 각 8,900~12,900원

  9,990원

  블랙 박스 카트(M, 38×29×85.5cm) 9,990원 정상가 15,900원
  ※입점점에 한함
  ※조기 품절될 수 있습니다

  에너자이저 맥스10+5입(AA/AAA) 9,990원 정상가 각 12,900원

  뉴발란스/데상트 러닝 양말(각 2매) 9,990원

  룸바이홈 줄누비 패드(멀티체크/140×190cm) 9,990원 ※ 해당상품에 한함

  대림바스 PVC 샤워호스(실버 1.5m/어반 그레이, 어반화이트 2m) 9,990원 정상가 각 13,900/14,900원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외   ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  1+1
  코렐 냉면기 4종(상품별 용량 상이) 각21,900원
  ※입점점에 한함  ※점별 재고 상이  ※교차구매 가능

  1+1
  유니랩 셰프장갑(소/중/대)/원데이 일회용 수세미(핑크/그린) 각4,990원
  ※동일 상품에 한함

  1+1
  크린랩 크린장갑(200매)/이중지퍼백(20매,소/중/대)/랩(30X50cm) 각3,190~9,090원

  1+1 기획팩
  크린랩 베이킹소다 세정티슈/크린랩 찌든때용 세정티슈(50매+50매) 4,390원 ※입점점에 한함 ※점별 재고 상이

  2+1
  불스원샷 70000(기획상품) 32,000원
  ※입점 점포에 한함  ※행사 상품에 한함

  2개 이상 20%
  스크럽 대디/마미 수세미 마이크로파이버타올 2개 이상 구매시 1개당 각4,392~6,392원 
  ※1개 구매시 각 5,490~7,990원 ※교차구매 가능

  2개 이상 20%
  오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 각3,920원  ※1개 구매시 4,900원

  40%
  테팔 인텐시움 통 3중 냄비 3종(상품별상이) 26,280~37,680원

  30%
  코렐 목련/더블링 그린티 外 5,530~15,330원

  30%
  오늘좋은 데일리 피치스킨 이불(S/Q) 16,030/20,930원
  ※S : 150×200cm/Q : 180×220cm

  30%
  오늘좋은 패브릭 리빙박스 4종(40L/66L/80L/120L) 4,130~13,930원 

  최대 40%
  세차도구 전품목(상품별 규격상이)
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  20%
  러닝용품 전품목 8,720~39,920원 ※입점점에 한함 
  ※브랜드 : 나이키/아디다스/언더아머 外

  최대 9,000원 할인
  브랜드 와인잔 2P (슈피겔라우/쇼트즈위젤 外) 각9,900원

  3M 울트라클린 크린스틱 스타터팩/리필(핸들1입+리필5입/리필10입) 7,900/6,900원
  ※입점점에 한함 ※점별 재고상이

  꽃 피는 다육 포트 3종기획 각9,990원 ※입점점에 한함

  르젠 써큘레이터형 스탠드 선풍기(45W) 39,900원

  브랜드 반팔&반바지 19,900~29,900원
  ※입점점에 한함 ※브랜드 : 휠라/푸마/르까프 外

  여성 브랜드 언더웨어 브라/팬티 4,900/ 9,900원
  ※입점점에 한함 ※브랜드 : 비비안/비너스/오테나 外

  쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
  ※기간 3/20(목)~4/23(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령

  최대 20%
  쿡셀 프라이팬/냄비 18종(상품별 상이) 31,120~49,900원

  에너자이저 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
  ※기간 3/27(목)~4/9(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "㉧"를 확인하세요 ※결제시 계산대에서 수령

  10%
  에너자이저 맥스플러스 4입 8,370원

  ToysRus 인기완구추천!
  ※ 기간 : 3/27(목)~4/9(수)  ※ 매장에 더 많은 상품이 준비되어 있습니다  ※입점점에 한함

  L.POINT  30%
  마블 14인치 피규어세트 아이언맨/스파이더맨(6세 이상/49×11.2×45cm) 55,930원

  롯데/BC/신한/NH농협/삼성카드 10,000원 할인
  피아트 500e 푸쉬카(102×56×97cm) 89,000원
  ※점별 재고수량 상이
  ※페이지 하단 카드할인 세부내용 참고

  colioli 득템위크 반려동물식품&용품 500여종 할인! 개이득! 냥이득!
  반려동물식품/용품 3만원 이상 구매시 5천원 롯데상품권 증정!
  ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※기간 : 3/27(목)~4/9(수) 
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ♠ "를 확인하세요
  ※페이지 하단 카드할인 세부내용 참고

  롯데/BC/신한/NH농협/삼성카드 40%
  콜리올리 순살가득 스틱/슬라이스 4종(각 300g, 닭고기/오리고기) 7,140원
  ※입점점에 한함  ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 40%
  콜리올리 깔끔하게 사용하는 1회용 패드(M 100매/M 200매/L 40매) 각7,140~14,340원
  ※입점점에 한함  ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 30%
  피랩 마음놓고먹어 강아지 간식 5종(각 1kg) 각11,130~16,730원 
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  30%
  템테이션 8종(상품별 상이) 3,010원 ※입점점에 한함

  롯데마트 단 독
  5만원 구매 시 5천원 즉시할인
  ▶기간 : 3/27(목) ~ 4/9(수)
  ※최종 결제 금액에서 5천원 차감(탑텐 영수증 확인 필수)
  ※탑텐, 탑텐키즈, 탑텐밸런스 타 할인 쿠폰 행사와 중복 적용 불가

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
  ·전단적용기간 : 2025. 4. 3(목) ~ 2025. 4. 9(수) < 비신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 9(수) >
  ·제타플렉스 잠실점

  온국민이 땡잡는 날, 땡큐절 3.27 ~ 4.9

  롯데 안에서 쓰면 쓸수록 더 커지는 혜택!
  롯데멤버스 카드 출시기념!
  롯데마트 월 최대 20,000원 할인
  롯데슈퍼 월 최대 16,000원 할인
  ※GO APP 쿠폰으로 발급 ※자세한 내용은 롯데마트GO APP 및 롯데카드 홈페이지 참고

  일주일 내내 땡잡았다!
  롯데/BC/신한/NH농협/삼성카드 초특가 2탄 
  기 간 : 4/3(목) ~ 4/9(수)
  ※L.POINT 회원 해당카드 최종 결제 금액 기준입니다
  ※페이지 하단 카드할인 세부내용 참고

  척아이롤/부채살 연중 최저가 도전!
  ※연중 기간 : 24년 4월~25년 2월

  미국산 소고기 전품목 50%

  TV CF 방영상품 척아이롤 1,390원
  할인 전 가격 2,780원 (100g/냉장/미국산 소고기)

  미국산 부채살 1,590원
  할인 전 가격 3,180원 (100g/냉장/미국산 소고기)

  12Brix 이상 당도 선별 완료
  TV CF 방영상품 농가돕기 사과 2kg 대용량 9,990원
  할인 전 가격 14,990원 (2kg/봉/국산)

  단, 4일간!
  TV CF 방영상품 돼지고기 냉장 삼겹살/목심 반값! (각 100g/냉장/수입산)
  ※기간 : 4/3(목) ~ 4/6(일)
  ※조기 품절될 수 있습니다

  TV CF 방영상품 한 판 전복 10마리 반값! 9,950원
  할인 전 가격 19,900원 (10마리/냉장/국산)

  4/5 토, 4/6 일
  TV CF 방영상품
  봉지라면 전품목 3개 구매시 9,900원(상품별 규격 상이)
  ※브랜드 관계없이 교차구매 가능
  ※1인 1일 1회 9개 한정
  ※정상가 3,300원 미만,10개입/20개입 박스 상품 제외
  ※해당상품에 한함
  
  땡잡았다! 롯또 혜택! 
  ▶ 기 간 : 3/27(목)~4/9(수)
  ※이벤트 세부사항은 QR코드 스캔 후 이벤트 페이지에서 확인하세요

  EVENT 1
  매일 오전 10시~오후 1시 최대 10,000원 롯또쿠폰 증정!
  7만원 이상 구매시 10%할인 최대 1만원 한도

  EVENT 2
  총 1천명
  3개 채널에서 각 1만원 이상 구매시 최대 10,000점 스노우플랜 추첨 증정!

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한
  </div>
  `,
  `
  <div>
  땡잡았다! 오늘만 이 가격! 하루특가!

  4/3 목
  1+1
  수제 모둠 소시지(1kg/팩/원산지 별도표기) 12,980원

  4/4 금
  L.POINT 50%
  손질 주꾸미&낙지(500g/팩/냉동/원산지 별도표기) 9,900원

  대용량 상생 표고버섯(400g/봉/국산) 4,990원

  4/5 토 
  L.POINT 4,000원
  오픈런 필수템! 전점 1만봉 한정
  성주 꼬마참외(1kg/봉/국산) 5,990원 ※1인 2봉 구매한정

  4/6 일
  2개 이상 각1,000원 할인
  뉴질랜드 단호박 벌크(개/뉴질랜드산) 2개 이상 구매시 1개당 각1,990원 ※1개 구매시 2,990원

  롯데/BC/신한/NH농협/삼성카드 2+1
  스낵(상품별 상이) 890~11,900원
  ※브랜드 관계없이 교차구매 가능
  ※3개 구매시 34% 할인 ※3의 배수로 적용
  ※L.POINT 회원 해당카드 결제시 적용
  ※공구핫딜/기획상품 제외
  ※일부품목 제외 ※1인 1회 최대 9개 한정
  ※페이지 하단 카드할인 세부내용 참고 

  땡잡았다! 단,4일간 주말특가! ※기간 : 4/3(목) ~ 4/6(일)

  롯데/BC/신한/NH농협/삼성카드 1+1
  동원 퀴진 감자튀김 3종(상품별 규격 상이) 각7,980~8,980원
  ※케이준 양념감자/해쉬브라운 스틱/클링클컷
  ※동일 가격간 교차구매 가능

  1+1
  CJ 고메 함박스테이크/치즈 함박 스테이크(상품별 상이) 각9,980원 ※교차구매 가능

  1+1
  핸드워시 용기/리필(상품별 상이)
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  페이셜클렌징 인기 브랜드 70종
  ※VT/식물나라/뉴트로지나/해피바스/온더바디 등
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  마스크팩 인기 브랜드 130종
  ※메디힐/VT/린제이/JM솔루션/메디앤서/바노바기 외
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 품목 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  비요뜨 4종(상품별 상이) 2개 이상 구매시 1개당 각1,440원 ※1개 구매시 각2,880원
  ※링크/초코링/크런치볼/쿠키앤크림 ※교차구매 가능

  2개 이상 50%
  서울우유 트리플 슈레드 치즈(1kg) 2개 이상 구매시 1개당 각8,990원 ※1개 구매시 17,980원 ※전점 15,000개 한정

  2개 이상 50%
  그릭데이 4종(상품별 상이) 2개 이상 구매시 1개당 각1,900~7,000원 ※1개 구매시 각3,800~14,000원
  ※라이트/시그니처 ※교차구매 가능

  2개 이상 50%
  롯데칠성 음료 브랜드 대전(상품별 상이)
  ※PB 및 일부 상품 제외 ※교차구매 가능

  전통 증류주 2병 이상 10%
  증류식 전통소주(상품별 상이)
  ※일부 품목 제외(처분상품 등) ※교차구매 가능

  2개 이상 50%
  깨끗한나라 브랜드 37종(상품별 상이) ※교차구매 가능

  최대 30% + 10% 추가할인
  화장품 샵 베스트 어워즈
  ※참여 브랜드 : LG생활건강/아모레퍼시픽/이니스프리
  ※아이오페 레티놀/슈퍼바이탈/수려한 수분샘크림/이니스프리 그린티 외 11품목
  ※당일영수증에 한함 ※점별 입점점에 한함
  ※브랜드별 할인상품 상이 ※기간 : 4/5(토)~4/6(일)

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데
  </div>
  `,
  `
  <div>
  2,000원 요리하다 냉동밥 전품목(상품별 규격 상이) ※1입 상품 한정

  1+1
  하코야 닭꼬치 전품목(상품별 규격 상이)
  ※동일가격 간 교차구매 가능

  1+1
  냉동 핫도그 전품목(상품별 규격 상이)
  ※PB상품 제외 ※기간 : 4/3(목)~4/6(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  바릴라 파스타소스/면 전품목(상품별 규격 상이)
  ※동일 가격 상품 간 교차구매 가능

  1+1
  포도씨유/카놀라유/해바라기유 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  스테비아/알룰로스 전품목(상품별 규격 상이) ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  참치액/코인조미료 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  16입 두유 전품목(각 190ml×16입)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  콤부차 30입 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※콤부차 기획 상품 제외
  ※동일 브랜드별 교차구매 가능

  땡잡았다! 인기 카테고리 전품목 세일! ※기간 : 3/27(목) ~ 4/9(수) ※품목별 기간 상이

  1+1
  아이스티 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※아이스티 기획 상품 제외
  ※동일 브랜드 내 동일가격(규격) 간 교차구매 가능

  1+1
  섬유탈취제 용기 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 4/3(목)~4/6(일)
  ※페브리즈 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)
  ※동일 브랜드 내 동일가격 간 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  하겐다즈 파인트 전품목(상품별 규격 상이) ※교차구매 가능

  2+1
  카누 캡슐 커피 전품목(상품별 규격 상이)
  ※시즌 캡슐/캡슐기획 제외 ※동일 호환 기기별 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개 이상 50%
  냉동 물만두/군만두 전품목(상품별 규격 상이)
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  아기치즈 전품목(상품별 규격 상이)
  ※동일 브랜드 간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개 이상 50%
  프로쉬 식기세척기 세제 전품목
  (상품별 규격 상이) ※기간 : 4/3(목)~4/6(일) ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  크리넥스 마스크 전품목(상품별 규격 상이)
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능

  2개 이상 50%
  도루코 주방용품 전품목
  (상품별 규격 상이)※일부품목 제외
  ※기간 : 4/4(금)~4/6(일) ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 롯데마트 추천! 매일 신선 먹거리!

  와고메 와규 전품목 전품목 반값

  롯데/BC/신한/NH농협/삼성카드 50%
  호주 와규 협회 주관 컨테스트 수상브랜드로만 선별!(품목별 상이/냉장, 냉동)
  ※호주산 소고기 ※페이지 하단 카드할인 세부내용 참고

  초특가 4일장 ※기간 : 4/3(목) ~ 4/6(일)

  2개 이상 각2,000원 할인
  돼지고기 귀한부위 항정살/갈매기살/등심덧살(각 300g/냉동/국내산 돼지고기)
  ※1개 구매시 각 11,900원 ※교차구매 가능
  ※조기 품절될 수 있습니다 ※제주점 제외

  전점 2만팩 한정 서두르세요
  뒷다리 불고기 두근팩(1.2kg/냉장/국내산 돼지고기) 9,900원
  ※조기 품절될 수 있습니다
  ※제주점 제외

  1+1
  요리하다 1등급 한우 소불고기(300g/냉장/원산지 별도표기) 14,980원

  L.POINT 57%
  훈제오리 슬라이스(400g/냉장/원산지 별도표기) 5,990원

  연어는 롯데마트 반값 SUPER 세일 
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 50%
  연중 최저가 도전! 사전계약 물량 대방출! 연중 기간 : 24년 1월 ~25년 2월
  SUPER 생연어(횟감용/구이용)(각 100g/냉장/노르웨이산) 2,990/2,890원

  L.POINT 40%
  SUPER 생연어초밥(16입/냉장/연어:노르웨이산) 11,700원

  L.POINT 5,000원
  일식 끝판왕 장호준 셰프와 함께한 프리미엄 숙성 연어회
  장호준 셰프의 네기 연어회(120g/냉장/연어:노르웨이산) 9,900원

  L.POINT 10%
  손질오징어(3마리/해동/원양산) 9,810원

  한가득 드리는 가성비 채소 5종 추천
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  한가득 감자/고구마(각 2kg/봉/국산) 6,990/5,990원

  한가득 장아찌용 청양고추(400g/봉/국산) 3,990원

  한가득 모둠쌈(300g/팩/국산) 2,990원

  한가득 1mm 양배추 샐러드 2종(각 400g/봉/국산) 각2,990원

  롯데/BC/신한/NH농협/삼성카드 4,000원 할인
  최강록 나야시리즈 전품목(품목별 규격 상이/냉장/미국산)
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 30%
  무항생제 한우 전품목(1+등급)(각 100g/냉장/국내산 한우고기)

  국내산 닭가슴살 원료육 사용
  닭가슴살 6종 골라담기(상품별 규격 상이/냉장/원산지 별도표기) 오픈런! 닭가슴살 990원!
  ※허브/페퍼/오리지널/대파크림/숯불구이/스윗불닭맛
  ※한정수량 전점 200,000개
  ※조기 품절될 수 있습니다

  L.POINT  각4,000원 할인
  스페인산 냉동 대패류, 한입 삼겹살/목심(품목별 상이/냉동/스페인산 돼지고기)

  L.POINT 50%
  데친 홍게(1마리/냉장/국산) 4,495원

  1+1
  라면부터 파인다이닝까지! 만능 요리재료를 한봉 더!
  해물모둠(500g/냉동/팩/원산지 별도표기) 15,900원

  1+1
  동원 양반 김부각 6종/CJ 비비고칩 4종(상품별 용랑 상이/원산지 별도표기 ) 각4,470/3,720원 ※브랜드별 교차구매 가능

  32봉 특별기획
  동원 양반 들기름김 기획팩(4g×16봉×2입/원산지 별도표기) 9,980원

  ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
  ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다
  </div>
  `,
  `
  <div>
  롯데마트 차별화 과일 추천
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 3,000원
  AI로 선별한 성주참외(3~6입/봉/국산) 9,990원 ※기간 : 4/3(목) ~ 4/6(일)

  해발 750m 이상 고산지 재배! 쫀득한 식감! 오직 롯데에서만!
  2송이 이상 각1,000원 할인
  B750 바나나(송이/베트남산) 2송이이상 구매시 1송이당 각1,990원
  ※1송이 구매시 2,990원
  ※기간 : 4/3(목)~4/6(일)

  스마트팜 딸기(500g/팩/국산) L.POINT 3,000원 할인 ※해당상품 포함 딸기 전품목 L.POINT 3천원 할인

  L.POINT 3,000원 
  스마트팜 방울토마토(스위텔/스윗탱고/스윗허니)(상품별 중량 상이/국산) 7,990원
  ※점별 운영상품 상이

  L.POINT 4,000원 
  스테비아 방울토마토 전품목(상품별 규격 상이/국산) 5,990원

  온가족 나들이 즉석 먹거리 추천
  초밥 30입 반값
  L.POINT 50%
  반값 득템 초밥(30입)(1팩/원산지 별도표기) 14,990원 

  L.POINT 20%
  해물 양장피(1팩/원산지 별도표기) 15,992원

  L.POINT 50%
  갱엿 순살 닭강정(대)(1팩/원산지 별도표기) 8,995원

  L.POINT 20%
  온가족 모둠 도시락 13,592원

  L.POINT 25%
  로스트 오븐치킨(1팩/원산지 별도표기) 9,743원

  국내산 통족발(특대)(1팩/국내산) 19,800원 ※족발매장 입점점 한함

  5,000원 할인
  대용량 가득바삭파이(1팩/원산지 별도표기) 7,900원 ※베이커리팩토리/쁘띠르뽀미에 입점점 한함

  세계맥주 67종(상품별 용량 상이) 5캔 구매시 9,900원
  ※1캔 구매시 각 3,000~3,300원
  
  이달의 맥주(500ml) 5캔 구매시 10,000원 ※1캔 구매시 2,500원

  2개 이상 50%
  찹쌀/현미(각 2.5kg/국산) 2개이상 구매시 1개당 각9,995원
  ※1개 구매시 각 19,990원
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능 ※조기 품절될 수 있습니다

  미국산 고당도 오렌지(8~10입/박스/미국산) 9,990원

  L.POINT  3,000원
  컷팅 파인애플(900g/팩/필리핀산) 6,990원
  ※조기 품절될 수 있습니다

  L.POINT 5,000원
  제주 천혜향(1.5kg/박스/국산) 12,990원

  GAP 완전미 고시히카리(10kg/국산)/해보드미(20kg/국산) 29,900/49,900원

  풀무원 Soga 크고 단단한 두부(900g/원산지 별도표기) 2,990원 ※조기 품절될 수 있습니다

  2개이상 각500원 할인
  애호박(개/국산) 2개이상 구매시 1개당 각1,490원 ※1개 구매시 1,990원
  ※조기 품절될 수 있습니다

  태국 망고(개/태국산) 4개 구매시 9,960원
  ※1개 구매시 3,990원 ※조기 품절될 수 있습니다

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 인기 먹거리 최대 1+1 최대 50%

  1+1
  오뚜기 생쫄면/메밀소바(각 2인분) 각6,980원
  ※교차구매 가능

  1+1
  냉장 꼬치어묵 2종(삼진/고래사) 각9,980원
  ※동일 상품에 한함

  1+1
  치킨너겟/텐더 13종(상품별 규격 상이) 각5,980~12,980원
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  동원 리챔 오리지널/더블라이트(각 200gX3입) 각15,980원
  ※교차구매 가능

  1+1
  롯데 알뜰 소시지(500g) 3,790원
  ※기간 : 4/3(목)~4/6(일)

  1+1
  사세 치킨가라아게/사세 통새우 튀김(상품별 상이) 각9,980원 ※교차구매 가능

  1+1
  마니커 바사삭 치킨/버팔로우 닭다리 골드(상품별 상이) 10,980/11,980원 ※동일 상품에 한함

  1+1
  오뚜기 볶음밥 2종(각 450g) 각6,580원 ※교차구매 가능

  1+1
  봉지라면 6종(상품별 규격상이) 각3,320~8,800원
  ※팔도&해찬들 차돌된장찌개라면 4개입 外 5종
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  오뚜기밥 찰현미/오곡/발아흑미(각 210g×3입) 각6,480원 ※교차구매 가능

  1+1
  매일 상하 스트링 치즈(18g×10입) 12,980원

  1+1
  코카콜라/코카콜라 제로(각 1.8L) 각3,990원 ※교차구매 가능

  1+1
  빙그레 끼리 크림치즈 포션 플레인/벨큐브 스패니시 스타일(상품별 상이) 각6,380/11,180원
  ※동일 상품에 한함

  1+1
  꽃샘 밤티라미수 라떼(18g×20입) 10,980원

  1+1
  삼립 휘낭시에 2종(크림치즈/크림치즈 딸기/각 360g) 각8,480원 ※교차구매 가능

  1+1
  글로벌 수입과자 11종(상품별 상이) 각3,500~15,980원
  ※점별 입고 물량 상이 ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  블루샥 라떼/피넛 라떼(상품별 상이) ※교차구매 가능
  이디야 블렌드 아메리카노(1g×100입)
  각12,900/각25,900원

  1+1
  상온/냉장커피 음료 69종(상품별 상이) 각1,880~4,390원
  ※동일 브랜드 내 동일 가격간 교차 구매 가능

  1+1
  수입음료 26종 ※점별 입고 물량 상이 3,680/7,980원
  ※동일 가격간 교차 구매 가능

  1+1
  청우 브루느와(165g) / 청우 쫀득초코칩(240g) 4,690/5,290원 ※동일 상품에 한함
  
  2+1
  CJ 냉동볶음밥/비빔밥 9종(상품별 상이) 각6,480~6,580원
  ※동일 가격간 교차구매 가능

  2개 이상 50%
  풀무원 노엣지피자 3종(상품별 규격상이) 2개 이상 구매시 1개당 각4,990원 ※1개 구매시 각9,980원
  ※코리안 BBQ/포테이토&콘/토마토&미트콤보 ※교차구매 가능

  2개 이상 50%
  빙그레 요플레 9종(상품별 상이) 2개 이상 구매시 1개당 각1,890원 ※1개 구매시 각 3,780원 ※교차구매 가능

  2개 이상 50%
  논알콜맥주 22종(상품별 상이) 2개 이상 구매시 1개당 각690~1,650원 ※1개 구매시 각1,380~3,300원
  ※교차구매 가능

  동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  양반밥 찰진백미밥/현미밥/흑미밥(각 130g×6입) 각10,480원 ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 1+1
  동원 퀴진 크리스피 돈까스 2종(상품별 규격 상이) 각10,480원 ※치즈/통등심 ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  동원 롤 유부초밥2종/리얼크랩스 더킹오리지널(상품별상이) 각6,980원 ※교차구매 가능

  롯데웰푸드 전품목 3만원 이상 구매시 10,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  롯데 의성마늘 순한 비엔나/프랑크(상품별 상이) 각7,980/8,980 ※동일 상품에 한함

  롯데 의성마늘 빅그릴 비엔나(300 g×2) 6,980원

  사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령

  사조대림 실속김밥세트(510g) 7,980원

  1+1 사조 살코기/마일드/고추참치(각 85g×4입) 각8,980원
  ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데

  </div>
  `,
  `
  <div>
  2개 이상 50%
  한성 크런치 롤 유부초밥(240g) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 7,980원

  2개 이상 50%
  진주햄 천하장사 2종(각 448g, 오리지널/치즈) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 각 7,980원
  ※교차구매 가능

  2개 이상 50%
  동원 딤섬 4종(상품별 규격상이) 2개 이상 구매시 1개당 각5,490~5,990원
  ※새우하가우/부추창펀/샤오롱바오/부채교 ※교차구매 가능
  ※1개 구매시 각10,980~11,980원

  2개 이상 50%
  진진 꿔바로우/탕수육(상품별 규격 상이) 2개 이상 구매시 1개당 각4,950~5,450원
  ※1개 구매시 각9,900~10,900원
  ※교차구매 가능

  2개 이상 20%
  동원 자연치즈 5종(상품별 상이) 2개 이상 구매시 1개당 각4,784~5,984원 ※1개 구매시 각5,980~7,480원 ※교차구매 가능

  2개 이상 50% 
  프레지덩 쁘띠 치즈, 버터 5종(상품별 상이) 2개 이상 구매시 1개당 각3,495~6,995원 ※1개 구매시 각6,990~13,990원
  ※쁘띠 브리치즈, 무염버터 外 ※교차구매 가능

  2개 이상 50%
  화제의 레몬즙 6종(상품별 상이) 2개 이상 구매시 1개당 각4,995~7,950원 ※1개 구매시 각9,990~15,900원 ※교차구매 가능

  2개 이상 50%
  매일 헬스케어 셀렉스 9종(상품별 상이) 2개 이상 구매시 1개당 각2,990~17,500원
  ※1개 구매시 각5,980~35,000원
  ※교차구매 가능 ※점별 운영상품 상이

  2개 이상 40%
  종근당건강 락토핏 6종(상품별 상이) 2개 이상 구매시 1개당 각9,540~17,940원 
  ※1개 구매시 각15,900~29,900원
  ※교차구매 가능

  5개 이상 각2,980원 할인
  씨제이 햇반 용기 죽 7종(상품별 상이) 5개 이상 구매시 1개당 각2,000원 
  ※1개 구매시 각 4,980원 ※교차구매 가능

  요리하다 찰통순대 기획(500g×2) 5,990원

  L.POINT 2,000원
  요리하다 마르게리따 피자(354g) 3,990원

  서울 흰우유(2.3L) 5,980원

  오늘좋은 얼큰한 번데기탕(280g) 1,740원

  CJ 비비고 육즙가득 고기만두(360g×2입) 10,980원

  동원 통그릴 비엔나(1kg) 10,980원

  오리온 마켓오 브라우니 바이트 제주말차(240g)
  롯데 명가 찰떡파이 초코바나나(250g) 5,280/ 5,580원

  공구핫딜 덴마크 피자모짜렐라 쫄깃한 치즈(300g×2) 9,980원

  공구핫딜 서울우유 제로 요구르트(270ml×6입) 3,980원

  롯데마트 단독
  칠성사이다 유리병 기획(300ml×4입) 3,980원

  샘표식품 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "S"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  샘표 국산 100% 조선고추장/토장(상품별 규격 상이) 29,900/ 22,900원 ※동일상품에 한함 

  동서식품 전품목 5만원 이상 구매시 5,000원 롯데상품권 증정
  ※동서식품 치즈 제외 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "  # "를 확인하세요 ※결제시 계산대에서 수령

  2,000원 할인
  동서 맥심 모카골드 마일드/화이트골드 220입+20입 증량업 기획(상품별 상이) 각31,220원 

  CJ제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "C"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  CJ 더건강한 베이컨(90g×2입) 육공육 더얇은 슬라이스 씬(250g) 각9,980원
  ※교차구매 가능 

  1+1
  해찬들 원조 태양초 고추장(1.5kg) 18,900원

  2개 이상 각1,000원 할인
  CJ 고메 소바바 치킨 5종(상품별 상이) 2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원 ※교차구매가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수))  ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함  ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡 잡았다! 생활필수품 최대 1+1 최대 50%

  1+1
  케라시스 클린노트 샴푸/트리트먼트(각 800ml) 각12,500원 ※베이비파우더향/화이트코튼향 ※교차구매 가능

  1+1
  리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 각15,900원
  ※손상모발용/지성두피용  ※교차구매 가능

  1+1
  메디안 잇몸과학 치약(각 120g×3입, 스트롱민트/클린민트)
  메디안 잇몸과학 칫솔(4입) 각12,980원 ※교차구매 가능 

  1+1
  오랄비 초미세모 잇몸케어 칫솔(3개) 11,900원

  롯데/BC/신한/NH농협/삼성카드 1+1
  섬유유연제 최대 66종
  ※다우니/피죤/아우라/스너글/버넬 
  ※점별 운영상품 상이
  ※동일 브랜드 내 동일가격에 한해 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고 

  1+1
  변기 세정제(상품별 상이) 각1,000~8,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 브랜드, 동일가격에 한해 교차구매 가능

  1+1
  주방세제(상품별 상이) 각1,900~10,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 브랜드, 동일가격에 한해 교차구매 가능

  1+1
  도브 바디케어 전품목(비누/핸드워시/바디워시/바디로션) 각5,900~15,900원
  ※점별 운영상품 상이 ※동일 품목내 동일 가격간 교차구매 가능

  LG생활건강 행사상품 3만원 이상 구매시 
  여행용 세트 증정
  코카콜라 행사상품 5만원 이상 구매시 
  아이스 박스 증정
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령
  ※구분자 "@"를 확인하세요  ※사은품 한정수량  ※사은품 소진시 자동 행사 종료

  1+1
  엘라스틴 헤드스파&퍼퓸 샴푸/컨디셔너(각 855ml) 각13,900원
  ※로지드림/디어자스민
  ※점별 운영상품 상이 ※교차구매 가능 

  1+1
  온더바디 퍼퓸 바디워시(각 900g) 각9,900원
  ※피오니로즈/화이트머스크 
  ※점별 운영상품 상이 ※교차구매 가능 

  1+1
  페리오 알파치약 기획(185g×4입) 10,900원

  헨켈 그린 모음전 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령

  L.POINT 4,100원 퍼실 그린 기획(세탁세제 총 4.65L+섬유유연제 900ml) 23,800원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  2+1
  디펜드 언더웨어 전품목(상품별 규격 상이) 
  ※일부 품목 제외 ※동일 가격 간 교차구매 가능

  2개 이상 50%
  도브 베이비 리치모이스처 로션/워시(각 400ml) 
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2,000원 할인
  벚꽃에디션 무형광 3겹 화장지(30m×30롤) 9,900원 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  좋은느낌 생리대(상품별 상이) 
  ※팬티라이너/탐폰/일부품목 제외 ※교차구매 가능

  2개 이상 50%
  슬릭 면도기/날/쉐이빙폼 등 8종(상품별 상이) 2개 이상 구매시 1개당 각3,100~21,950원 ※1개 구매시 각6,200~43,900원
  ※점별 입점상품 상이 ※동일 품목간 교차구매 가능

  최대 50%
  주거세정제(상품별 상이) ※행사상품에 한함
  ※점별 취급품목 상이  ※동일 브랜드, 동일가격에 한해 교차구매 가능

  2개 이상 50%
  벚꽃에디션 미용티슈/키친타월/물티슈(상품별 상이) 2개 이상 구매시 1개당 각1,000~3,950원 ※1개 구매시 각2,000~7,900원 ※교차구매 가능

  2개 이상 50%
  싸이닉 엔조이 선케어 3종(선크림 200g/선에센스 80ml/선스틱 20g) 2개 이상 구매시 1개당 각6,900~8,900원 
  ※1개 구매시 각13,800~17,800원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  1매당 8.9원 초가성비 물티슈!
  코코토토 데일리 물티슈(100매×10입) 8,900원

  AHC 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정 
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "A"를 확인하세요 ※결제시 계산대에서 수령

  AHC 유스래스팅 아이크림(35ml×2입+7ml×2입)/ AHC 바이탈 골든 콜라겐 크림(50g×2입+토너 30ml) 각25,900원

  피앤지 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ㉬ "를 확인하세요 ※결제시 계산대에서 수령

  최대 50%
  다우니/페브리즈/팬틴/질레트(상품별 상이)

  하기스 행사상품 8만원 이상 구매시 10,000원 롯데상품권 증정
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)   
  ※구분자 "㉻"를 확인하세요 ※결제시 계산대에서 수령 

  2개 이상 50%
  하기스 기저귀 19종(상품별 규격 상이) 2개 이상 구매시 각15,300~22,950원 ※1개 구매시 각30,600~45,900원 ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 홈 인테리어ㆍ잡화류 최대 1+1ㆍ최대 50%

  브랜드 프라이팬/밀폐용기 최대 50%(상품별 상이) ※입점점에 한함 

  40%
  테팔 스타트이지/에브리데이쿡 11종(상품별 상이) 각18,480~35,880원

  50%
  해피콜 쿡메이트 프라이팬/궁중팬 2종(각 28cm) 각18,900원

  40,000원 할인
  쿡셀 더블 블랙 프라이팬 2P세트(20cm/28cm) 49,900원

  50%
  락앤락 익스트롱 프라이팬 6종(상품별 상이) 각15,450~18,450원

  L.POINT 50%
  글라스락 인기 밀폐 시리즈
  ※스마트/퓨어오븐/모듈러/퓨어핸디/간편캡 등

  L.POINT 50%
  스텐락 인기 밀폐 시리즈
  ※퓨어/이지오픈

  L.POINT 40%
  락앤락 인기 밀폐 시리즈
  ※ 비스프리모듈러/더클리어/클리어비스프리/ 프리저핏/스마트킵/클래식 등

  1+1 기획
  글라스락 센스 핑크 에디션 1+1기획(상품별 용량 상이) 각5,900~19,900원

  2개 이상 50%
  크린랩 이중지퍼백/뽑아쓰는 크린백(상품별 상이) 2개 이상 구매시 1개당 각3,145~7,695원
  ※1개 구매시 각6,290~15,390원
  ※교차구매 가능

  2개 이상 50%
  오늘좋은 데일리 울트라쿨 냉감패드(SS : 110×200cm/Q : 150×200cm) 2개 이상 구매시 1개당 각14,950/19,950원
  ※교차구매 가능  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  땡잡았다! 초특가을 만나보세요!
  ※매장에 더 많은 상품이 준비되어 있습니다.

  990원

  오늘좋은 데일리 무세제 클리너(3P/11×3×6cm) 990원 정상가 1,500원

  오늘좋은 베이직 바스켓 7종/클래식 바스켓 4종(상품별 상이) 990원 정상가 각1,000원~1,500원

  베이직 남성/여성 덧신/양말(각 1매) 990원 정상가 각 2,000원

  동원 뉴트리플랜 소프트뮨 강아지 습식간식 3종(각 100g) 990원 정상가 각 2,000원

  4,990원

  룸바이홈 럭셔리 호텔 세면타월(각 40×60cm, 네이비/그레이/화이트) 4,990원 정상가 각 6,990원
  ※ 입점점에 한함 ※ 해당상품에 한함 ※ 점별 재고 상이

  맥스 폭발방지 CRV 부탄가스(4입) 4,990원 정상가 6,790원

  향기가득 프리미엄 워셔액 4종(각 1.8L×2) 4,990원 정상가 각 5,900원

  1+1 기획팩 유니랩 위생장갑(200매+200매) 4,990원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외   ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  땡잡았다! 홈 인테리어ㆍ잡화류 최대 1+1ㆍ최대 50%

  50%
  풍년 마레러스터 압력솥(8인) 59,000원

  롯데/BC/신한/NH농협/삼성카드 50%
  포트메리온(상품별 용량 상이) 5,340~41,665원 ※입점점에 한함 
  ※ 점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고 

  50%
  아이리스 시스템 수납함(중형 2호)/빌드업 바스켓 3종(상품별 상이) 각1,000~9,950원 ※입점점에 한함  

  9,910원 할인
  오늘좋은 아로마 세면타월(각 4P, 유칼립투스/아쿠아/라벤더/블라썸) 각9,990원
  ※입점 점포에 한함 ※점별 재고 상이

  10,000원 할인
  트래커 브리튼 여행가방(16/20/24/28인치) 49,000~89,000원
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  30%
  오늘좋은 세탁이 편한 빨아쓰는 베개(소 : 40×60cm/대 : 50×70cm) 15,330/20,930원
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  2개 이상 30%
  브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각6,930~7,630원 ※교차구매 가능 ※1개 구매시 각9,900~10,900원
  ※뉴발란스, 아디다스, 언더아머, 네파 등 ※점별 상품 상이

  2+1
  남성 브랜드 언더웨어(상품별 상이) 6,900원
  ※브랜드 : 트라이/보디가드/베이직엘르 외  ※교차구매 가능

  땡잡았다! 초특가을 만나보세요!
  ※매장에 더 많은 상품이 준비되어 있습니다.

  7,990원

  SOUP/AD HOC 데일리진(스트레이트/테이퍼드) 7,990원 정상가 각 14,800원

  오늘좋은 쿠션/방석 10종 7,990원
  ※ 상품별 상이
  ※ 해당상품에 한함

  코코도르 차량용 미니멀 디퓨저(50ml×2) 7,990원 정상가 각 9,900원

  오늘좋은 패브릭 발매트 11종(메모리폼/워셔블 外) 7,990원 정상가 각 10,900원~12,900원

  오늘좋은 심리스 런닝/땀패드 런닝 7,990원 정상가 각 8,900~12,900원

  9,990원

  블랙 박스 카트(M, 38×29×85.5cm) 9,990원 정상가 15,900원
  ※입점점에 한함
  ※조기 품절될 수 있습니다

  에너자이저 맥스10+5입(AA/AAA) 9,990원 정상가 각 12,900원

  뉴발란스/데상트 러닝 양말(각 2매) 9,990원

  룸바이홈 줄누비 패드(멀티체크/140×190cm) 9,990원 ※ 해당상품에 한함

  대림바스 PVC 샤워호스(실버 1.5m/어반 그레이, 어반화이트 2m) 9,990원 정상가 각 13,900/14,900원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외   ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  1+1
  코렐 냉면기 4종(상품별 용량 상이) 각21,900원
  ※입점점에 한함  ※점별 재고 상이  ※교차구매 가능

  1+1
  유니랩 셰프장갑(소/중/대)/원데이 일회용 수세미(핑크/그린) 각4,990원
  ※동일 상품에 한함

  1+1
  크린랩 크린장갑(200매)/이중지퍼백(20매,소/중/대)/랩(30X50cm) 각3,190~9,090원

  1+1 기획팩
  크린랩 베이킹소다 세정티슈/크린랩 찌든때용 세정티슈(50매+50매) 4,390원 ※입점점에 한함 ※점별 재고 상이

  2+1
  불스원샷 70000(기획상품) 32,000원
  ※입점 점포에 한함  ※행사 상품에 한함

  2개 이상 20%
  스크럽 대디/마미 수세미 마이크로파이버타올 2개 이상 구매시 1개당 각4,392~6,392원 
  ※1개 구매시 각 5,490~7,990원 ※교차구매 가능

  2개 이상 20%
  오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 각3,920원  ※1개 구매시 4,900원

  40%
  테팔 인텐시움 통 3중 냄비 3종(상품별상이) 26,280~37,680원

  30%
  코렐 목련/더블링 그린티 外 5,530~15,330원

  30%
  오늘좋은 데일리 피치스킨 이불(S/Q) 16,030/20,930원
  ※S : 150×200cm/Q : 180×220cm

  30%
  오늘좋은 패브릭 리빙박스 4종(40L/66L/80L/120L) 4,130~13,930원 

  최대 40%
  세차도구 전품목(상품별 규격상이)
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  20%
  러닝용품 전품목 8,720~39,920원 ※입점점에 한함 
  ※브랜드 : 나이키/아디다스/언더아머 外

  최대 9,000원 할인
  브랜드 와인잔 2P (슈피겔라우/쇼트즈위젤 外) 각9,900원

  3M 울트라클린 크린스틱 스타터팩/리필(핸들1입+리필5입/리필10입) 7,900/6,900원
  ※입점점에 한함 ※점별 재고상이

  꽃 피는 다육 포트 3종기획 각9,990원 ※입점점에 한함

  르젠 써큘레이터형 스탠드 선풍기(45W) 39,900원

  브랜드 반팔&반바지 19,900~29,900원
  ※입점점에 한함 ※브랜드 : 휠라/푸마/르까프 外

  여성 브랜드 언더웨어 브라/팬티 4,900/ 9,900원
  ※입점점에 한함 ※브랜드 : 비비안/비너스/오테나 外

  쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
  ※기간 3/20(목)~4/23(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령

  최대 20%
  쿡셀 프라이팬/냄비 18종(상품별 상이) 31,120~49,900원

  에너자이저 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
  ※기간 3/27(목)~4/9(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "㉧"를 확인하세요 ※결제시 계산대에서 수령

  10%
  에너자이저 맥스플러스 4입 8,370원

  ToysRus 인기완구추천!
  ※ 기간 : 3/27(목)~4/9(수)  ※ 매장에 더 많은 상품이 준비되어 있습니다  ※입점점에 한함

  L.POINT  30%
  마블 14인치 피규어세트 아이언맨/스파이더맨(6세 이상/49×11.2×45cm) 55,930원

  롯데/BC/신한/NH농협/삼성카드 10,000원 할인
  피아트 500e 푸쉬카(102×56×97cm) 89,000원
  ※점별 재고수량 상이
  ※페이지 하단 카드할인 세부내용 참고

  colioli 득템위크 반려동물식품&용품 500여종 할인! 개이득! 냥이득!
  반려동물식품/용품 3만원 이상 구매시 5천원 롯데상품권 증정!
  ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※기간 : 3/27(목)~4/9(수) 
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ♠ "를 확인하세요
  ※페이지 하단 카드할인 세부내용 참고

  롯데/BC/신한/NH농협/삼성카드 40%
  콜리올리 순살가득 스틱/슬라이스 4종(각 300g, 닭고기/오리고기) 7,140원
  ※입점점에 한함  ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 40%
  콜리올리 깔끔하게 사용하는 1회용 패드(M 100매/M 200매/L 40매) 각7,140~14,340원
  ※입점점에 한함  ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 30%
  피랩 마음놓고먹어 강아지 간식 5종(각 1kg) 각11,130~16,730원 
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  30%
  템테이션 8종(상품별 상이) 3,010원 ※입점점에 한함

  롯데마트 단 독
  5만원 구매 시 5천원 즉시할인
  ▶기간 : 3/27(목) ~ 4/9(수)
  ※최종 결제 금액에서 5천원 차감(탑텐 영수증 확인 필수)
  ※탑텐, 탑텐키즈, 탑텐밸런스 타 할인 쿠폰 행사와 중복 적용 불가

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
  ·전단적용기간 : 2025. 4. 3(목) ~ 2025. 4. 9(수) < 비신선식품 적용기간 : 2025. 3. 27(목) ~ 4. 9(수) >
  ·제타플렉스 서울역점

  온국민이 땡잡는 날, 땡큐절 3.27 ~ 4.9

  롯데 안에서 쓰면 쓸수록 더 커지는 혜택!
  롯데멤버스 카드 출시기념!
  롯데마트 월 최대 20,000원 할인
  롯데슈퍼 월 최대 16,000원 할인
  ※GO APP 쿠폰으로 발급 ※자세한 내용은 롯데마트GO APP 및 롯데카드 홈페이지 참고

  일주일 내내 땡잡았다!
  롯데/BC/신한/NH농협/삼성카드 초특가 2탄 
  기 간 : 4/3(목) ~ 4/9(수)
  ※L.POINT 회원 해당카드 최종 결제 금액 기준입니다
  ※페이지 하단 카드할인 세부내용 참고

  척아이롤/부채살 연중 최저가 도전!
  ※연중 기간 : 24년 4월~25년 2월

  미국산 소고기 전품목 50%

  TV CF 방영상품 척아이롤 1,390원
  할인 전 가격 2,780원 (100g/냉장/미국산 소고기)

  미국산 부채살 1,590원
  할인 전 가격 3,180원 (100g/냉장/미국산 소고기)

  12Brix 이상 당도 선별 완료
  TV CF 방영상품 농가돕기 사과 2kg 대용량 9,990원
  할인 전 가격 14,990원 (2kg/봉/국산)

  단, 4일간!
  TV CF 방영상품 돼지고기 냉장 삼겹살/목심 반값! (각 100g/냉장/수입산)
  ※기간 : 4/3(목) ~ 4/6(일)
  ※조기 품절될 수 있습니다

  TV CF 방영상품 한 판 전복 10마리 반값! 9,950원
  할인 전 가격 19,900원 (10마리/냉장/국산)

  4/5 토, 4/6 일
  TV CF 방영상품
  봉지라면 전품목 3개 구매시 9,900원(상품별 규격 상이)
  ※브랜드 관계없이 교차구매 가능
  ※1인 1일 1회 9개 한정
  ※정상가 3,300원 미만,10개입/20개입 박스 상품 제외
  ※해당상품에 한함
  
  땡잡았다! 롯또 혜택! 
  ▶ 기 간 : 3/27(목)~4/9(수)
  ※이벤트 세부사항은 QR코드 스캔 후 이벤트 페이지에서 확인하세요

  EVENT 1
  매일 오전 10시~오후 1시 최대 10,000원 롯또쿠폰 증정!
  7만원 이상 구매시 10%할인 최대 1만원 한도

  EVENT 2
  총 1천명
  3개 채널에서 각 1만원 이상 구매시 최대 10,000점 스노우플랜 추첨 증정!

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한
  </div>
  `,
  `
  <div>
  땡잡았다! 오늘만 이 가격! 하루특가!

  4/3 목
  1+1
  수제 모둠 소시지(1kg/팩/원산지 별도표기) 12,980원

  4/4 금
  L.POINT 50%
  손질 주꾸미&낙지(500g/팩/냉동/원산지 별도표기) 9,900원

  대용량 상생 표고버섯(400g/봉/국산) 4,990원

  4/5 토 
  L.POINT 4,000원
  오픈런 필수템! 전점 1만봉 한정
  성주 꼬마참외(1kg/봉/국산) 5,990원 ※1인 2봉 구매한정

  4/6 일
  2개 이상 각1,000원 할인
  뉴질랜드 단호박 벌크(개/뉴질랜드산) 2개 이상 구매시 1개당 각1,990원 ※1개 구매시 2,990원

  롯데/BC/신한/NH농협/삼성카드 2+1
  스낵(상품별 상이) 890~11,900원
  ※브랜드 관계없이 교차구매 가능
  ※3개 구매시 34% 할인 ※3의 배수로 적용
  ※L.POINT 회원 해당카드 결제시 적용
  ※공구핫딜/기획상품 제외
  ※일부품목 제외 ※1인 1회 최대 9개 한정
  ※페이지 하단 카드할인 세부내용 참고 

  땡잡았다! 단,4일간 주말특가! ※기간 : 4/3(목) ~ 4/6(일)

  롯데/BC/신한/NH농협/삼성카드 1+1
  동원 퀴진 감자튀김 3종(상품별 규격 상이) 각7,980~8,980원
  ※케이준 양념감자/해쉬브라운 스틱/클링클컷
  ※동일 가격간 교차구매 가능

  1+1
  CJ 고메 함박스테이크/치즈 함박 스테이크(상품별 상이) 각9,980원 ※교차구매 가능

  1+1
  핸드워시 용기/리필(상품별 상이)
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  페이셜클렌징 인기 브랜드 70종
  ※VT/식물나라/뉴트로지나/해피바스/온더바디 등
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  마스크팩 인기 브랜드 130종
  ※메디힐/VT/린제이/JM솔루션/메디앤서/바노바기 외
  ※일부품목 제외 ※기간 : 4/5(토)~4/6(일)
  ※동일 품목 내 동일 가격 간 교차구매 가능

  2개 이상 50%
  비요뜨 4종(상품별 상이) 2개 이상 구매시 1개당 각1,440원 ※1개 구매시 각2,880원
  ※링크/초코링/크런치볼/쿠키앤크림 ※교차구매 가능

  2개 이상 50%
  서울우유 트리플 슈레드 치즈(1kg) 2개 이상 구매시 1개당 각8,990원 ※1개 구매시 17,980원 ※전점 15,000개 한정

  2개 이상 50%
  그릭데이 4종(상품별 상이) 2개 이상 구매시 1개당 각1,900~7,000원 ※1개 구매시 각3,800~14,000원
  ※라이트/시그니처 ※교차구매 가능

  2개 이상 50%
  롯데칠성 음료 브랜드 대전(상품별 상이)
  ※PB 및 일부 상품 제외 ※교차구매 가능

  전통 증류주 2병 이상 10%
  증류식 전통소주(상품별 상이)
  ※일부 품목 제외(처분상품 등) ※교차구매 가능

  2개 이상 50%
  깨끗한나라 브랜드 37종(상품별 상이) ※교차구매 가능

  최대 30% + 10% 추가할인
  화장품 샵 베스트 어워즈
  ※참여 브랜드 : LG생활건강/아모레퍼시픽/이니스프리
  ※아이오페 레티놀/슈퍼바이탈/수려한 수분샘크림/이니스프리 그린티 외 11품목
  ※당일영수증에 한함 ※점별 입점점에 한함
  ※브랜드별 할인상품 상이 ※기간 : 4/5(토)~4/6(일)

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데
  </div>
  `,
  `
  <div>
  2,000원 요리하다 냉동밥 전품목(상품별 규격 상이) ※1입 상품 한정

  1+1
  하코야 닭꼬치 전품목(상품별 규격 상이)
  ※동일가격 간 교차구매 가능

  1+1
  냉동 핫도그 전품목(상품별 규격 상이)
  ※PB상품 제외 ※기간 : 4/3(목)~4/6(일)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  바릴라 파스타소스/면 전품목(상품별 규격 상이)
  ※동일 가격 상품 간 교차구매 가능

  1+1
  포도씨유/카놀라유/해바라기유 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  스테비아/알룰로스 전품목(상품별 규격 상이) ※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  참치액/코인조미료 전품목(상품별 규격 상이)※일부품목 제외
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  16입 두유 전품목(각 190ml×16입)
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  콤부차 30입 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※콤부차 기획 상품 제외
  ※동일 브랜드별 교차구매 가능

  땡잡았다! 인기 카테고리 전품목 세일! ※기간 : 3/27(목) ~ 4/9(수) ※품목별 기간 상이

  1+1
  아이스티 전품목(상품별 규격 상이)
  ※점포별 입고상품 상이 ※아이스티 기획 상품 제외
  ※동일 브랜드 내 동일가격(규격) 간 교차구매 가능

  1+1
  섬유탈취제 용기 전품목(상품별 규격 상이)
  ※일부품목 제외 ※기간 : 4/3(목)~4/6(일)
  ※페브리즈 행사카드 구매시 적용(롯데/BC/신한/NH농협/삼성카드)
  ※동일 브랜드 내 동일가격 간 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  하겐다즈 파인트 전품목(상품별 규격 상이) ※교차구매 가능

  2+1
  카누 캡슐 커피 전품목(상품별 규격 상이)
  ※시즌 캡슐/캡슐기획 제외 ※동일 호환 기기별 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개 이상 50%
  냉동 물만두/군만두 전품목(상품별 규격 상이)
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  아기치즈 전품목(상품별 규격 상이)
  ※동일 브랜드 간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2개 이상 50%
  프로쉬 식기세척기 세제 전품목
  (상품별 규격 상이) ※기간 : 4/3(목)~4/6(일) ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  크리넥스 마스크 전품목(상품별 규격 상이)
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능

  2개 이상 50%
  도루코 주방용품 전품목
  (상품별 규격 상이)※일부품목 제외
  ※기간 : 4/4(금)~4/6(일) ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 롯데마트 추천! 매일 신선 먹거리!

  와고메 와규 전품목 전품목 반값

  롯데/BC/신한/NH농협/삼성카드 50%
  호주 와규 협회 주관 컨테스트 수상브랜드로만 선별!(품목별 상이/냉장, 냉동)
  ※호주산 소고기 ※페이지 하단 카드할인 세부내용 참고

  초특가 4일장 ※기간 : 4/3(목) ~ 4/6(일)

  2개 이상 각2,000원 할인
  돼지고기 귀한부위 항정살/갈매기살/등심덧살(각 300g/냉동/국내산 돼지고기)
  ※1개 구매시 각 11,900원 ※교차구매 가능
  ※조기 품절될 수 있습니다 ※제주점 제외

  전점 2만팩 한정 서두르세요
  뒷다리 불고기 두근팩(1.2kg/냉장/국내산 돼지고기) 9,900원
  ※조기 품절될 수 있습니다
  ※제주점 제외

  1+1
  요리하다 1등급 한우 소불고기(300g/냉장/원산지 별도표기) 14,980원

  L.POINT 57%
  훈제오리 슬라이스(400g/냉장/원산지 별도표기) 5,990원

  연어는 롯데마트 반값 SUPER 세일 
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 50%
  연중 최저가 도전! 사전계약 물량 대방출! 연중 기간 : 24년 1월 ~25년 2월
  SUPER 생연어(횟감용/구이용)(각 100g/냉장/노르웨이산) 2,990/2,890원

  L.POINT 40%
  SUPER 생연어초밥(16입/냉장/연어:노르웨이산) 11,700원

  L.POINT 5,000원
  일식 끝판왕 장호준 셰프와 함께한 프리미엄 숙성 연어회
  장호준 셰프의 네기 연어회(120g/냉장/연어:노르웨이산) 9,900원

  L.POINT 10%
  손질오징어(3마리/해동/원양산) 9,810원

  한가득 드리는 가성비 채소 5종 추천
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  한가득 감자/고구마(각 2kg/봉/국산) 6,990/5,990원

  한가득 장아찌용 청양고추(400g/봉/국산) 3,990원

  한가득 모둠쌈(300g/팩/국산) 2,990원

  한가득 1mm 양배추 샐러드 2종(각 400g/봉/국산) 각2,990원

  롯데/BC/신한/NH농협/삼성카드 4,000원 할인
  최강록 나야시리즈 전품목(품목별 규격 상이/냉장/미국산)
  ※페이지 하단 카드할인 세부내용 참고

  L.POINT 30%
  무항생제 한우 전품목(1+등급)(각 100g/냉장/국내산 한우고기)

  국내산 닭가슴살 원료육 사용
  닭가슴살 6종 골라담기(상품별 규격 상이/냉장/원산지 별도표기) 오픈런! 닭가슴살 990원!
  ※허브/페퍼/오리지널/대파크림/숯불구이/스윗불닭맛
  ※한정수량 전점 200,000개
  ※조기 품절될 수 있습니다

  L.POINT  각4,000원 할인
  스페인산 냉동 대패류, 한입 삼겹살/목심(품목별 상이/냉동/스페인산 돼지고기)

  L.POINT 50%
  데친 홍게(1마리/냉장/국산) 4,495원

  1+1
  라면부터 파인다이닝까지! 만능 요리재료를 한봉 더!
  해물모둠(500g/냉동/팩/원산지 별도표기) 15,900원

  1+1
  동원 양반 김부각 6종/CJ 비비고칩 4종(상품별 용랑 상이/원산지 별도표기 ) 각4,470/3,720원 ※브랜드별 교차구매 가능

  32봉 특별기획
  동원 양반 들기름김 기획팩(4g×16봉×2입/원산지 별도표기) 9,980원

  ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
  ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다
  </div>
  `,
  `
  <div>
  롯데마트 차별화 과일 추천
  ※조기 품절될 수 있습니다 ※이미지 연출컷입니다

  L.POINT 3,000원
  AI로 선별한 성주참외(3~6입/봉/국산) 9,990원 ※기간 : 4/3(목) ~ 4/6(일)

  해발 750m 이상 고산지 재배! 쫀득한 식감! 오직 롯데에서만!
  2송이 이상 각1,000원 할인
  B750 바나나(송이/베트남산) 2송이이상 구매시 1송이당 각1,990원
  ※1송이 구매시 2,990원
  ※기간 : 4/3(목)~4/6(일)

  스마트팜 딸기(500g/팩/국산) L.POINT 3,000원 할인 ※해당상품 포함 딸기 전품목 L.POINT 3천원 할인

  L.POINT 3,000원 
  스마트팜 방울토마토(스위텔/스윗탱고/스윗허니)(상품별 중량 상이/국산) 7,990원
  ※점별 운영상품 상이

  L.POINT 4,000원 
  스테비아 방울토마토 전품목(상품별 규격 상이/국산) 5,990원

  온가족 나들이 즉석 먹거리 추천
  초밥 30입 반값
  L.POINT 50%
  반값 득템 초밥(30입)(1팩/원산지 별도표기) 14,990원 

  L.POINT 20%
  해물 양장피(1팩/원산지 별도표기) 15,992원

  L.POINT 50%
  갱엿 순살 닭강정(대)(1팩/원산지 별도표기) 8,995원

  L.POINT 20%
  온가족 모둠 도시락 13,592원

  L.POINT 25%
  로스트 오븐치킨(1팩/원산지 별도표기) 9,743원

  국내산 통족발(특대)(1팩/국내산) 19,800원 ※족발매장 입점점 한함

  5,000원 할인
  대용량 가득바삭파이(1팩/원산지 별도표기) 7,900원 ※베이커리팩토리/쁘띠르뽀미에 입점점 한함

  세계맥주 67종(상품별 용량 상이) 5캔 구매시 9,900원
  ※1캔 구매시 각 3,000~3,300원
  
  이달의 맥주(500ml) 5캔 구매시 10,000원 ※1캔 구매시 2,500원

  2개 이상 50%
  찹쌀/현미(각 2.5kg/국산) 2개이상 구매시 1개당 각9,995원
  ※1개 구매시 각 19,990원
  ※기간 : 4/3(목)~4/6(일) ※교차구매 가능 ※조기 품절될 수 있습니다

  미국산 고당도 오렌지(8~10입/박스/미국산) 9,990원

  L.POINT  3,000원
  컷팅 파인애플(900g/팩/필리핀산) 6,990원
  ※조기 품절될 수 있습니다

  L.POINT 5,000원
  제주 천혜향(1.5kg/박스/국산) 12,990원

  GAP 완전미 고시히카리(10kg/국산)/해보드미(20kg/국산) 29,900/49,900원

  풀무원 Soga 크고 단단한 두부(900g/원산지 별도표기) 2,990원 ※조기 품절될 수 있습니다

  2개이상 각500원 할인
  애호박(개/국산) 2개이상 구매시 1개당 각1,490원 ※1개 구매시 1,990원
  ※조기 품절될 수 있습니다

  태국 망고(개/태국산) 4개 구매시 9,960원
  ※1개 구매시 3,990원 ※조기 품절될 수 있습니다

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 인기 먹거리 최대 1+1 최대 50%

  1+1
  오뚜기 생쫄면/메밀소바(각 2인분) 각6,980원
  ※교차구매 가능

  1+1
  냉장 꼬치어묵 2종(삼진/고래사) 각9,980원
  ※동일 상품에 한함

  1+1
  치킨너겟/텐더 13종(상품별 규격 상이) 각5,980~12,980원
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  동원 리챔 오리지널/더블라이트(각 200gX3입) 각15,980원
  ※교차구매 가능

  1+1
  롯데 알뜰 소시지(500g) 3,790원
  ※기간 : 4/3(목)~4/6(일)

  1+1
  사세 치킨가라아게/사세 통새우 튀김(상품별 상이) 각9,980원 ※교차구매 가능

  1+1
  마니커 바사삭 치킨/버팔로우 닭다리 골드(상품별 상이) 10,980/11,980원 ※동일 상품에 한함

  1+1
  오뚜기 볶음밥 2종(각 450g) 각6,580원 ※교차구매 가능

  1+1
  봉지라면 6종(상품별 규격상이) 각3,320~8,800원
  ※팔도&해찬들 차돌된장찌개라면 4개입 外 5종
  ※동일 브랜드 내 동일가격 간 교차구매 가능

  1+1
  오뚜기밥 찰현미/오곡/발아흑미(각 210g×3입) 각6,480원 ※교차구매 가능

  1+1
  매일 상하 스트링 치즈(18g×10입) 12,980원

  1+1
  코카콜라/코카콜라 제로(각 1.8L) 각3,990원 ※교차구매 가능

  1+1
  빙그레 끼리 크림치즈 포션 플레인/벨큐브 스패니시 스타일(상품별 상이) 각6,380/11,180원
  ※동일 상품에 한함

  1+1
  꽃샘 밤티라미수 라떼(18g×20입) 10,980원

  1+1
  삼립 휘낭시에 2종(크림치즈/크림치즈 딸기/각 360g) 각8,480원 ※교차구매 가능

  1+1
  글로벌 수입과자 11종(상품별 상이) 각3,500~15,980원
  ※점별 입고 물량 상이 ※동일 브랜드 내 동일 가격 간 교차구매 가능

  1+1
  블루샥 라떼/피넛 라떼(상품별 상이) ※교차구매 가능
  이디야 블렌드 아메리카노(1g×100입)
  각12,900/각25,900원

  1+1
  상온/냉장커피 음료 69종(상품별 상이) 각1,880~4,390원
  ※동일 브랜드 내 동일 가격간 교차 구매 가능

  1+1
  수입음료 26종 ※점별 입고 물량 상이 3,680/7,980원
  ※동일 가격간 교차 구매 가능

  1+1
  청우 브루느와(165g) / 청우 쫀득초코칩(240g) 4,690/5,290원 ※동일 상품에 한함
  
  2+1
  CJ 냉동볶음밥/비빔밥 9종(상품별 상이) 각6,480~6,580원
  ※동일 가격간 교차구매 가능

  2개 이상 50%
  풀무원 노엣지피자 3종(상품별 규격상이) 2개 이상 구매시 1개당 각4,990원 ※1개 구매시 각9,980원
  ※코리안 BBQ/포테이토&콘/토마토&미트콤보 ※교차구매 가능

  2개 이상 50%
  빙그레 요플레 9종(상품별 상이) 2개 이상 구매시 1개당 각1,890원 ※1개 구매시 각 3,780원 ※교차구매 가능

  2개 이상 50%
  논알콜맥주 22종(상품별 상이) 2개 이상 구매시 1개당 각690~1,650원 ※1개 구매시 각1,380~3,300원
  ※교차구매 가능

  동원에프앤비 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  양반밥 찰진백미밥/현미밥/흑미밥(각 130g×6입) 각10,480원 ※교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 1+1
  동원 퀴진 크리스피 돈까스 2종(상품별 규격 상이) 각10,480원 ※치즈/통등심 ※교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고

  1+1
  동원 롤 유부초밥2종/리얼크랩스 더킹오리지널(상품별상이) 각6,980원 ※교차구매 가능

  롯데웰푸드 전품목 3만원 이상 구매시 10,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉣"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  롯데 의성마늘 순한 비엔나/프랑크(상품별 상이) 각7,980/8,980 ※동일 상품에 한함

  롯데 의성마늘 빅그릴 비엔나(300 g×2) 6,980원

  사조대림 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
  ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령

  사조대림 실속김밥세트(510g) 7,980원

  1+1 사조 살코기/마일드/고추참치(각 85g×4입) 각8,980원
  ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데

  </div>
  `,
  `
  <div>
  2개 이상 50%
  한성 크런치 롤 유부초밥(240g) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 7,980원

  2개 이상 50%
  진주햄 천하장사 2종(각 448g, 오리지널/치즈) 2개 이상 구매시 1개당 각3,990원 ※1개 구매시 각 7,980원
  ※교차구매 가능

  2개 이상 50%
  동원 딤섬 4종(상품별 규격상이) 2개 이상 구매시 1개당 각5,490~5,990원
  ※새우하가우/부추창펀/샤오롱바오/부채교 ※교차구매 가능
  ※1개 구매시 각10,980~11,980원

  2개 이상 50%
  진진 꿔바로우/탕수육(상품별 규격 상이) 2개 이상 구매시 1개당 각4,950~5,450원
  ※1개 구매시 각9,900~10,900원
  ※교차구매 가능

  2개 이상 20%
  동원 자연치즈 5종(상품별 상이) 2개 이상 구매시 1개당 각4,784~5,984원 ※1개 구매시 각5,980~7,480원 ※교차구매 가능

  2개 이상 50% 
  프레지덩 쁘띠 치즈, 버터 5종(상품별 상이) 2개 이상 구매시 1개당 각3,495~6,995원 ※1개 구매시 각6,990~13,990원
  ※쁘띠 브리치즈, 무염버터 外 ※교차구매 가능

  2개 이상 50%
  화제의 레몬즙 6종(상품별 상이) 2개 이상 구매시 1개당 각4,995~7,950원 ※1개 구매시 각9,990~15,900원 ※교차구매 가능

  2개 이상 50%
  매일 헬스케어 셀렉스 9종(상품별 상이) 2개 이상 구매시 1개당 각2,990~17,500원
  ※1개 구매시 각5,980~35,000원
  ※교차구매 가능 ※점별 운영상품 상이

  2개 이상 40%
  종근당건강 락토핏 6종(상품별 상이) 2개 이상 구매시 1개당 각9,540~17,940원 
  ※1개 구매시 각15,900~29,900원
  ※교차구매 가능

  5개 이상 각2,980원 할인
  씨제이 햇반 용기 죽 7종(상품별 상이) 5개 이상 구매시 1개당 각2,000원 
  ※1개 구매시 각 4,980원 ※교차구매 가능

  요리하다 찰통순대 기획(500g×2) 5,990원

  L.POINT 2,000원
  요리하다 마르게리따 피자(354g) 3,990원

  서울 흰우유(2.3L) 5,980원

  오늘좋은 얼큰한 번데기탕(280g) 1,740원

  CJ 비비고 육즙가득 고기만두(360g×2입) 10,980원

  동원 통그릴 비엔나(1kg) 10,980원

  오리온 마켓오 브라우니 바이트 제주말차(240g)
  롯데 명가 찰떡파이 초코바나나(250g) 5,280/ 5,580원

  공구핫딜 덴마크 피자모짜렐라 쫄깃한 치즈(300g×2) 9,980원

  공구핫딜 서울우유 제로 요구르트(270ml×6입) 3,980원

  롯데마트 단독
  칠성사이다 유리병 기획(300ml×4입) 3,980원

  샘표식품 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "S"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  샘표 국산 100% 조선고추장/토장(상품별 규격 상이) 29,900/ 22,900원 ※동일상품에 한함 

  동서식품 전품목 5만원 이상 구매시 5,000원 롯데상품권 증정
  ※동서식품 치즈 제외 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "  # "를 확인하세요 ※결제시 계산대에서 수령

  2,000원 할인
  동서 맥심 모카골드 마일드/화이트골드 220입+20입 증량업 기획(상품별 상이) 각31,220원 

  CJ제일제당 전품목 4만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 "C"를 확인하세요 ※결제시 계산대에서 수령

  1+1
  CJ 더건강한 베이컨(90g×2입) 육공육 더얇은 슬라이스 씬(250g) 각9,980원
  ※교차구매 가능 

  1+1
  해찬들 원조 태양초 고추장(1.5kg) 18,900원

  2개 이상 각1,000원 할인
  CJ 고메 소바바 치킨 5종(상품별 상이) 2개 이상 구매시 1개당 각7,980원 ※1개 구매시 각8,980원 ※교차구매가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수))  ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함  ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡 잡았다! 생활필수품 최대 1+1 최대 50%

  1+1
  케라시스 클린노트 샴푸/트리트먼트(각 800ml) 각12,500원 ※베이비파우더향/화이트코튼향 ※교차구매 가능

  1+1
  리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 각15,900원
  ※손상모발용/지성두피용  ※교차구매 가능

  1+1
  메디안 잇몸과학 치약(각 120g×3입, 스트롱민트/클린민트)
  메디안 잇몸과학 칫솔(4입) 각12,980원 ※교차구매 가능 

  1+1
  오랄비 초미세모 잇몸케어 칫솔(3개) 11,900원

  롯데/BC/신한/NH농협/삼성카드 1+1
  섬유유연제 최대 66종
  ※다우니/피죤/아우라/스너글/버넬 
  ※점별 운영상품 상이
  ※동일 브랜드 내 동일가격에 한해 교차구매 가능
  ※페이지 하단 카드할인 세부내용 참고 

  1+1
  변기 세정제(상품별 상이) 각1,000~8,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 브랜드, 동일가격에 한해 교차구매 가능

  1+1
  주방세제(상품별 상이) 각1,900~10,900원 ※점별 취급품목 상이
  ※일부품목 제외 ※동일 브랜드, 동일가격에 한해 교차구매 가능

  1+1
  도브 바디케어 전품목(비누/핸드워시/바디워시/바디로션) 각5,900~15,900원
  ※점별 운영상품 상이 ※동일 품목내 동일 가격간 교차구매 가능

  LG생활건강 행사상품 3만원 이상 구매시 
  여행용 세트 증정
  코카콜라 행사상품 5만원 이상 구매시 
  아이스 박스 증정
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)  ※구매 영수증 지참 후 '도와드립니다' 센터에서 수령
  ※구분자 "@"를 확인하세요  ※사은품 한정수량  ※사은품 소진시 자동 행사 종료

  1+1
  엘라스틴 헤드스파&퍼퓸 샴푸/컨디셔너(각 855ml) 각13,900원
  ※로지드림/디어자스민
  ※점별 운영상품 상이 ※교차구매 가능 

  1+1
  온더바디 퍼퓸 바디워시(각 900g) 각9,900원
  ※피오니로즈/화이트머스크 
  ※점별 운영상품 상이 ※교차구매 가능 

  1+1
  페리오 알파치약 기획(185g×4입) 10,900원

  헨켈 그린 모음전 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "㉭"를 확인하세요 ※결제시 계산대에서 수령

  L.POINT 4,100원 퍼실 그린 기획(세탁세제 총 4.65L+섬유유연제 900ml) 23,800원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
  2+1
  디펜드 언더웨어 전품목(상품별 규격 상이) 
  ※일부 품목 제외 ※동일 가격 간 교차구매 가능

  2개 이상 50%
  도브 베이비 리치모이스처 로션/워시(각 400ml) 
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  롯데/BC/신한/NH농협/삼성카드 2,000원 할인
  벚꽃에디션 무형광 3겹 화장지(30m×30롤) 9,900원 ※페이지 하단 카드할인 세부내용 참고

  2개 이상 50%
  좋은느낌 생리대(상품별 상이) 
  ※팬티라이너/탐폰/일부품목 제외 ※교차구매 가능

  2개 이상 50%
  슬릭 면도기/날/쉐이빙폼 등 8종(상품별 상이) 2개 이상 구매시 1개당 각3,100~21,950원 ※1개 구매시 각6,200~43,900원
  ※점별 입점상품 상이 ※동일 품목간 교차구매 가능

  최대 50%
  주거세정제(상품별 상이) ※행사상품에 한함
  ※점별 취급품목 상이  ※동일 브랜드, 동일가격에 한해 교차구매 가능

  2개 이상 50%
  벚꽃에디션 미용티슈/키친타월/물티슈(상품별 상이) 2개 이상 구매시 1개당 각1,000~3,950원 ※1개 구매시 각2,000~7,900원 ※교차구매 가능

  2개 이상 50%
  싸이닉 엔조이 선케어 3종(선크림 200g/선에센스 80ml/선스틱 20g) 2개 이상 구매시 1개당 각6,900~8,900원 
  ※1개 구매시 각13,800~17,800원
  ※점별 운영상품 상이 ※동일 품목간 교차구매 가능

  1매당 8.9원 초가성비 물티슈!
  코코토토 데일리 물티슈(100매×10입) 8,900원

  AHC 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정 
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "A"를 확인하세요 ※결제시 계산대에서 수령

  AHC 유스래스팅 아이크림(35ml×2입+7ml×2입)/ AHC 바이탈 골든 콜라겐 크림(50g×2입+토너 30ml) 각25,900원

  피앤지 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ㉬ "를 확인하세요 ※결제시 계산대에서 수령

  최대 50%
  다우니/페브리즈/팬틴/질레트(상품별 상이)

  하기스 행사상품 8만원 이상 구매시 10,000원 롯데상품권 증정
  ※ 당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)   
  ※구분자 "㉻"를 확인하세요 ※결제시 계산대에서 수령 

  2개 이상 50%
  하기스 기저귀 19종(상품별 규격 상이) 2개 이상 구매시 각15,300~22,950원 ※1개 구매시 각30,600~45,900원 ※교차구매 가능

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  땡잡았다! 홈 인테리어ㆍ잡화류 최대 1+1ㆍ최대 50%

  브랜드 프라이팬/밀폐용기 최대 50%(상품별 상이) ※입점점에 한함 

  40%
  테팔 스타트이지/에브리데이쿡 11종(상품별 상이) 각18,480~35,880원

  50%
  해피콜 쿡메이트 프라이팬/궁중팬 2종(각 28cm) 각18,900원

  40,000원 할인
  쿡셀 더블 블랙 프라이팬 2P세트(20cm/28cm) 49,900원

  50%
  락앤락 익스트롱 프라이팬 6종(상품별 상이) 각15,450~18,450원

  L.POINT 50%
  글라스락 인기 밀폐 시리즈
  ※스마트/퓨어오븐/모듈러/퓨어핸디/간편캡 등

  L.POINT 50%
  스텐락 인기 밀폐 시리즈
  ※퓨어/이지오픈

  L.POINT 40%
  락앤락 인기 밀폐 시리즈
  ※ 비스프리모듈러/더클리어/클리어비스프리/ 프리저핏/스마트킵/클래식 등

  1+1 기획
  글라스락 센스 핑크 에디션 1+1기획(상품별 용량 상이) 각5,900~19,900원

  2개 이상 50%
  크린랩 이중지퍼백/뽑아쓰는 크린백(상품별 상이) 2개 이상 구매시 1개당 각3,145~7,695원
  ※1개 구매시 각6,290~15,390원
  ※교차구매 가능

  2개 이상 50%
  오늘좋은 데일리 울트라쿨 냉감패드(SS : 110×200cm/Q : 150×200cm) 2개 이상 구매시 1개당 각14,950/19,950원
  ※교차구매 가능  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  땡잡았다! 초특가을 만나보세요!
  ※매장에 더 많은 상품이 준비되어 있습니다.

  990원

  오늘좋은 데일리 무세제 클리너(3P/11×3×6cm) 990원 정상가 1,500원

  오늘좋은 베이직 바스켓 7종/클래식 바스켓 4종(상품별 상이) 990원 정상가 각1,000원~1,500원

  베이직 남성/여성 덧신/양말(각 1매) 990원 정상가 각 2,000원

  동원 뉴트리플랜 소프트뮨 강아지 습식간식 3종(각 100g) 990원 정상가 각 2,000원

  4,990원

  룸바이홈 럭셔리 호텔 세면타월(각 40×60cm, 네이비/그레이/화이트) 4,990원 정상가 각 6,990원
  ※ 입점점에 한함 ※ 해당상품에 한함 ※ 점별 재고 상이

  맥스 폭발방지 CRV 부탄가스(4입) 4,990원 정상가 6,790원

  향기가득 프리미엄 워셔액 4종(각 1.8L×2) 4,990원 정상가 각 5,900원

  1+1 기획팩 유니랩 위생장갑(200매+200매) 4,990원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외   ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
  땡잡았다! 홈 인테리어ㆍ잡화류 최대 1+1ㆍ최대 50%

  50%
  풍년 마레러스터 압력솥(8인) 59,000원

  롯데/BC/신한/NH농협/삼성카드 50%
  포트메리온(상품별 용량 상이) 5,340~41,665원 ※입점점에 한함 
  ※ 점별 재고 상이 ※페이지 하단 카드할인 세부내용 참고 

  50%
  아이리스 시스템 수납함(중형 2호)/빌드업 바스켓 3종(상품별 상이) 각1,000~9,950원 ※입점점에 한함  

  9,910원 할인
  오늘좋은 아로마 세면타월(각 4P, 유칼립투스/아쿠아/라벤더/블라썸) 각9,990원
  ※입점 점포에 한함 ※점별 재고 상이

  10,000원 할인
  트래커 브리튼 여행가방(16/20/24/28인치) 49,000~89,000원
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  30%
  오늘좋은 세탁이 편한 빨아쓰는 베개(소 : 40×60cm/대 : 50×70cm) 15,330/20,930원
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  2개 이상 30%
  브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각6,930~7,630원 ※교차구매 가능 ※1개 구매시 각9,900~10,900원
  ※뉴발란스, 아디다스, 언더아머, 네파 등 ※점별 상품 상이

  2+1
  남성 브랜드 언더웨어(상품별 상이) 6,900원
  ※브랜드 : 트라이/보디가드/베이직엘르 외  ※교차구매 가능

  땡잡았다! 초특가을 만나보세요!
  ※매장에 더 많은 상품이 준비되어 있습니다.

  7,990원

  SOUP/AD HOC 데일리진(스트레이트/테이퍼드) 7,990원 정상가 각 14,800원

  오늘좋은 쿠션/방석 10종 7,990원
  ※ 상품별 상이
  ※ 해당상품에 한함

  코코도르 차량용 미니멀 디퓨저(50ml×2) 7,990원 정상가 각 9,900원

  오늘좋은 패브릭 발매트 11종(메모리폼/워셔블 外) 7,990원 정상가 각 10,900원~12,900원

  오늘좋은 심리스 런닝/땀패드 런닝 7,990원 정상가 각 8,900~12,900원

  9,990원

  블랙 박스 카트(M, 38×29×85.5cm) 9,990원 정상가 15,900원
  ※입점점에 한함
  ※조기 품절될 수 있습니다

  에너자이저 맥스10+5입(AA/AAA) 9,990원 정상가 각 12,900원

  뉴발란스/데상트 러닝 양말(각 2매) 9,990원

  룸바이홈 줄누비 패드(멀티체크/140×190cm) 9,990원 ※ 해당상품에 한함

  대림바스 PVC 샤워호스(실버 1.5m/어반 그레이, 어반화이트 2m) 9,990원 정상가 각 13,900/14,900원

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외   ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함

  ※전단적용기간 : 2025. 4. 3(목) ~ 4. 9(수)
  </div>
  `,
  `
  <div>
  1+1
  코렐 냉면기 4종(상품별 용량 상이) 각21,900원
  ※입점점에 한함  ※점별 재고 상이  ※교차구매 가능

  1+1
  유니랩 셰프장갑(소/중/대)/원데이 일회용 수세미(핑크/그린) 각4,990원
  ※동일 상품에 한함

  1+1
  크린랩 크린장갑(200매)/이중지퍼백(20매,소/중/대)/랩(30X50cm) 각3,190~9,090원

  1+1 기획팩
  크린랩 베이킹소다 세정티슈/크린랩 찌든때용 세정티슈(50매+50매) 4,390원 ※입점점에 한함 ※점별 재고 상이

  2+1
  불스원샷 70000(기획상품) 32,000원
  ※입점 점포에 한함  ※행사 상품에 한함

  2개 이상 20%
  스크럽 대디/마미 수세미 마이크로파이버타올 2개 이상 구매시 1개당 각4,392~6,392원 
  ※1개 구매시 각 5,490~7,990원 ※교차구매 가능

  2개 이상 20%
  오늘좋은 냉동밥 보관용기(8P) 2개 이상 구매시 1개당 각3,920원  ※1개 구매시 4,900원

  40%
  테팔 인텐시움 통 3중 냄비 3종(상품별상이) 26,280~37,680원

  30%
  코렐 목련/더블링 그린티 外 5,530~15,330원

  30%
  오늘좋은 데일리 피치스킨 이불(S/Q) 16,030/20,930원
  ※S : 150×200cm/Q : 180×220cm

  30%
  오늘좋은 패브릭 리빙박스 4종(40L/66L/80L/120L) 4,130~13,930원 

  최대 40%
  세차도구 전품목(상품별 규격상이)
  ※입점점에 한함  ※해당상품에 한함  ※점별 재고 상이

  20%
  러닝용품 전품목 8,720~39,920원 ※입점점에 한함 
  ※브랜드 : 나이키/아디다스/언더아머 外

  최대 9,000원 할인
  브랜드 와인잔 2P (슈피겔라우/쇼트즈위젤 外) 각9,900원

  3M 울트라클린 크린스틱 스타터팩/리필(핸들1입+리필5입/리필10입) 7,900/6,900원
  ※입점점에 한함 ※점별 재고상이

  꽃 피는 다육 포트 3종기획 각9,990원 ※입점점에 한함

  르젠 써큘레이터형 스탠드 선풍기(45W) 39,900원

  브랜드 반팔&반바지 19,900~29,900원
  ※입점점에 한함 ※브랜드 : 휠라/푸마/르까프 外

  여성 브랜드 언더웨어 브라/팬티 4,900/ 9,900원
  ※입점점에 한함 ※브랜드 : 비비안/비너스/오테나 外

  쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
  ※기간 3/20(목)~4/23(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ㉪ "를 확인하세요 ※결제시 계산대에서 수령

  최대 20%
  쿡셀 프라이팬/냄비 18종(상품별 상이) 31,120~49,900원

  에너자이저 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
  ※기간 3/27(목)~4/9(수)
  ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) 
  ※구분자 "㉧"를 확인하세요 ※결제시 계산대에서 수령

  10%
  에너자이저 맥스플러스 4입 8,370원

  ToysRus 인기완구추천!
  ※ 기간 : 3/27(목)~4/9(수)  ※ 매장에 더 많은 상품이 준비되어 있습니다  ※입점점에 한함

  L.POINT  30%
  마블 14인치 피규어세트 아이언맨/스파이더맨(6세 이상/49×11.2×45cm) 55,930원

  롯데/BC/신한/NH농협/삼성카드 10,000원 할인
  피아트 500e 푸쉬카(102×56×97cm) 89,000원
  ※점별 재고수량 상이
  ※페이지 하단 카드할인 세부내용 참고

  colioli 득템위크 반려동물식품&용품 500여종 할인! 개이득! 냥이득!
  반려동물식품/용품 3만원 이상 구매시 5천원 롯데상품권 증정!
  ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
  ※기간 : 3/27(목)~4/9(수) 
  ※당일 영수증에 한함
  ※비연속식 증정(1인 1일 1매) 
  ※구분자 " ♠ "를 확인하세요
  ※페이지 하단 카드할인 세부내용 참고

  롯데/BC/신한/NH농협/삼성카드 40%
  콜리올리 순살가득 스틱/슬라이스 4종(각 300g, 닭고기/오리고기) 7,140원
  ※입점점에 한함  ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 40%
  콜리올리 깔끔하게 사용하는 1회용 패드(M 100매/M 200매/L 40매) 각7,140~14,340원
  ※입점점에 한함  ※페이지 하단 카드할인 세부내용 참고 

  롯데/BC/신한/NH농협/삼성카드 30%
  피랩 마음놓고먹어 강아지 간식 5종(각 1kg) 각11,130~16,730원 
  ※입점점에 한함 ※페이지 하단 카드할인 세부내용 참고

  30%
  템테이션 8종(상품별 상이) 3,010원 ※입점점에 한함

  롯데마트 단 독
  5만원 구매 시 5천원 즉시할인
  ▶기간 : 3/27(목) ~ 4/9(수)
  ※최종 결제 금액에서 5천원 차감(탑텐 영수증 확인 필수)
  ※탑텐, 탑텐키즈, 탑텐밸런스 타 할인 쿠폰 행사와 중복 적용 불가

  ※정상가란? 2025년 3월 13일(목) ~ 4월 2일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2025년 3월 6일(목) ~ 3월 26일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다  ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  ※해당 단일카드로 전액 결제시에 한함   ※체크카드/앱카드 포함  ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외  ※롯데마트몰 카드행사 상이  ※오프라인 점포에 한함
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202504_2";
const category = [];

const data = {
  L201: {
    title: "스마트전단지-전점201",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L203: {
    title: "스마트전단지-제타플렉스 잠실점203",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L205: {
    title: "스마트전단지-제타플렉스 서울역점205",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
